import { makeAutoObservable } from "mobx";
import { IIndustry } from "../models/IIndustry";
import IndustryService from "../service/IndustryService";
import { ITypeOfActivity } from "../models/ITypeOfActivity";
import TypeService from "../service/TypeService";
import TypeOfActivityStore from "./TypeOfActivityStore";

export default class IndustryStore {
      industries: IIndustry[] = [];
      _selectedIndustry: number = 0;
      subIndustries: { [key: number]: ITypeOfActivity[] } = {};
      typeOfActivityStore: TypeOfActivityStore;

      constructor(typeOfActivityStore: TypeOfActivityStore) {
            makeAutoObservable(this);
            this.fetchIndustries()
            this.typeOfActivityStore = typeOfActivityStore;
      }

      async createIndustry(industry: IIndustry) {
            const newIndustry = await IndustryService.createIndustry(industry);
            this.industries.push(newIndustry);
      }

      async deleteIndustry(id:number) {
           return await IndustryService.deleteOneIndustryById(id);
      }
     
      async fetchIndustries() {
            const fetchedIndustries = await IndustryService.fetchIndustries();
            this.industries = fetchedIndustries;
            await this.fetchAllSubIndustries();
      }

      async fetchSubIndustries(industryId: number) {
            const fetchedSubIndustries = await TypeService.getTypesByIndustryId(industryId);
            this.subIndustries[industryId] = fetchedSubIndustries;
      }

      async fetchAllSubIndustries() {
            for (const industry of this.industries) {
                  await this.fetchSubIndustries(industry.id);
            }
      }

      getName(id: number): string {
            const industry = this.industries.find(ind => ind.id === id);
            return industry ? industry.name : "Неизвестная отрасль";
      }

      getSubIndustries(industryId: number): ITypeOfActivity[] {
            return this.subIndustries[industryId] || [];
      }

      setSelectedIndustryId(industry:any){
            this._selectedIndustry=industry
      }

      get  getSelectedIndustryId(){
            return this._selectedIndustry
      }
}