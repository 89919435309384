import $api from "../http/index";
import { IFranchise } from "../models/IFranchise";

export default class FavoritesService {
    static async fetchFavorites(): Promise<IFranchise[]> {
        try {
            const { data } = await $api.get('/baskets/get-all-favorites');
            return data;
        } catch (error) {
            throw error;
        }
    }

    static async createFavorite(franchiseId: number): Promise<IFranchise> {
        try {
            const { data } = await $api.post('/baskets/add', { franchiseId });
            return data; 
        } catch (error) {
            throw error;
        }
    }

    static async getUserBasket() {
        try {
            const { data } = await $api.get('/baskets/get-user-basket');
            return data; 
        } catch (error) {
            throw error;
        }
    }

    static async removeFavorite(franchiseId: number): Promise<void> {
        try {
            await $api.delete('/baskets/remove', { data: { franchiseId } });
        } catch (error) {
            throw error;
        }
    }
}
