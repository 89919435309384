import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classes from "../styles/Profile.module.scss";
import banner from '../assets/advertisingBanners/banner.png';
import { Context } from "../index";
import { observer } from "mobx-react-lite";
import UserFranchiseList from "../UI/UserFranchiseList";
import PublicProfileCard from "../UI/cards/PublicProfileCard";
import { IUser } from "../models/IUser";
import LoadingDots from "../UI/loadingDots";
import { NOTHING_FOUND_ROUTE } from "../utilits/consts";

const PublicProfile: React.FC = () => {
     const { userStore, userFranchiseStore } = useContext(Context);
     const { isLoading } = userStore;
     const { id } = useParams<{ id: string }>();
     const [user, setUser] = useState<IUser>();
     const navigate = useNavigate();

     useEffect(() => {
          if (id) {
               const loadUserData = async () => {
                    const user = await userStore.fetchUserById(parseInt(id));
                    if (!user) (
                         navigate(NOTHING_FOUND_ROUTE)
                    )
                    setUser(user);
               };
               loadUserData();
               userFranchiseStore.loadPublicUserFranchises(id);
          }
     }, [id, userFranchiseStore, userStore]);
     
     const userFranchises = userFranchiseStore.userFranchises;

     
     if (isLoading || !user) {
          return <LoadingDots />
     }

     return (
          <div className={classes.profile}>
               <div className={classes.profile__content}>
                    <div className={classes.profile__wrapper}>
                         <div className={classes.userData__box}>
                              <h1>Профиль</h1>
                              <PublicProfileCard user={user}/>
                         </div>
                         <div className={classes.advertising_banner}>
                              <img src={banner} alt="" />
                         </div>
                    </div>
               </div>

               <UserFranchiseList userFranchises={userFranchises} isPublicProfile/>
          </div>
     );
}

export default observer(PublicProfile);
