import React from "react";
import classes from "./LabledCheckbox.module.scss";

interface LabeledCheckboxProps {
    text: string;
    checked: boolean;
    onChange: () => void;
}

const LabeledCheckbox: React.FC<LabeledCheckboxProps> = ({ text, checked, onChange }) => {
     return (
          <div className={classes.checkbox__container}>
               <input
                    type="checkbox"
                    id="checkbox"
                    checked={checked}
                    onChange={onChange}
               />
               <label htmlFor="checkbox">{text}</label>
          </div>
     );
};

export default LabeledCheckbox;
