import { AxiosResponse } from "axios";
import $api from "../http";
import { AuthResponse } from "../models/response/AuthResponse";

function createFormData(data: Record<string, any>): FormData {
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
          formData.append(key, value);
      });
      return formData;
}

export default class AuthService {
      static async login(identifier: string, password: string): Promise<AxiosResponse<AuthResponse>> {
            try {
                return await $api.post<AuthResponse>('/users/login', { identifier, password });
            } catch (e: any) {
                throw new Error(e.response?.data?.message);
            }
      }

      static async registration(email: string, password: string, login: string, firstName: string, lastName: string, activationCode: string, img: File): Promise<AxiosResponse<AuthResponse>> {
            try {
                  const formData = createFormData({
                        email,
                        password,
                        login,
                        firstName,
                        lastName,
                        activationCode,
                        img,
                  });
      
                  return await $api.post<AuthResponse>('/users/registration', formData, {
                        headers: {
                              'Content-Type': 'multipart/form-data',
                        },
                  });
            } catch (e: any) {
                throw new Error(e.response?.data?.message);
            }
      }

      static async sendActivationCode(email: string) {
            try {
                  await $api.post('/users/send-activation-code', { email });
            } catch (e: any) {
                  throw new Error(e.response?.data?.message);
            }
      }

      static async sendRecoveryLink(email: string): Promise<AxiosResponse<AuthResponse>> {
            try {
                  return await $api.post<AuthResponse>('/users/send-recovery-link', { email });
            } catch (e: any) {
                  throw new Error(e.response?.data?.message);
            }
      }

      static async checkLogin(login: string): Promise<AxiosResponse<AuthResponse>> {
            try {
                  return await $api.post<AuthResponse>('/users/check-login', { login });
            } catch (e: any) {
                  throw new Error(e.response?.data?.message);
            }
      }

      static async checkEmail(email: string): Promise<AxiosResponse<AuthResponse>> {
            try {
                  return await $api.post<AuthResponse>('/users/check-email', { email });
            } catch (e: any) {
                  throw new Error(e.response?.data?.message);
            }
      }

      static async logout(): Promise<void> {
            return await $api.post('/users/logout');
      }
}