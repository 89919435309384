import React, { useContext } from "react";
import classes from './ProfileCard.module.scss';
import CustomButton from "../buttons/CustomButton";
import ProfileInput from "../inputs/ProfileInput";
import FileUploader from "../fileUploaders/FileUploader";
import { ReactComponent as Logout } from '../../assets/icons/logout.svg';
import { Context } from "../../index";

interface FormData {
    email: string;
    login: string;
    firstName: string;
    lastName: string;
    userImage: string;
}

interface ProfileCardProps {
    userData: FormData;
    fieldErrors: {
        email: boolean;
        login: boolean;
        firstName: boolean;
        lastName: boolean;
        userImage: boolean;
    };
    serverError: string | null;
    isEditing: boolean;
    isLoading: boolean;
    isSubmitClicked: boolean;
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement>, field: keyof FormData) => void;
    handleImageChange: (image: File) => void;
    toggleEditing: () => void;
    handleSave: () => void;
    handleReset: () => void;
    LogoutHandle: () => void;
}

const ProfileCard: React.FC<ProfileCardProps> = ({
    userData,
    fieldErrors,
    serverError,
    isEditing,
    isLoading,
    isSubmitClicked,
    handleInputChange,
    handleImageChange,
    toggleEditing,
    handleSave,
    handleReset,
    LogoutHandle,
}) => {
     const bodyType = isEditing ? 'filled' : 'empty';

     const { userStore } = useContext(Context);
     
     return (
          <div className={classes.profile__card}>
               <h2>Личные данные</h2> 
               <div className={classes.leave__button} onClick={LogoutHandle}>
                    <Logout />
               </div>

               <div className={classes.profile__box}>
                    <FileUploader
                         onImageChange={handleImageChange}
                         defaultImageURL={userData.userImage}
                         width="180px"
                         height="180px"
                         borderRadius="100%"
                         disabled={!isEditing}
                         isUserImageUploader
                    />
                    <div className={classes.user__info}>
                         <h3>{userStore.user.firstName} {userStore.user.lastName}</h3>
                         <span>{userStore.user.email}</span>
                    </div>
               </div>

               <div className={classes.userCard_gridBox}>
                    <ProfileInput
                         userData={userData.firstName}
                         value={userData.firstName}
                         fieldError={fieldErrors.firstName}
                         onChange={(e) => handleInputChange(e, 'firstName')}
                         isEditing={isEditing}
                         disabled={!isEditing}
                         errorMessage="Это обязательное поле. Заполните его!"
                         isSubmitClicked={isSubmitClicked}
                    />
                    <ProfileInput
                         userData={userData.login}
                         value={userData.login}
                         fieldError={fieldErrors.login}
                         onChange={(e) => handleInputChange(e, 'login')}
                         isEditing={isEditing}
                         hintMessage="Как должен выглядеть логин:"
                         hints={[
                             "Длина логина: от 4 до 20 символов",
                             "Буквы латинского алфавита и цифры",
                             "Не содержит пробелы и специальные символы"
                         ]}
                         errorMessage={serverError?.includes('Данный логин уже занят') ? serverError : "Неверный формат логина"} 
                         disabled={!isEditing}
                         isSubmitClicked={isSubmitClicked}
                    />
                    <ProfileInput
                         userData={userData.lastName}
                         value={userData.lastName}
                         fieldError={fieldErrors.lastName}
                         onChange={(e) => handleInputChange(e, 'lastName')}
                         errorMessage="Это обязательное поле. Заполните его!"
                         isEditing={isEditing}
                         disabled={!isEditing}
                         isSubmitClicked={isSubmitClicked}
                    />
                    <ProfileInput
                         userData={userData.email}
                         value={userData.email}
                         fieldError={fieldErrors.email}
                         onChange={(e) => handleInputChange(e, 'email')}
                         isEditing={isEditing}
                         errorMessage={serverError?.includes('Данная почта уже занята') ? serverError : "Неверный формат почты"} 
                         disabled={!isEditing}
                         isSubmitClicked={isSubmitClicked}
                    />
                    {isEditing && (
                         <CustomButton
                              borderRadius="0.875rem"
                              width="100%"
                              bodyType="empty"
                              fontSize="1rem"
                              onClick={handleReset}
                         >
                              Отменить
                         </CustomButton>
                    )}
                    <CustomButton
                         borderRadius="0.875rem"
                         width="100%"
                         bodyType={bodyType}
                         fontSize="1rem"
                         onClick={isEditing ? handleSave : toggleEditing}
                         loading={isLoading}
                         style={{
                              gridColumn: "2 / 3"
                         }}
                    >
                         {isEditing ? 'Сохранить' : 'Изменить'}
                    </CustomButton>
               </div>
        </div>
    );
};

export default ProfileCard;
