import React, { useContext,useEffect, useState } from "react";
import classes from './FranchiseBox.module.scss';
import { IFranchise } from "../../models/IFranchise";
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { observer } from "mobx-react-lite";
import { ReactComponent as Clock } from '../../assets/icons/clock.svg';
import ScrollToTopNavLink from "../ScrollToTopNavLink";
import { FRANCHISE_EDIT_ROUTE, FRANCHISE_PAGE_ROUTE } from "../../utilits/consts";
import CustomButton from "../buttons/CustomButton";
import { STATIC_URL } from "../../http";
import { formatDate } from "../../utilits/formatDate";
import { truncateText } from "../../utilits/truncateText";
import { EditorState, convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

const FranchiseBox: React.FC<{ franchise: IFranchise, isYourProfile:boolean }> = ({ franchise, isYourProfile }) => {

    const [descriptionHtml, setDescriptionHtml] = useState<string>(franchise.description);
    
    useEffect(() => {
        if (franchise.description) {
            const contentState = convertFromRaw(JSON.parse(franchise.description));
            const editorState = EditorState.createWithContent(contentState);
            const html = stateToHTML(editorState.getCurrentContent());
            const truncateHtml = truncateText(html, 3, 170)
            setDescriptionHtml(truncateHtml);
        }
    }, [franchise.description]);
   
    const renderOverlayText = () => {
        switch (franchise.status) {
            case "Pending":
                return (
                    <div className={classes.overlay__text}>
                        <b>На рассмотрении</b>
                        <span>В ближайшее время мы рассмотрим вашу франшизу</span>
                    </div>
                );
            case "Rejected":
                return (
                    <div className={classes.overlay__text}>
                        <b>Отменено</b>
                        <span>В ближайшее время эта франшиза будет удалена</span>
                    </div>
                );
            default:
                return null;
        }
    };
    
    return (
        <>
            <div className={classes.franchiseBox__container}>
                {(franchise.status === "Pending" || franchise.status === "Rejected") && (
                    <div className={classes.overlay}>
                        {renderOverlayText()}
                    </div>
                )}
                <div className={classes.franchiseImage}>
                    <img src={`${STATIC_URL}/franchises/${franchise.faceImage}`} alt={franchise.name} />
                </div>
                <div className={classes.franchiseBox__content}>
                    <h2>{franchise.name}</h2>
                    <div className={classes.creationTime__box}>
                        <Clock />
                        <span>{formatDate(franchise.createdAt)}</span>
                    </div>
                   
                    <div dangerouslySetInnerHTML={{ __html: descriptionHtml }} className={classes.franchiseBox__text}></div>
                    <span className={classes.totalViews}>Всего просмотров: {franchise.views}</span>
                </div>
                {isYourProfile ? (  <ScrollToTopNavLink to={`${FRANCHISE_EDIT_ROUTE}/${franchise.id}`} className={classes.box__button}>
                    <EditIcon />
                </ScrollToTopNavLink>):(<></>)}
              
                <ScrollToTopNavLink 
                    to={`${FRANCHISE_PAGE_ROUTE}/${franchise.id}`}
                    className={classes.toFranchisePage}
                    disabled={franchise.status !== "Approved"}
                >
                    <CustomButton padding="1.125rem 1.5rem">
                        Подробнее
                    </CustomButton>
                </ScrollToTopNavLink>
            </div>
        </>
    );
};

export default observer(FranchiseBox);