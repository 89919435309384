import React from 'react';
import { useLocation } from 'react-router-dom';
import classes from './ErrorDetector.module.scss';
import { FORGOT_PASSWORD_ROUTE, LOGIN_ROUTE, REGISTRATION_ROUTE } from '../utilits/consts';


interface ErrorCondition {
     condition: boolean | undefined;
     message: string;
}
 
interface ErrorConditionsByRoute {
       [route: string]: ErrorCondition[];
}

interface ErrorDetectorProps {
     showError: boolean;
     dataError: boolean;
     loginError: boolean;
     loginDirty: boolean;
     passwordDirty: boolean;
     passwordError: boolean;
     emailError: boolean;
     emailDirty: boolean;
     recovery_emailError: boolean;
     recovery_emailDirty: boolean;
     activationCode: boolean;
     confirmPasswordError?: boolean;
     confirmPasswordDirty: boolean;
     serverError?: string | null;
}

const getErrorMessage = ({
     dataError,
     activationCode,
     emailDirty,
     emailError,
     recovery_emailError,
     recovery_emailDirty,
     loginError,
     loginDirty,
     passwordDirty,
     passwordError,
     confirmPasswordError,
     confirmPasswordDirty,
     serverError,
     currentRoute,
 }: ErrorDetectorProps & { currentRoute: string }): string => {
     const errorConditions: ErrorConditionsByRoute = {
          [LOGIN_ROUTE]: [
               { condition: dataError, message: "Заполните данные" },
               { condition: loginError && loginDirty, message: "Неверная логин" },
               { condition: passwordDirty && passwordError, message: "Неверный пароль" },
               { condition: !!serverError, message: serverError || "" },
          ],
          [FORGOT_PASSWORD_ROUTE]: [
               { condition: dataError, message: "Заполните данные" },
               { condition: recovery_emailError && recovery_emailDirty, message: "Неверная почта" },
               { condition: !!serverError, message: serverError || "" },
          ],
          [REGISTRATION_ROUTE]: [
               { condition: dataError, message: "Заполните данные" },
               { condition: loginError && loginDirty, message: "Неверный логин" },
               { condition: passwordError && passwordDirty, message: "Неверный пароль" },
               { condition: emailError && emailDirty, message: "Неверная почта" },
               { condition: confirmPasswordError && confirmPasswordDirty, message: "Пароли не совпадают" },
               { condition: activationCode, message: "Введите код" },
               { condition: !!serverError, message: serverError || "" },
          ],
     };
 
     const errors = errorConditions[currentRoute] || [];
 
     const error = errors.find(({ condition }: ErrorCondition) => condition);
     return error ? error.message : "";
 };
 
 const ErrorDetector: React.FC<ErrorDetectorProps> = (props) => {
     const location = useLocation();
     const { showError } = props;
     const currentRoute = location.pathname;
 
     const errorMessage = getErrorMessage({ ...props, currentRoute });
 
     return (
          <>
               {showError && errorMessage && (
                    <div className={classes.ErrorDetected}>
                         {errorMessage}
                    </div>
               )}
          </>
     );
 };
 
 export default ErrorDetector;