import { makeAutoObservable, toJS } from "mobx";
import FavoritesService from "../service/FavoritesService";
import { IFranchise } from "../models/IFranchise";

export default class FavoritesStore {
    private _favorites: IFranchise[] = [];
    private _loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    setLoading(bool: boolean) {
        this._loading = bool;
    }

    async loadFavorites() {
        try {
            this.setLoading(true);
            const favorites = await FavoritesService.fetchFavorites();
            this._favorites = favorites;
        } catch (error: any) {
            console.error("Ошибка загрузки избранного", error);
        } finally {
            this.setLoading(false);
        }
    }

    async addFavorite(franchiseId: number) {
        try {
            const newFavorite = await FavoritesService.createFavorite(franchiseId);
            this._favorites.push(newFavorite);
        } catch (error: any) {
            console.error("Ошибка добавления в избранное", error);
        }
    }

    async removeFavorite(franchiseId: number) {
        try {
            this._favorites = this._favorites.filter(favorite => favorite.id !== franchiseId);
            await FavoritesService.removeFavorite(franchiseId);
        } catch (error: any) {
            console.error("Ошибка удаления из избранного", error);
        }
    }

    clearFavorites = () => {
        this._favorites = [];
    }

    get favorites(): IFranchise[] {
        return toJS(this._favorites);
    }

    get isLoading(): boolean {
        return this._loading;
    }
}
