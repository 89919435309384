import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import UserStore from './store/UserStore';
import FranchiseStore from './store/FranchiseStore';
import FavoritesStore from './store/FavoritesStore';
import UserFranchiseStore from './store/UserFranchiseStore';
import NewsStore from './store/NewsStore';
import IndustryStore from './store/IndustryStore';
import TypeOfActivityStore from './store/TypeOfActivityStore';
import ReleaseStore from './store/ReleaseStore';
import FranchiseApprovalStore from './store/FranchiseApprovalStore';

interface State {
     userStore: UserStore;
     franchiseStore: FranchiseStore;
     favoritesStore: FavoritesStore;
     newsStore: NewsStore;
     userFranchiseStore: UserFranchiseStore;
     industryStore: IndustryStore;
     typeOfActivityStore: TypeOfActivityStore;
     releaseStore: ReleaseStore;
     franchiseApprovalStore: FranchiseApprovalStore
}

const favoritesStore = new FavoritesStore();
const userFranchiseStore = new UserFranchiseStore();
const userStore = new UserStore(favoritesStore, userFranchiseStore);
const franchiseStore = new FranchiseStore();
const newsStore = new NewsStore();
const typeOfActivityStore = new TypeOfActivityStore();
const industryStore = new IndustryStore(typeOfActivityStore);
const releaseStore = new ReleaseStore();
const franchiseApprovalStore = new FranchiseApprovalStore

export const Context = createContext<State>({
     userStore,
     franchiseStore,
     favoritesStore,
     newsStore,
     userFranchiseStore,
     industryStore,
     typeOfActivityStore,
     releaseStore,
     franchiseApprovalStore
});

const root = ReactDOM.createRoot(
     document.getElementById('root') as HTMLElement
);

root.render(
     <Context.Provider value={{
          userStore,
          franchiseStore,
          favoritesStore,
          userFranchiseStore,
          newsStore,
          industryStore,
          typeOfActivityStore,
          releaseStore,
          franchiseApprovalStore
     }}>
     <App />
     </Context.Provider>
);