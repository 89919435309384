import React, { useEffect, useState, useContext } from "react";
import classes from '../styles/FranchisePage.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from "./../index";
import { IFranchise } from "../models/IFranchise";
import { STATIC_URL } from "../http";
import GallerySwiper from "../UI/swipers/GallerySwiper";
import CustomButton from "../UI/buttons/CustomButton";
import { formatPrice } from "../utilits/utilities";
import {NOTHING_FOUND_ROUTE } from "../utilits/consts";
import SkeletonFranchisePage from "../UI/skeletons/SkeletonFranchisePage";
import FranchisesYouMayLike from "../components/franchisesYouMayLike/FranchisesYouMayLike";
import { EditorState, convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import FranchiseModal from "../UI/modals/FranchiseModal";

const FranchisePage: React.FC = () => {
     const { id } = useParams<{ id: string }>();
     const { franchiseStore, industryStore, typeOfActivityStore } = useContext(Context);
     const [franchise, setFranchise] = useState<IFranchise | undefined>(undefined);
     const [descriptionHtml, setDescriptionHtml] = useState<string>("");
     const [loading, setLoading] = useState<boolean>(true);
     const navigate = useNavigate();
     
     const [isModalOpen, setIsModalOpen] = useState(false);

     useEffect(() => {
          const fetchFranchise = async () => {
               setLoading(true);
               try {
                    const foundFranchise = await franchiseStore.fetchOneFranchise(parseInt(id || ""));
                    if (!foundFranchise) {
                         navigate(NOTHING_FOUND_ROUTE);
                         return;
                    }
                    await franchiseStore.fetchFranchisesByIndustry(foundFranchise.id, foundFranchise.industryId, 3);
                    setFranchise(foundFranchise);

                    if (foundFranchise.description) {
                         const contentState = convertFromRaw(JSON.parse(foundFranchise.description));
                         const editorState = EditorState.createWithContent(contentState);
                         const html = stateToHTML(editorState.getCurrentContent());
                         setDescriptionHtml(html);
                    }
               } catch (e) {
                    navigate(NOTHING_FOUND_ROUTE);
               } finally {
                    setLoading(false);
               }
          };
          fetchFranchise();
     }, [id, franchiseStore]);
     
     if (loading || !franchise) {
          return <SkeletonFranchisePage />;
     }

     const handleOpenModal = () => {
          setIsModalOpen(true);
     };
   
     const handleCloseModal = () => {
          setIsModalOpen(false);
     }

     return (
          <>
               <div className={classes.franchise__container}>
                    <div className={classes.franchise__wrapper}>
                         <div className={classes.franchise__topBox}>
                              <div className={classes.franchise__image}>
                                   <img src={`${STATIC_URL}/franchises/${franchise.faceImage}`} alt="" />
                              </div>

                              <div className={classes.franchise__infoBody}>
                                   <h1>{franchise.name}</h1>
                                   <div className={classes.franchise__infoTable}>
                                        <div className={classes.infoTable__row}>
                                             <b>Отрасль:</b>
                                             <p>{industryStore.getName(franchise.industryId)}</p>
                                        </div>
                                        <div className={classes.infoTable__row}>
                                             <b>Вид деятельности:</b>
                                             <p>{typeOfActivityStore.getName(franchise.typeOfActivityId)}</p>
                                        </div>
                                        <div className={classes.infoTable__row}>
                                             <b>Бренд:</b>
                                             <p>{franchise.brand}</p>
                                        </div>
                                        <div className={classes.infoTable__row}>
                                             <b>Инвестиции:</b>
                                             <p>от {formatPrice(franchise.investments)} BYN</p>
                                        </div>
                                        <div className={classes.infoTable__row}>
                                             <b>Окупаемость:</b>
                                             <p>от {franchise.paybackTime} месяцев</p>
                                        </div>
                                        <div className={classes.infoTable__row}>
                                             <b>Владелец:</b>
                                             <p>{franchise.ownerInfo?.firstName} {franchise.ownerInfo?.lastName}</p>
                                        </div>
                                        <div className={classes.infoTable__row}>
                                             <b>Роялти:</b>
                                             <p>от {franchise.royalty}%</p>
                                        </div>
                                        <div className={classes.infoTable__row}>
                                             <b>Рекламный сбор:</b>
                                             <p>{formatPrice(franchise.advertisingFee)} BYN</p>
                                        </div>
                                        <div className={classes.infoTable__row}>
                                             <b>Кол-во собственных точек:</b>
                                             <p>{franchise.ownPoints}</p>
                                        </div>
                                        <div className={classes.infoTable__row}>
                                             <b>Кол-во франчайзинговых точек:</b>
                                             <p>{franchise.franchisePoints}</p>
                                        </div>
                                   </div>  
                                   <CustomButton onClick={handleOpenModal} width="fit-content" padding="1rem 1.8rem">
                                        Оставить заявку
                                   </CustomButton>
                              </div>
                         </div>

                         <div className={classes.franchise__bottomBox}>
                              <div  className={classes.franchisor_description}>
                                   <h1>Описание от франчайзера</h1>
                                   <div dangerouslySetInnerHTML={{ __html: descriptionHtml }} className={classes.description__text}></div>
                              </div>
                                   
                              <div className={classes.franchise__gallery}>
                                   <h1>Фото</h1>
                                   <GallerySwiper images={franchise.imageGallery.map(image => `${STATIC_URL}/franchises/${image}`)} />
                              </div>
                         </div>
                    </div>
               </div>
               <FranchiseModal isOpen={isModalOpen} onClose={handleCloseModal} franchise={franchise}/>
               <FranchisesYouMayLike franchiseId={franchise.id} industryId={franchise.industryId} />
          </>
     );
};

export default FranchisePage;
