import React from "react";
import classes from './BuyAndSell.module.scss';
import firstImage  from '../../../assets/BuyAndSell-image-1.png'
import SecondImage  from '../../../assets/BuyAndSell-image-2.png';
import LastImage  from '../../../assets/BuyAndSell-image-3.png';
import CustomButton from "../../../UI/buttons/CustomButton";
import { REGISTRATION_ROUTE } from "../../../utilits/consts";
import ScrollToTopNavLink from "../../../UI/ScrollToTopNavLink";


const BuyAndSell = () => {
    return (
        <div className={classes.buyAndSell__container}>
            <div className={classes.buyAndSell__content}>
                <div className={classes.buyAndSell__imagesWrapper}>
                    <div className={classes.firstImage}>
                        <img src={firstImage}></img>
                    </div>
                    <div className={classes.secondImage}>
                        <img src={SecondImage} ></img>
                    </div>
                    <div className={classes.thirdImage}>
                        <img src={LastImage} ></img>
                    </div> 
                </div>  
                <div className={classes.buyAndSell__text}>
                    <h1>Продавайте и покупайте франшизы</h1>
                    <p>Разверните свой бизнес на новые горизонты с francheese! Откройте новые возможности вместе с нами и достигайте значительных высот. Зарегистрируйтесь сейчас, чтобы сделать первый шаг к успеху!</p>
                    <div>
                        <ScrollToTopNavLink to={REGISTRATION_ROUTE}>
                            <CustomButton>Зарегистрироваться</CustomButton>
                        </ScrollToTopNavLink>
                    </div>
                </div>
            </div>
        </div>
    )};

export default BuyAndSell;