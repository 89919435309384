import React, { useContext, useEffect, useState } from "react";
import classes from './RecommendedFranchises.module.scss'
import FranchiseCard from "../../../UI/cards/FranchiseCard";
import RecommendedFranchiseCard from "../../../UI/cards/RecommendedFranchiseCard";
import { Context } from "../../../index";
import { STATIC_URL } from "../../../http";
import SkeletonsFranchiseCard from "../../../UI/skeletons/SkeletonsFranchiseCard";
import Skeleton from "../../../UI/skeletons/Skeleton";
import { observer } from "mobx-react-lite";
import SkeletonRecommendedCard from "../../../UI/skeletons/SkeletonRecommendedCard";
import ScrollToTopNavLink from "../../../UI/ScrollToTopNavLink";
import { PUBLIC_PROFILE_ROUTE } from "../../../utilits/consts";

const RecommendedFranchises: React.FC = () => {
     const { franchiseStore, releaseStore } = useContext(Context);
     
     return (
          <div className={classes.recommended__container}>
               <div className={classes.recommended__content}>
                    <h1>Наши рекомендации</h1>
                    <div className={classes.recommended__gridBox}>
                         <div className={classes.bestFranchise__box}>
                              {releaseStore.specialFranchise ? (
                                   <>
                                        <FranchiseCard 
                                             franchise={releaseStore.specialFranchise}
                                             displayOwner={false}
                                             displayName={false}
                                             isActive
                                        />

                                        <div className={classes.bestFranchise__info}>
                                             <ScrollToTopNavLink to={`${PUBLIC_PROFILE_ROUTE}/${releaseStore.specialFranchise?.ownerInfo?.id}`} className={classes.bestFranchise__ownerImage}>
                                                  <img 
                                                       src={`${STATIC_URL}/users/${releaseStore.specialFranchise?.ownerInfo?.img}`}
                                                       alt=""
                                                  />
                                             </ScrollToTopNavLink>
                                             <div className={classes.info__textBox}>
                                                  <p>{releaseStore.specialFranchise?.name}</p>
                                                  <span>
                                                       {releaseStore.specialFranchise.ownerInfo?.firstName} {releaseStore.specialFranchise.ownerInfo?.lastName}
                                                  </span>
                                             </div>
                                        </div>
                                   </>
                              ) : (
                                   <>
                                        <SkeletonsFranchiseCard/>
                                        <div className={classes.bestFranchise__info}>
                                             <Skeleton width={48} height={48} borderRadius={"50%"} contrast="dark"/>
                                             <div className={classes.info__textBox}>
                                                  <Skeleton width={220} height={"1.25rem"} borderRadius={4}/>
                                                  <Skeleton width={100} height={"0.875rem"} borderRadius={4}/>
                                             </div>
                                        </div>
                                   </>
                              )}
                         </div>
                         <div className={classes.topFranchises__gridBox}>
                              {releaseStore.specialFranchise ? (
                                   <>
                                        {franchiseStore.topFranchises.map((franchise, index) => (
                                             <RecommendedFranchiseCard
                                                  key={franchise.id}
                                                  franchise={franchise}
                                             />
                                        ))}
                                   </>
                              ) : (
                                   <>
                                        {Array.from({ length: 6}).map((_, index) => (
                                             <SkeletonRecommendedCard key={index}/>
                                        ))}
                                   </>
                              )}
                         </div>
                    </div>
               </div>
          </div>
     )
}

export default observer(RecommendedFranchises);