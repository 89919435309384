import React from "react";
import ScrollToTopNavLink from "../ScrollToTopNavLink";
import { FRANCHISE_PAGE_ROUTE } from "../../utilits/consts";
import { STATIC_URL } from "../../http";
import {IFranchise} from "../../models/IFranchise"
import classes from './SearchItem.module.scss';
import SearchIcon from '../../assets/icons/search.svg';


interface SearchItemProps {
    franchise: IFranchise
}

const SearchItem: React.FC<SearchItemProps> = ({
    franchise
}) => {

    return(
        <div className={classes.item__box}>
            <ScrollToTopNavLink to={`${FRANCHISE_PAGE_ROUTE}/${franchise.id}`} className={classes.item__content}>
                <div className={classes.franchise__preview}>
                    <img src={`${STATIC_URL}/franchises/${franchise.faceImage}`} alt="" />
                </div>
                <p>{franchise.name}</p>
                <img src={SearchIcon} alt="Поиск" className={classes.searchIcon} />
            </ScrollToTopNavLink>
        </div>
    )
}


export default SearchItem;