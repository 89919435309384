import $api from "../http/index";
import { ITypeOfActivity } from '../models/ITypeOfActivity';

export default class TypeService {
    static async createTypeOfActivity(type: ITypeOfActivity) {
        try {
            const formData = new FormData();
            formData.append('name', type.name);
            formData.append('industryId', type.industryId.toString());
            const { data } = await $api.post('/types/create', formData)
            return data;
        }
        catch(e){
            throw e;
        }
    }

    static async fetchTypesOfActivities() {
        try {
            const { data } = await $api.get('/types/get-all');
            return data;
        }
        catch(e){
            throw e;
        }
    }

    static async getTypesByIndustryId(industryId: number) {
        try {
            const { data } = await $api.get(`/types/get-types/${industryId}`);
            return data;
        }
        catch(e){
            throw e;
        }
    }

    static async deleteOneTypeById(id:number){
        try {
            const { data } = await $api.delete(`/types/remove/${id}`);
            return data
        }
        catch(e){
            throw e;
        }
    }
}