import React, { useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import $api from '../http';
import { AuthResponse } from '../models/response/AuthResponse';
import LoadingDots from '../UI/loadingDots';
import { useNotification } from '../hooks/useNotification';
import { HOME_ROUTE } from '../utilits/consts';

const RecoveryAccount = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { notify, showLoading, hideLoading } = useNotification();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const accessToken = params.get('accessToken');
        const refreshToken = params.get('refreshToken');

        if (accessToken && refreshToken) {
            localStorage.setItem('accessToken', accessToken);
        }

        const RecoveryAccountAccess = async () => {
            try {
                showLoading("Восстанавливаем доступ к аккаунту")
                const response = await $api.get<AuthResponse>(`/users/refresh`);
                if (response.status === 200) {
                    localStorage.setItem('accessToken', response.data.accessToken);
                    notify("Доступ восстановлен")
                    navigate(HOME_ROUTE)
                } else {
                    console.error('Ошибка активации:', response.data.message);
                }
                hideLoading();
            } catch (error) {
                notify("Не удалось востановить доступ к аккаунту")
                console.error('Произошла ошибка:', error);
            }
        }
        RecoveryAccountAccess();
    }, [location]);
    
    return (
        <div>
            <LoadingDots />
        </div>
    );
};

export default RecoveryAccount;