import { makeAutoObservable, runInAction, toJS } from "mobx";
import { IFranchise } from "../models/IFranchise";
import FranchiseService from "../service/FranchiseService";
import UserService from "../service/UserService";

export default class FranchiseStore {
     private _franchises: IFranchise[] = [];
     private _topFranchises: IFranchise[] = [];
     private _recommendedFranchises: IFranchise[] = [];
     private _totalCount: number = 0;
     private _page: number = 1;
     private _limit: number = 8;
     private _filters = {
          industryId: [] as number[],
          typeOfActivityId: [] as number[],
          budget: "Все" as string,
          sort: "По популярности" as string
     };
     private _loading: boolean = false;

     constructor() {
          makeAutoObservable(this);
          this.fetchTopFranchises(6);
          this.fetchInitialData();
     }

     setFilters(industryIds: number[], typeOfActivityIds: number[], budget: string, sort: string) {
          this._filters = { industryId: industryIds, typeOfActivityId: typeOfActivityIds, budget, sort };
          this.fetchCatalogPage(1);
     }

     fetchCatalogPage(page: number) {
          this._page = page;
          this.fetchFranchises(page);
     }

     async fetchFranchises(page: number = this._page, limit: number = this._limit) {
          this.setLoading(true);
          try {
               const { franchises, totalCount } = await FranchiseService.fetchFranchises(
                    this._filters.industryId,
                    this._filters.typeOfActivityId,
                    page,
                    limit,
                    this._filters.budget,
                    this._filters.sort
               );
               runInAction(() => {
                    this._franchises = franchises;
                    this._totalCount = totalCount;
                    this._page = page;
                    this._limit = limit;
               });
          } catch (error) {
               console.error("Не удалось получить данные о франшизах:", error);
          } finally {
               this.setLoading(false);
          }
     }
  
     async fetchInitialData() {
          this.setLoading(true);
          try {
               let { franchises, totalCount } = await FranchiseService.fetchFranchises(
                    undefined,
                    undefined,
                    1,
                    8,
                    "Все",
                    "По популярности"
               );
               runInAction(() => {
                    this._franchises = franchises;
                    this._totalCount = totalCount;
               });
          } catch (error) {
               console.error("Не удалось получить данные о франшизах:", error);
          } finally {
               this.setLoading(false);
          }
     }
  
     async fetchTopFranchises(limit: number) {
          try {
               const topFranchises = await FranchiseService.fetchTopFranchises(limit);
               this._topFranchises = topFranchises; 
          } catch (error) {
               console.error("Не удалось получить лучшие франшизы:", error);
               return { franchises: [] };
          }
     }
     
     async fetchOneFranchise(id: number): Promise<IFranchise> {
          this.setLoading(true);
          try {
               const franchise = await FranchiseService.fetchOneFranchise(id);
               return franchise;
          } catch (error) {
               console.error("Не удалось получить франшизу:", error);
               throw error;
          }
          finally {
               this.setLoading(false);
          }
     }

     async fetchFranchisesByIndustry(id:number, industryId:number, limit: number) {
          this.setLoading(true);
          try {
               const recommendedFranchises = await FranchiseService.fetchFranchisesByIndustry(id,industryId,limit);
               this._recommendedFranchises = recommendedFranchises
          } catch (error) {
               console.error("Не удалось получить франшизу:", error);
               throw error;
          } finally {
               this.setLoading(false);
          }
     }

     async fetchFranchiseByReleaseId(releaseId: number): Promise<IFranchise> {
          try {
               const franchise = await FranchiseService.fetchFranchiseByReleaseId(releaseId);
               return franchise;
          } catch (error) {
               console.error("Не удалось получить франшизу:", error);
               throw error;
          }
     }

     async createFranchise (
          name: string,
          royalty: number,
          advertisingFee: number,
          investments: number,
          ownPoints: number,
          franchisePoints: number,
          paybackTime: number,
          industryId: number,
          typeOfActivityId: number,
          brand: string,
          description: string,
          faceImage: File,
          galleryImages: File[],
     ) {
          try {
               await FranchiseService.createFranchise(name, royalty, advertisingFee, investments, ownPoints, franchisePoints, paybackTime, industryId, typeOfActivityId, brand, description, faceImage, galleryImages);
          } catch (error) {
               console.error("Не удалось создать франшизу:", error);
          }
     }
     
     async updateFranchise (
          id: string,
          name: string,
          royalty: number,
          advertisingFee: number,
          investments: number,
          ownPoints: number,
          franchisePoints: number,
          paybackTime: number,
          industryId: number,
          typeOfActivityId: number,
          brand: string,
          description: string,
          faceImage: File,
          galleryImages: File[],
     ) {
          try {
               await FranchiseService.updateFranchise(id, name, royalty, advertisingFee, investments, ownPoints, franchisePoints, paybackTime, industryId, typeOfActivityId, brand, description, faceImage, galleryImages);
          } catch (error) {
               console.error("Не удалось обновить франшизу:", error);
          }
     }

     async deleteOneFranchiseById(id: number): Promise<IFranchise> {
          try {
               const message = await FranchiseService.deleteOneFranchise(id);
               return message;
          } catch (error) {
               console.error("Не удалось удалить франшизу:", error);
               throw error;
          }
     }

     setLoading(bool: boolean) {
          this._loading = bool;
     }

     setTotalCount(count:any) {
          this._totalCount = count
     }

     get franchises(): IFranchise[] {
          return toJS(this._franchises);
     }

     get topFranchises(): IFranchise[] {
          return toJS(this._topFranchises);
     }

     get totalCount() {
          return this._totalCount
     }
     
     get page() {
          return this._page
     }
     get recommendedFranchises (){
          return toJS(this._recommendedFranchises)
     }
     get limit() {
          return this._limit
     }

     get isLoading() {
          return this._loading;
     }
}