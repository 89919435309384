import React, { useContext, useState,useEffect } from 'react'
import { IFranchise } from '../../models/IFranchise';
import classes from './FranchiseApprovalCard.module.scss';
import { STATIC_URL } from '../../http';
import { formatPrice } from '../../utilits/utilities';
import { Context } from '../../index';
import GallerySwiper from '../swipers/GallerySwiper';
import CustomButton from '../buttons/CustomButton';
import { EditorState, convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import DefaultImage from '../../assets/defaultUser.png'
import { useNotification } from '../../hooks/useNotification';
import MailService from '../../service/MailService';

interface FranchiseApprovalCardProps {
     franchise: IFranchise;
 }
 
 const FranchiseApprovalCard: React.FC<FranchiseApprovalCardProps> = ({ 
     franchise,
}) => {
     const { franchiseApprovalStore, userStore, industryStore, typeOfActivityStore } = useContext(Context); 
     const { notify, showLoading, hideLoading } = useNotification();

     const [isExpanded, setIsExpanded] = useState(false);
     const [descriptionHtml, setDescriptionHtml] = useState<string>("");
     const ownerInfo = franchise?.ownerInfo;
     
     const handleApprove = async () => {
          if(!!ownerInfo) {
               showLoading("Одобряем франшизу")
               await franchiseApprovalStore.approveFranchise(franchise.id);
               await MailService.sendFranchiseApproved(ownerInfo.id, franchise.id);
               hideLoading();
               notify("Франшиза одобрена!")
          }
     };
  
     const handleDeny = async () => {
          if(!!ownerInfo) {
               showLoading("Отменяем франшизу")
               await franchiseApprovalStore.denyFranchise(franchise.id);
               await MailService.sendFranchiseDenied(ownerInfo.id, franchise.id);
               hideLoading();
               notify("Франшиза отменена!")
          }
     };

     useEffect(() => {
          const contentState = convertFromRaw(JSON.parse(franchise.description));
          const editorState = EditorState.createWithContent(contentState);
          const html = stateToHTML(editorState.getCurrentContent());
          setDescriptionHtml(html);
     }, [])

     const handleToggleDetails = () => {
          setIsExpanded(prevState => !prevState);
     };

     const ownerImage = ownerInfo?.img ? `${STATIC_URL}/users/${ownerInfo.img}` : DefaultImage;
  
     return (
          <div className={classes.franchiseApprovalCard}>
               <div className={classes.franchiseInfo}>
                    <div className={classes.franchiseInfo__image}>
                         <img src={`${STATIC_URL}/franchises/${franchise.faceImage}`} alt={franchise.AltText} />
                    </div>
                    <div className={classes.franchiseInfo__data}>
                         <div className={classes.franchiseInfo__dataItem}>
                              <span>Название:</span>
                              <p>{franchise.name}</p>
                         </div>
                         <div className={classes.franchiseInfo__dataItem}>
                              <span>Отрасль:</span>
                              <p>{industryStore.getName(franchise.industryId)}</p>
                         </div>
                         <div className={classes.franchiseInfo__dataItem}>
                              <span>Вид деятельности:</span>
                              <p>{typeOfActivityStore.getName(franchise.typeOfActivityId)}</p>
                         </div>
                         <div className={classes.franchiseInfo__dataItem}>
                              <span>Окупаемость:</span>
                              <p>{franchise.paybackTime} месяцев</p>
                         </div>
                         <div className={classes.franchiseInfo__dataItem}>
                              <span>Инвестиции:</span>
                              <p>{formatPrice(franchise.investments)} ₽</p>
                         </div>
                         <div className={classes.franchiseInfo__dataItem}>
                              <span>Роялти:</span>
                              <p>{franchise.royalty}%</p>
                         </div>
                         <div className={classes.franchiseInfo__dataItem}>
                              <span>Окупаемость:</span>
                              <p>{franchise.paybackTime} месяцев</p>
                         </div>
                         <div className={classes.franchiseInfo__dataItem}>
                              <span>Рекламный сбор:</span>
                              <p>{formatPrice(franchise.advertisingFee)} ₽</p>
                         </div>
                    </div>
               </div>

               <div className={classes.ownerInfo}>
                    {ownerInfo ? (
                         <>
                              <div className={classes.ownerInfo__name}>
                                   <span>Создатель:</span>
                                   <h3>{ownerInfo.firstName} {ownerInfo.lastName}</h3>
                              </div>
                              <div className={classes.ownerInfo__image}>
                                   <img src={ownerImage} alt={ownerInfo.firstName}/>
                              </div>
                              <div className={classes.franchiseInfo__dataItem} 
                                   style={{ 
                                        alignItems: "end",
                                        gridArea: "2 / 1 / span 1 /span 2"
                                   }}
                              >
                                   <span>Дата создания:</span>
                                   <p>{new Date(franchise.createdAt).toLocaleDateString()}</p>
                              </div>
                         </>
                         ) : (
                              <p>Информация о пользователе не найдена.</p>
                         )}
               </div>

               <div className={`${classes.collapsibleContent} ${isExpanded ? classes.expanded : ''}`}>
                    <div className={classes.franchiseInfo__data}>
                         <div className={classes.franchiseInfo__dataItem}>
                              <span>Описание:</span>
                              <div dangerouslySetInnerHTML={{ __html: descriptionHtml }}></div>
                         </div>
                         <div className={classes.franchiseInfo__dataItem}>
                              <span>Кол-во собственных точек:</span>
                              <p>{franchise.ownPoints}</p>
                         </div>
                         <div className={classes.franchiseInfo__dataItem}>
                              <span>Кол-во франчайзинговых точек:</span>
                              <p>{franchise.franchisePoints}</p>
                         </div>
                         <div className={classes.franchiseInfo__dataItem}>
                              <span>Окупаемость:</span>
                              <p>{franchise.paybackTime} месяцев</p>
                         </div>
                         <div className={classes.franchiseInfo__dataItem}>
                              <span>Инвестиции:</span>
                              <p>{formatPrice(franchise.investments)} ₽</p>
                         </div>
                         <div className={classes.franchiseInfo__dataItem}>
                              <span>Детали:</span>
                              <p>{franchise.details ? franchise.details : "Нет деталей"}</p>
                         </div>
                    </div>
                    <h2>Галерея изображений</h2>
                    <GallerySwiper images={franchise.imageGallery.map(image => `${STATIC_URL}/franchises/${image}`)} />
               </div>
  
               <div className={classes.actions}>
                    <CustomButton 
                         onClick={handleToggleDetails}
                         className={classes.toggleButton}
                         padding='1rem 2rem'
                         bodyType='empty'
                    >
                         {isExpanded ? 'Скрыть' : 'Подробнее'}
                    </CustomButton>
                    {ownerInfo ? (
                         <>
                              <button onClick={() => handleApprove()} className={classes.approveButton}>Одобрить</button>
                              <button onClick={() => handleDeny()} className={classes.denyButton}>Отменить</button>
                         </>
                         ) : (
                              <p>Информация о пользователе не найдена.</p>
                         )
                    }
               </div>
          </div>
      );
  };
  
  export default FranchiseApprovalCard;