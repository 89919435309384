import truncate from 'html-truncate';

export function truncateText(
    html: string,
    maxLines: number,
    maxLength: number,
    ending: string = "..."
): string {
    const truncatedHtml = truncate(html, maxLength, { ellipsis: ending });
    const lines = truncatedHtml.split("\n").filter(line => line.trim() !== "");
    return lines.slice(0, maxLines).join("\n");
}