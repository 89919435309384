import React, { FC } from "react";
import classes from './RecommendedFranchiseCard.module.scss';
import CustomButton from "../buttons/CustomButton";
import { IFranchise } from "../../models/IFranchise";
import { STATIC_URL } from "../../http";
import { formatPrice } from "../../utilits/utilities";
import { FRANCHISE_PAGE_ROUTE, PUBLIC_PROFILE_ROUTE } from "../../utilits/consts";
import ScrollToTopNavLink from "../ScrollToTopNavLink";
import { ReactComponent as TimeIcon } from '../../assets/icons/timeDuration.svg';

interface RecommendedFranchiseCardProps {
    franchise: IFranchise;
}

const RecommendedFranchiseCard: FC<RecommendedFranchiseCardProps> = ({
    franchise
}) => {
    const ownerInfo = franchise?.ownerInfo;

    return (
        <div className={classes.franchiseCard__container}>
            <ScrollToTopNavLink to={`${FRANCHISE_PAGE_ROUTE}/${franchise.id}`}>
                <div 
                    className={classes.franchiseCard__image}
                    style={{ backgroundImage: `url(${STATIC_URL}/franchises/${franchise?.faceImage})` }}
                />
            </ScrollToTopNavLink>

            <div className={classes.franchiseCard__infoBox}>
                <span>{franchise?.name}</span>
                <div className={classes.infoBox}>
                    <ScrollToTopNavLink to={`${PUBLIC_PROFILE_ROUTE}/${ownerInfo?.id}`} className={classes.infoBox__ownerImage}>
                        <img 
                            src={`${STATIC_URL}/users/${ownerInfo?.img}`}
                            alt={ownerInfo?.firstName} 
                        />
                    </ScrollToTopNavLink>
                    <CustomButton 
                        color={"black"}
                        bodyType={"empty"}
                        borderRadius={"0.375rem"}
                        padding={"0.5rem"}
                    >
                        {formatPrice(franchise.investments)} BYN
                    </CustomButton>
                    <div className={classes.infoBox__paybackTime}>
                        <TimeIcon/>
                        <span>от {franchise.paybackTime} мес.</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RecommendedFranchiseCard;
