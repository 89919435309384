import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Context } from "../index";
import NothingFound from "./NothingFound";
import classes from '../styles/Favorites.module.scss';
import FavoritesBox from "../UI/franchiseBoxes/FavoritesBox";
import SkeletonFranchiseBox from "../UI/skeletons/SkeletonFranchiseBox";

const Favorites: React.FC = () => {
    const { favoritesStore } = useContext(Context);
    const { favorites, isLoading } = favoritesStore;
    
    useEffect(() => {
        favoritesStore.loadFavorites();
    }, [favoritesStore]);

    const toggleFavorite = async (franchiseId: number) => {
        try {
            if (favoritesStore.favorites.some(fav => fav.id === franchiseId)) {
                await favoritesStore.removeFavorite(franchiseId);

            } else {
                await favoritesStore.addFavorite(franchiseId);
            }
        } catch (error) {
            console.error("Не удалось обновить статус избранных:", error);
        }
    };

    if (isLoading) {
        return (
            <div className={classes.favorites__container}>
                <div className={classes.favorites__content}>
                    <h1>Избранное</h1>
                    <div className={classes.favorites__items}>
                        {Array.from({ length: 3}).map((_, index) => (
                            <SkeletonFranchiseBox key={index}/>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.favorites__container}>
            <div className={classes.favorites__content}>
                <h1>Избранное</h1>
                {favoritesStore.favorites.length === 0 ? (
                    <NothingFound text={"У вас пока нет Избранных"} />
                ) : (
                    <div className={classes.favorites__items}>
                        {favorites.map(franchise => (
                            <FavoritesBox key={franchise.id} franchise={franchise} onFavoriteToggle={toggleFavorite} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default observer(Favorites);
