import React, { useState, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import classes from "../styles/FranchisesCatalog.module.scss";
import FilterButton from "../UI/buttons/FilterButton";
import { Context } from "../index";
import NothingFound from "./NothingFound";
import AllFiltersButton from "../UI/buttons/AllFiltersButton";
import CustomButton from "../UI/buttons/CustomButton";
import { IIndustry } from "../models/IIndustry";
import { useLocation } from "react-router-dom";
import Pagination from "../UI/news/Pagination";
import { FRANCHISE_CATALOG_ROUTE } from "../utilits/consts";
import ScrollToTopNavLink from "../UI/ScrollToTopNavLink";
import CatalogFranchiseCard from "../UI/cards/CatalogFranchiseCard";
import LoadingDots from "../UI/loadingDots";

const FranchisesCatalog: React.FC = () => {
     const location = useLocation();
     const isCatalogPage = location.pathname === FRANCHISE_CATALOG_ROUTE;
     const { franchiseStore, industryStore, typeOfActivityStore } = useContext(Context);

     const [filters, setFilters] = useState({
          budget: "Все",
          categories: industryStore.getSelectedIndustryId ? [industryStore.getSelectedIndustryId] : [],
          types: typeOfActivityStore.getSelectedTypeId ? [typeOfActivityStore.getSelectedTypeId] : [],
          showAllCategories: industryStore.getSelectedIndustryId === 0,
     });

     const [sort, setSort] = useState<string>("По популярности");

     useEffect(() => {
          franchiseStore.setFilters(filters.categories, filters.types, filters.budget, sort);
     }, [filters, sort, franchiseStore]);

     const handleFilterChange = (category: number, checked: boolean) => {
          setFilters((prevFilters) => {
               let newCategories = [...prevFilters.categories];
               if (category === 0) {
                    newCategories = checked ? [] : [0];
               } else {
                    newCategories = checked ? [...newCategories, category] : newCategories.filter((c) => c !== category);
               }
               return {
                    ...prevFilters,
                    categories: newCategories,
                    showAllCategories: newCategories.length === 0 || newCategories.includes(0),
               };
          });
     };

     const handleBudgetChange = (budget: string) => {
          setFilters((prevFilters) => ({ ...prevFilters, budget }));
     };

     const handleSortChange = (sort: string) => {
          setSort(sort);
     };

     const handleTypeChange = (types: number[]) => {
          setFilters((prevFilters) => ({
              ...prevFilters,
              types,
          }));
      };

     const handlePageChange = (page: number) => {
          franchiseStore.fetchCatalogPage(page);
     };

     const totalPages = Math.ceil(franchiseStore.totalCount / franchiseStore.limit);

     return (
          <div className={classes.catalog__container}>
               <div className={classes.catalog__content}>
                    <div className={classes.catalog__header}>
                         <h1>Наши франшизы</h1>
                         <div className={classes.filters__container}>
                              <FilterButton
                                   isActive={filters.showAllCategories}
                                   onClick={() => handleFilterChange(0, true)}
                              >
                              Все категории
                              </FilterButton>

                              {industryStore.industries.map((industry: IIndustry) => (
                                   <FilterButton
                                        key={industry.id}
                                        isActive={filters.categories.includes(industry.id)}
                                        onClick={() =>
                                             handleFilterChange(
                                                  industry.id,
                                                  !filters.categories.includes(industry.id)
                                             )
                                        }
                                   >
                                        {industry.name}
                                   </FilterButton>
                              ))}

                              <AllFiltersButton
                                   onBudgetChange={handleBudgetChange}
                                   onSortChange={handleSortChange}
                                   onTypeChange={handleTypeChange}
                                   industries={industryStore.industries.map(industry => ({
                                        id: industry.id,
                                        name: industry.name,
                                        types: typeOfActivityStore.types.filter(type => type.industryId === industry.id)
                                   }))}
                              />
                         </div>
                    </div>
                    <div className={classes.catalog__franchisesContainer}>
                         {franchiseStore.isLoading ? (
                              <LoadingDots />
                         ) : (
                              franchiseStore.franchises.length === 0 ? (
                                   <NothingFound 
                                        text="К сожалению, франшизы не найдены. Попробуйте изменить критерии поиска или обратитесь в службу поддержки." 
                                   />
                              ) : (
                                   franchiseStore.franchises.map((franchise) => (
                                        <CatalogFranchiseCard key={franchise.id} franchise={franchise} />
                                   ))
                              )
                         )}
                    </div>
                    <div className={classes.catalog__bottom}>
                         {!isCatalogPage ? (
                              <ScrollToTopNavLink to={FRANCHISE_CATALOG_ROUTE}>
                                   <CustomButton bodyType="empty" style={{ display: "flex", margin: "0 auto" }}>
                                        Больше франшиз
                                   </CustomButton>
                              </ScrollToTopNavLink>
                         ) : (
                              <Pagination
                                   currentPage={franchiseStore.page}
                                   totalPages={totalPages}
                                   onPageChange={handlePageChange}
                              />
                         )}
                    </div>
               </div>
          </div>
     );
};

export default observer(FranchisesCatalog);
