import React, { useState, useRef, useEffect } from "react";
import classes from './AllFiltersButton.module.scss';
import AllFiltersTriangle from '../../assets/icons/allFiltersTriangle.svg';
import FilterButton from "./FilterButton";
import IndustryDropdownButtons from "./IndustryDropdownButtons";

interface FilterOption {
    id: number;
    name: string;
}

interface Industry {
    id: number;
    name: string;
    types: FilterOption[];
}

interface AllFiltersButtonProps {
    onBudgetChange: (budget: string) => void;
    onSortChange: (sort: string) => void;
    onTypeChange: (types: number[]) => void; 
    industries: Industry[];
}

const AllFiltersButton: React.FC<AllFiltersButtonProps> = ({
    onBudgetChange,
    onSortChange,
    onTypeChange,
    industries,
}) => {
    const [isFiltersVisible, setFiltersVisible] = useState(false);
    const [selectedBudget, setSelectedBudget] = useState("Все");
    const [selectedSort, setSelectedSort] = useState("По популярности");
    const [selectedTypes, setSelectedTypes] = useState<number[]>([]);
    const [expandedIndustries, setExpandedIndustries] = useState<number[]>([]);

    const filtersRef = useRef<HTMLDivElement>(null);

    const toggleFiltersVisibility = () => {
        setFiltersVisible(!isFiltersVisible);
    };

    const handleBudgetChange = (budget: string) => {
        const newBudget = selectedBudget === budget ? "Все" : budget;
        setSelectedBudget(newBudget);
        onBudgetChange(newBudget);
    };

    const handleSortChange = (sort: string) => {
        setSelectedSort(sort);
        onSortChange(sort);
    };

    const handleTypeChange = (type: number) => {
        setSelectedTypes(prev => {
            const updatedTypes = prev.includes(type) 
                ? prev.filter(t => t !== type) 
                : [...prev, type];
            onTypeChange(updatedTypes);
            return updatedTypes;
        });
    };

    const toggleIndustryDropdown = (industryId: number) => {
        setExpandedIndustries(prev => 
            prev.includes(industryId) 
                ? prev.filter(id => id !== industryId) 
                : [...prev, industryId]
        );
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (filtersRef.current && !filtersRef.current.contains(event.target as Node)) {
            setFiltersVisible(false);
        }
    };

    useEffect(() => {
        if (isFiltersVisible) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isFiltersVisible]);

    return (
        <div className={classes.allFilters_container} ref={filtersRef}>
            <button className={classes.allFilters} onClick={toggleFiltersVisibility}>
                <img src={AllFiltersTriangle} alt="Все фильтры" />
                Все фильтры
            </button>
            {isFiltersVisible && (
                <div className={classes.filters__config}>
                    <div className={classes.filters__box}>
                        <p>Сортировка</p>
                        <div className={classes.filters__buttonsBox}>
                            {['По популярности', 'Сначала дешевые', 'Сначала дорогие', 'По сроку окупаемости'].map((sort) => (
                                <FilterButton
                                    key={sort}
                                    isActive={selectedSort === sort}
                                    onClick={() => handleSortChange(sort)}
                                >
                                    {sort}
                                </FilterButton>
                            ))}
                        </div>
                    </div>
                    <div className={classes.filters__box}>
                        <p>Бюджет</p>
                        <div className={classes.filters__buttonsBox}>
                            {['Все', 'До 50 тыс', 'До 100 тыс', 'До 200 тыс', 'До 300 тыс', 'До 500 тыс', 'До 1 млн', 'До 1.5 млн', 'До 2 млн', 'Более 2 млн'].map((budget) => (
                                <FilterButton
                                    key={budget}
                                    isActive={selectedBudget === budget}
                                    onClick={() => handleBudgetChange(budget)}
                                >
                                    {budget}
                                </FilterButton>
                            ))}
                        </div>
                    </div>
                    <div className={classes.filters__box}>
                        <p>Тип активности</p>
                        <div className={classes.filters__dropdownButtons}>
                            {industries.map((industry) => (
                                <IndustryDropdownButtons
                                    key={industry.id}
                                    industry={industry}
                                    selectedTypes={selectedTypes}
                                    onTypeChange={handleTypeChange}
                                    expandedIndustry={expandedIndustries}
                                    toggleIndustryDropdown={toggleIndustryDropdown}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AllFiltersButton;