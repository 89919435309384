import React from 'react';
import ScrollToTopNavLink from '../ScrollToTopNavLink';
import classes from './QuestionnaireButton.module.scss';
import cheeseForQuestionnaire from '../../assets/cheeseForQuestionnaire.svg';
import { QUESTIONARY_ROUTE } from '../../utilits/consts';

interface QuestionnaireButtonProps extends React.HTMLAttributes<HTMLAnchorElement> {
    onClick?: () => void;
}

const QuestionnaireButton: React.FC<QuestionnaireButtonProps> = ({ onClick, ...props }) => {
     return (
          <ScrollToTopNavLink 
               to={QUESTIONARY_ROUTE} 
               className={classes.questionnaireButton} 
               onClick={onClick}
               {...props}
          >
               Пройти анкету
               <div className={classes.questionnaireButton__cheese}>
                    <img src={cheeseForQuestionnaire} alt="" />
               </div>
          </ScrollToTopNavLink>
     );
}

export default QuestionnaireButton;