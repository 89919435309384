import React, { createContext, useContext, useState, ReactNode, useEffect, useRef } from 'react';
import Notification from '../UI/notifications/Notification';

interface INotification {
    id: number;
    message: string;
}

interface NotificationContextProps {
    notify: (message: string) => void;
    showLoading: (message: string) => void;
    hideLoading: () => void;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

export const useNotification = () => {
     const context = useContext(NotificationContext);
     if (!context) {
          throw new Error('useNotification должен быть использован в NotificationProvider');
     }
     return context;
};

     export const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
     const [notifications, setNotifications] = useState<INotification[]>([]);
     const [loadingNotification, setLoadingNotification] = useState<string | null>(null);
     const notificationTimers = useRef<Map<number, NodeJS.Timeout>>(new Map());

     const notify = (message: string) => {
          const id = Date.now();
          setNotifications((prev) => [...prev, { id, message }]);

          const timer = setTimeout(() => {
               removeNotification(id);
          }, 3000);

          notificationTimers.current.set(id, timer);
     };

     const removeNotification = (id: number) => {
          setNotifications((prev) => prev.filter((notification) => notification.id !== id));
          clearNotificationTimer(id);
     };

     const clearNotificationTimer = (id: number) => {
          const timer = notificationTimers.current.get(id);
          if (timer) {
               clearTimeout(timer);
               notificationTimers.current.delete(id);
          }
     };

     const showLoading = (message: string) => {
          setLoadingNotification(message);
     };

     const hideLoading = () => {
          setLoadingNotification(null);
     };

     useEffect(() => {
               return () => {
                    notificationTimers.current.forEach((timer) => clearTimeout(timer));
               };
          }, []);

     return (
          <NotificationContext.Provider value={{ notify, showLoading, hideLoading }}>
               {children}
               <Notification notifications={notifications} loadingNotification={loadingNotification} />
          </NotificationContext.Provider>
     );
};
