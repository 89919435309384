import PhoneInputComponent from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import classes from './PhoneInput.module.scss';
import classNames from 'classnames';
import ExclamationPoint from './../../assets/icons/exclamation_point.svg';

interface PhoneInputProps {
      value: string;
      onChange: (phone: string) => void;
      error?: boolean;
      country?: string;
   }
   
   const PhoneInput: React.FC<PhoneInputProps> = ({ value, onChange, error = false, country = 'by' }) => {
      return (
            <div className={classes.phoneInputWrapper}>
                  <PhoneInputComponent
                        country={country}
                        value={value}
                        onChange={onChange}
                        inputClass={classNames(classes.phoneInput, {[classes.error]: error})}
                        containerClass={classes.phoneInputContainer}
                        dropdownClass={classes.phoneDropDown}
                        buttonClass={classNames(classes.phoneInputButton, {[classes.error]: error})}
                  />
                  {error && <img className={classes.errorIcon} src={ExclamationPoint} alt="Ошибка" />}
            </div>
      );
   };
   
export default PhoneInput;