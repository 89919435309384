import { makeAutoObservable } from "mobx";
import { IFranchise } from "../models/IFranchise";
import UserFranchiseService from "../service/UserFranchiseService";

export default class UserFranchiseStore {
    private _userFranchises: IFranchise[] = [];
    isLoading = false;

    constructor() {
        makeAutoObservable(this);
    }

    setLoading(bool: boolean) {
        this.isLoading = bool;
    }

    async loadUserFranchises() {
        try {
            this.setLoading(true);
            const franchises = await UserFranchiseService.fetchUserFranchises();
            this._userFranchises = franchises;
        } catch (error: any) {
            console.error("Ошибка загрузки франшиз пользователя", error);
        } finally {
            this.setLoading(false);
        }
    }

    async loadPublicUserFranchises(id:any) {
        try {
            this.setLoading(true);
            const franchises = await UserFranchiseService.fetchPublicUserFranchises(id);
            this._userFranchises = franchises;
        } catch (error: any) {
            console.error("Ошибка загрузки франшиз пользователя", error);
        } finally {
            this.setLoading(false);
        }
    }

    async addFranchiseToUser(franchiseId: number) {
        try {
            const newFranchise = await UserFranchiseService.addFranchiseToUser(franchiseId);
            this._userFranchises.push(newFranchise);
        } catch (error: any) {
            console.error("Ошибка добавления в франшизы пользователю ", error);
        }
    }

    async removeFranchise(franchiseId: number) {
        try {

            await UserFranchiseService.removeFranchise(franchiseId);
        } catch (error: any) {
            console.error("Ошибка удаления франшизы", error);
        }
    }

    get userFranchises(): IFranchise[] {
        return this._userFranchises;
    }
}
