import $api from "../http/index";

export default class NewsService{
     static async createNews(
          title: string, 
          img: File,
          details: string,
          content:string,
     ) {
          const formData = new FormData();
          formData.append('title', title);
          formData.append('img', img);
          formData.append('details', details.toString());
          formData.append('content', content.toString());
          
          try { 
               const { data } = await $api.post('/news/create', formData, {
               headers: {
                    'Content-Type': 'multipart/form-data',
               }
          });
               return data;
          }
          catch(e){
               throw e;
          }
     }

     static async fetchNews() {
          try { 
               const { data } = await $api.get('/news/get-all');
               return data;
          }
          catch(e){
               throw e;
          }
     }

     static async fetchOneNews(id: number) {
          try { 
               const { data } = await $api.get(`/news/${id}`);
               return data;
          }
          catch(e) {
               throw e;
          }
     }

     static async deleteOne(id: number){
          try { 
               const {data} = await $api.delete(`/news/remove/${id}`);
               return data
          }
          catch(e) {
               throw e;
          }
     }
}