import React from 'react';
import CustomButton from './buttons/CustomButton';
import ScrollToTopNavLink from './ScrollToTopNavLink';
import classes from './UserFranchiseList.module.scss';
import NothingFound from '../pages/NothingFound';
import FranchiseBox from './franchiseBoxes/FranchiseBox';
import { IFranchise } from '../models/IFranchise';
import { FRANCHISE_CREATION_ROUTE } from '../utilits/consts';

interface UserFranchiseListProps {
      userFranchises: Array<IFranchise>;
      isPublicProfile?: boolean;
}

const UserFranchiseList: React.FC<UserFranchiseListProps> = ({ 
      userFranchises,
      isPublicProfile = false
}) => {
      return (
            <div className={classes.userFranchise}>
                  <div className={classes.userFranchise__content}>
                        {isPublicProfile ? (
                              <>
                                    <div className={classes.userFranchise__header}>
                                          <h1>Франшизы пользователя</h1>
                                    </div>
                                    <div className={classes.userFranchise__box}>
                                          {userFranchises.map(franchise => (
                                                (franchise.status !== "Pending" && franchise.status !== "Rejected") && (
                                                      <FranchiseBox key={franchise.id} franchise={franchise} isYourProfile={false} />
                                                )
                                          ))}
                                          {userFranchises.length === 0 && (
                                          <>
                                                <NothingFound text={"У пользователя пока нет активных объявлений"}/>
                                          </>
                                          )}
                                    </div>
                              </>
                        ) : (
                              <>
                                    <div className={classes.userFranchise__header}>
                                          <h1>Мои объявления</h1>
                                          {userFranchises.length !== 0 && (
                                                <ScrollToTopNavLink to={FRANCHISE_CREATION_ROUTE}>
                                                      <CustomButton bodyType="empty">
                                                            Подать объявление
                                                      </CustomButton>
                                                </ScrollToTopNavLink>
                                          )}
                                    </div>
                                    <div className={classes.userFranchise__box}>
                                          {userFranchises.map((franchise) => (
                                                <FranchiseBox key={franchise.id} franchise={franchise} isYourProfile={true} />
                                          ))}

                                          {userFranchises.length === 0 && (
                                                <>
                                                      <NothingFound text={"У вас пока нет активных объявлений. Начните делиться своими возможностями прямо сейчас!"} />
                                                      <ScrollToTopNavLink to={FRANCHISE_CREATION_ROUTE}>
                                                            <CustomButton isCheeseButton style={{ marginLeft: "42%" }}>
                                                                  Подать объявление
                                                            </CustomButton>
                                                      </ScrollToTopNavLink>
                                                </>
                                          )}
                                    </div>
                              </>
                        )}
                  </div>
            </div>
      );
};

export default UserFranchiseList;
