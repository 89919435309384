import { makeAutoObservable } from "mobx";
import { ITypeOfActivity } from "../models/ITypeOfActivity";
import TypeService from "../service/TypeService";

export default class TypeOfActivityStore {
      types: ITypeOfActivity[] = [];
       _selectedType: number=0;

      constructor() {
            makeAutoObservable(this);
            this.fetchTypesOfActivities();
      }

      async createType(type: ITypeOfActivity) {
            try {
                  const newType = await TypeService.createTypeOfActivity(type);
                  this.types.push(newType)
            } catch (error: any) {
                  const message = error.response?.data?.message || error.message || 'Неизвестная ошибка';
                  throw new Error(message)
            }
      }

      async deleteType(id:number) {
            return await TypeService.deleteOneTypeById(id);
          
      }

      async fetchTypesOfActivities() {
            const fetchedTypes = await TypeService.fetchTypesOfActivities();
            this.types = fetchedTypes;
      }
      
      async getTypesByIndustryId(industryId: number) {
            const fetchedTypes = await TypeService.getTypesByIndustryId(industryId);
            this.types = fetchedTypes;
      }

      getName(id: number): string {
            const type = this.types.find(ind => ind.id === id);
            return type ? type.name : "Неизвестная тип";
      }

      setSelectedTypeId(type:any){
            this._selectedType=type
      }

      get getSelectedTypeId(){
            return this._selectedType
      }
}
