import React, { ChangeEvent, useContext, useEffect,useCallback, useState } from "react";
import classes from "../styles/Profile.module.scss";
import banner from '../assets/advertisingBanners/banner.png';
import { Context } from "../index";
import { STATIC_URL } from "../http";
import { observer } from "mobx-react-lite";
import ProfileCard from "../UI/cards/ProfileCard";
import UserFranchiseList from "../UI/UserFranchiseList";
import DefaultImage from '../assets/defaultUser.png'

interface FormData {
     email: string;
     login: string;
     firstName: string;
     lastName: string;
     userImage: string;
}

interface FieldErrors {
     email: boolean;
     login: boolean;
     firstName: boolean;
     lastName: boolean;
     userImage: boolean;
}

const initialFieldErrors: FieldErrors = {
     email: false,
     login: false,
     firstName: false,
     lastName: false,
     userImage: false,
};

const Profile: React.FC = () => {
     const { userStore, userFranchiseStore } = useContext(Context);
     
     const [isEditing, setIsEditing] = useState(false);
     const [isSubmitClicked, setIsSubmitClicked] = useState<boolean>(false);
     
     const [userData, setUserData] = useState({
          firstName: userStore.user.firstName,
          lastName: userStore.user.lastName,
          login: userStore.user.login,
          email: userStore.user.email,
          userImage: userStore.user.img ? `${STATIC_URL}/users/${userStore.user.img}` : DefaultImage,
     });
     const [fieldErrors, setFieldErrors] = useState<FieldErrors>(initialFieldErrors);
     const [isLoading, setIsLoading] = useState<boolean>(false);
     const [serverError, setServerError] = useState<string | null>(null);
     const [profileImage, setProfileImage] = useState<File | null>(null);
 
     useEffect(() => {
          const loadUserData = async () => {
               await userFranchiseStore.loadUserFranchises();
          };
          loadUserData();
     }, [userFranchiseStore, userStore]);
 
     const toggleEditing = () => {
          if (isEditing) {
               setUserData({
                    firstName: userStore.user.firstName,
                    lastName: userStore.user.lastName,
                    login: userStore.user.login,
                    email: userStore.user.email,
                    userImage: userStore.user.img ? `${STATIC_URL}/users/${userStore.user.img}` : DefaultImage,
               });
              setFieldErrors(initialFieldErrors);
          }
          setIsEditing(prev => !prev);
     };
 
     const handleReset = () => {
          setUserData({
              firstName: userStore.user.firstName,
              lastName: userStore.user.lastName,
              login: userStore.user.login,
              email: userStore.user.email,
              userImage: `${STATIC_URL}/users/${userStore.user.img}`,
          });
          setFieldErrors(initialFieldErrors);
          setProfileImage(null);
          setIsEditing(false);
     };
      
     const handleSave = async () => {
          setIsSubmitClicked(true);
          setIsLoading(true);
          setServerError(null);
      
          const isFormValid = validateForm();
      
          if (!isFormValid) {
              setIsLoading(false);
              return;
          }
      
          try {
               const formData = new FormData();
               formData.append('firstName', userData.firstName);
               formData.append('lastName', userData.lastName);
               formData.append('login', userData.login);
               formData.append('email', userData.email);
               if (profileImage) {
                    formData.append('userImage', profileImage);
               }
      
               await userStore.updateUser(userData.email, userData.firstName, userData.lastName, userData.login, profileImage || new File([], ""));
               setIsEditing(false);
          } catch (error: any) {
               setServerError(error.message);
               if(error.message) {
                    if (error.message.includes('Данный логин уже занят')) {
                         setFieldErrors(prevErrors => ({
                              ...prevErrors,
                              login: true
                         }));
                    }
                    if (error.message.includes('Данная почта уже занята')) {
                         setFieldErrors(prevErrors => ({
                              ...prevErrors,
                              email: true
                         }));
                    }
               }
          } finally {
              setIsLoading(false);
          }
     };
      
     const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, field: keyof FormData) => {
          const { value } = e.target;
          setUserData(prevState => {
               const newState = { ...prevState, [field]: value };
               if (isSubmitClicked) {
                    validateField(field, value);
               }
               return newState;
          });
     };
 
     const validateField = useCallback((name: keyof FormData, value: string) => {
          let isValid = true;
      
          switch (name) {
               case 'login':
                    isValid = /^[a-zA-Z0-9]{4,}$/.test(value);
                    break;
               case 'email':
                    isValid = /^[a-z0-9.%_+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i.test(value);
                    break;
               case 'firstName':
               case 'lastName':
                    isValid = value.trim() !== '';
                    break;
               default:
                    isValid = true;
          }
      
          setFieldErrors(prevErrors => ({
               ...prevErrors,
               [name]: !isValid
          }));
      
          return isValid;
     }, []);
      
     const validateForm = () => {
          const isFirstNameValid = validateField('firstName', userData.firstName);
          const isLastNameValid = validateField('lastName', userData.lastName);
          const isLoginValid = validateField('login', userData.login);
          const isEmailValid = validateField('email', userData.email);
          
          return isFirstNameValid && isLastNameValid && isLoginValid && isEmailValid;
     };
 
     const handleImageChange = (image: File) => {
         setProfileImage(image);
         setUserData(prev => ({ ...prev, userImage: URL.createObjectURL(image) }));
     };
 
     const LogoutHandle = () => {
         userStore.logout();
     };
 
     const userFranchises = userFranchiseStore.userFranchises;

     return (
          <div className={classes.profile}>
               <div className={classes.profile__content}>
                    <div className={classes.profile__wrapper}>
                         <div className={classes.userData__box}>
                              <h1>Добрый день, {userStore.user.firstName}!</h1>
                              <ProfileCard
                                   userData={userData}
                                   fieldErrors={fieldErrors}
                                   isEditing={isEditing}
                                   isLoading={isLoading}
                                   isSubmitClicked={isSubmitClicked}
                                   handleInputChange={handleInputChange}
                                   handleImageChange={handleImageChange}
                                   toggleEditing={toggleEditing}
                                   handleSave={handleSave}
                                   handleReset={handleReset}
                                   LogoutHandle={LogoutHandle}
                                   serverError={serverError}
                              />
                         </div>
                         <div className={classes.advertising_banner}>
                              <img src={banner} alt="" />
                         </div>
                    </div>
               </div>
               
              <UserFranchiseList userFranchises={userFranchises} />
          </div>
     );
}

export default observer(Profile);