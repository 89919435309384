import { IIndustry } from '../models/IIndustry';
import $api from "../http/index";

export default class IndustryService {
    static async createIndustry(industry: IIndustry) {
        try{
            const formData = new FormData();
            formData.append('name', industry.name);
            const { data } = await $api.post('/industries/create', formData, { headers: {
                'Content-Type': 'multipart/form-data',
            },});
            return data;
        }
        catch(e){
            throw e;
        }
    }

    static async fetchIndustries() {
        try{
            const { data } = await $api.get('/industries/get-all');
            return data;
        }
        catch(e){
            throw e;
        }
    }

    static async deleteOneIndustryById(id:number){
        try{
            const {data} = await $api.delete(`/industries/delete/${id}`);
            return data
        }
        catch(e){
            throw e;
        }
    }
}