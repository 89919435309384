import { makeAutoObservable, runInAction, toJS } from "mobx";
import { IFranchise } from "../models/IFranchise";
import ReleaseService from "../service/ReleaseService";
import { IRelease } from "../models/IRelease";

export default class ReleaseStore {
     _releases: IRelease[] = [];
     _interactiveCards: IFranchise[] = [];
     _threeInteractiveCards: IFranchise[] = [];
     _specialFranchise?: IFranchise = undefined;

     constructor() {
          makeAutoObservable(this);
          this.fetchReleases();
          this.fetchThreeInteractiveCards();
          this.fetchSpecialFranchise();
     }

     async fetchReleases() {
          try {
               const releases = await ReleaseService.fetchReleases();
               this._releases = releases;
          } catch (error: any) {
               console.error("Ошибка загрузки релизов", error);
          }
     }

     async fetchSpecialFranchise() {
          try {
               const specialFranchise = await ReleaseService.fetchSpecialFranchise();
               this._specialFranchise = specialFranchise;
          } catch (error: any) {
               console.error("Ошибка загрузки особой франшизы", error);
          }
     }

     async setSpecialFranchise(releaseId: number) {
          try {
               const specialFranchise = await ReleaseService.setSpecialFranchise(releaseId);
               this._specialFranchise = specialFranchise;
          } catch (error: any) {
               console.error("Ошибка загрузки особой франшизы", error);
          }
     }

     async fetchAllInteractiveCards() {
          try {
               const interactiveCards = await ReleaseService.fetchAllInteractiveCards();
               this._interactiveCards = interactiveCards;
          } catch (error: any) {
               console.error("Ошибка загрузки особой франшизы", error);
          }
     }

     async fetchThreeInteractiveCards() {
          try {
               const threeInteractiveCards = await ReleaseService.fetchThreeInteractiveCards();
               this._threeInteractiveCards = threeInteractiveCards;
          } catch (error: any) {
               console.error("Ошибка загрузки особой франшизы", error);
          }
     }

     async addInteractiveCard(releaseId: number) {
          try {
               await ReleaseService.addInteractiveCard(releaseId);
          } catch (error: any) {
               console.error('Ошибка установки интерактивных карт:', error);
          }
     }

     async removeInteractiveCard(id: number) {
          try {
               await ReleaseService.removeInteractiveCard(id);
          } catch (error: any) {
               console.error('Ошибка установки интерактивных карт:', error);
          }
     }
     
     async addNewRelease(franchiseId: number) {
          try {
               const newFranchise = await ReleaseService.addNewRelease(franchiseId);
               this._releases.push(newFranchise);
          } catch (error: any) {
               console.error("Ошибка добавления релиза", error);
          }
     }
      
     async removeRelease(franchiseId: number) {
          try {
               await ReleaseService.removeRelease(franchiseId);
          } catch (error: any) {
               console.error("Ошибка удаления релиза", error);
          }
     }

     async updateThreeInteractiveCards(updatedCards: IFranchise[]) {
          try {
               const newInteractiveCards = updatedCards;
               this._threeInteractiveCards = newInteractiveCards;
          } catch (error: any) {
              console.error('Ошибка установки интерактивных карт:', error);
          }
     }
     
     get specialFranchise(): IFranchise | undefined {
          return toJS(this._specialFranchise);
     }
     
     get releases(): IRelease[] {
          return toJS(this._releases);
     }

     get interactiveCards(): IFranchise[] {
          return toJS(this._interactiveCards);
     }

     get threeInteractiveCards(): IFranchise[] {
          return toJS(this._threeInteractiveCards);
     }
}
