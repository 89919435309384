import React, { useRef, useCallback, useState } from 'react';
import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import classes from './GallerySwiper.module.scss';
import 'swiper/css';
import ImageModal from '../modals/ImageModal';

interface GallerySwiperProps {
     images: string[];
}

const GallerySwiper: React.FC<GallerySwiperProps> = ({ images }) => {
     const sliderRef = useRef<SwiperRef>(null);
     const [isBeginning, setIsBeginning] = useState(true);
     const [isEnd, setIsEnd] = useState(false);
     const [selectedImage, setSelectedImage] = useState<string | null>(null);

     const handlePrev = useCallback(() => {
          if (!sliderRef.current) {
               return;
          }
          sliderRef.current.swiper.slidePrev();
     }, []);

     const handleNext = useCallback(() => {
          if (!sliderRef.current) {
               return;
          } 
          sliderRef.current.swiper.slideNext();
     }, []);

     return (
          <div className={classes.gallery}>
               <Swiper
                    breakpoints={{
                         1000: { slidesPerView: 2 },
                         1600: { slidesPerView: 3 },
                    }}
                    onSlideChange={(swiper: SwiperClass) => {
                         setIsBeginning(swiper.isBeginning);
                         setIsEnd(swiper.isEnd);
                    }}
                    onReachBeginning={() => setIsBeginning(true)}
                    onReachEnd={() => setIsEnd(true)}
                    onFromEdge={() => {
                         setIsBeginning(sliderRef.current?.swiper.isBeginning || false);
                         setIsEnd(sliderRef.current?.swiper.isEnd || false);
                    }}
                    className={classes.gallerySwiper}
                    ref={sliderRef}
               >
               {images.map((imageUrl, index) => (
                    <SwiperSlide key={index}>
                         <img
                              src={imageUrl}
                              alt=""
                              onClick={() => setSelectedImage(imageUrl)}
                              className={classes.swiperImage}
                         />
                    </SwiperSlide>
               ))}
               </Swiper>
               <div className={classes.customNavigation}>
                    <button
                         className={classes.prevButton}
                         onClick={handlePrev}
                         disabled={isBeginning}
                    ></button>
                    <button
                         className={classes.nextButton}
                         onClick={handleNext}
                         disabled={isEnd}
                    ></button>
               </div>
               {selectedImage && (
                    <ImageModal image={selectedImage} onClose={() => setSelectedImage(null)} />
               )}
          </div>
     );
};

export default GallerySwiper;
