import React, { useContext, useEffect } from "react";
import { FAVORITES_ROUTE, LOGIN_ROUTE, PROFILE_ROUTE, REGISTRATION_ROUTE } from "../../utilits/consts";
import CustomButton from "./CustomButton";
import ScrollToTopNavLink from "../ScrollToTopNavLink";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import { NavLink } from "react-router-dom";

const NavigationButtons = () => {
    const {userStore} = useContext(Context);
    
    return (
        <>
            {userStore.isAuth ? (
                <>
                    <NavLink to={FAVORITES_ROUTE}>
                        <CustomButton bodyType="empty" padding="1.25rem 1.375rem">
                            Избранное
                        </CustomButton>
                    </NavLink>

                    <NavLink to={PROFILE_ROUTE}>
                        <CustomButton padding="1.25rem 2.5rem">
                            Профиль
                        </CustomButton>
                    </NavLink>
                </>
                ) : (
                <>
                    <ScrollToTopNavLink to={LOGIN_ROUTE}>
                        <CustomButton padding="1.25rem 2.5rem">
                            Вход
                        </CustomButton>
                    </ScrollToTopNavLink>

                    <ScrollToTopNavLink to={REGISTRATION_ROUTE}>
                        <CustomButton bodyType="empty" padding="1.25rem 1.375rem">
                            Регистрация
                        </CustomButton>
                    </ScrollToTopNavLink>
                </>
            )}
        </>
    );
};

export default observer(NavigationButtons);
