import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useSavePath = () => {
      const location = useLocation();

      useEffect(() => {
            localStorage.setItem('redirectPath', location.pathname);
      }, [location]);
};

export default useSavePath;