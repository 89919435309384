import { runInAction, toJS } from "mobx";
import $api from "../http/index";
import { IFranchise } from "../models/IFranchise";

export default class FranchiseService {
    
    static async createFranchise (
        name: string,
        royalty: number,
        advertisingFee: number,
        investments: number,
        ownPoints: number,
        franchisePoints: number,
        paybackTime: number,
        industryId: number,
        typeOfActivityId: number,
        brand: string,
        description: string,
        faceImage: File,
        galleryImages: File[],
    ) {
        const formData = new FormData();
        appendFranchiseData(formData, name, royalty, advertisingFee, investments, ownPoints, franchisePoints, paybackTime, industryId, typeOfActivityId, brand, description, faceImage, galleryImages);
    
        const response = await $api.post('/franchises/create', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response
    }

    static async updateFranchise (
        id: string,
        name: string,
        royalty: number,
        advertisingFee: number,
        investments: number,
        ownPoints: number,
        franchisePoints: number,
        paybackTime: number,
        industryId: number,
        typeOfActivityId: number,
        brand: string,
        description: string,
        faceImage: File,
        galleryImages: File[],
    ) {
        const formData = new FormData();
        appendFranchiseData(formData, name, royalty, advertisingFee, investments, ownPoints, franchisePoints, paybackTime, industryId, typeOfActivityId, brand, description, faceImage, galleryImages);

        return await $api.put(`/franchises/update/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    static async fetchFranchises(
        industryIds: number[] | string | undefined,
        typeOfActivityIds: number[] | string | undefined,
        page: number,
        limit: number,
        budget?: string,
        sort?: string
    ): Promise<{ franchises: IFranchise[]; totalCount: number }> {
        try {
            const params: any = {
                page,
                limit,
                budget,
                sort,
            };
    
            if (industryIds) {
                params.industryIds = Array.isArray(industryIds) ? industryIds.join(',') : industryIds;
            }
    
            if (typeOfActivityIds) {
                params.typeOfActivityIds = Array.isArray(typeOfActivityIds) ? typeOfActivityIds.join(',') : typeOfActivityIds;
            }
    
            const response = await $api.get('/franchises/get-all', { params });
            if (response && response.data) {
                return {
                    franchises: response.data.franchises,
                    totalCount: response.data.totalCount,
                };
            } else {
                throw new Error('Invalid response from API');
            }
        } catch (error) {
            console.error('Error fetching franchises:', error);
            throw error;
        }
    }

    static async fetchOneFranchise(id: number) {
        try {
            const { data } = await $api.get(`/franchises/get-one/${id}`);
            return data;
        }
        catch(e) {
            throw e;
        }
    }

    static async fetchFranchisesByIndustry(id:number, industryId:number, limit: number) {
        try {
            const response = await $api.get('/franchises/get-by-industry/', { params:{id,industryId,limit}});
            return response.data;
        }
        catch(e) {
            throw e;
        }
    }

    static async fetchFranchiseByReleaseId(id: number) {
        try {
            const { data } = await $api.get(`/franchises/get-by-release/${id}`);
            return data;
        }
        catch(e) {
            throw e;
        }
    }

    static async fetchTopFranchises(limit: number) {
        try {
            const response = await $api.get('/franchises/get-top/', {
                params: { limit }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
    
    static async deleteOneFranchise(id: number) {
        try {
            const { data } = await $api.delete(`/franchises/remove/${id}`);
            return data
        }
        catch(e) {
            throw e;
        }
    }
}

const appendFranchiseData = (
    formData: FormData,
    name: string,
    royalty: number,
    advertisingFee: number,
    investments: number,
    ownPoints: number,
    franchisePoints: number,
    paybackTime: number,
    industryId: number,
    typeOfActivityId: number,
    brand: string,
    description: string,
    faceImage: File | null,
    galleryImages: File[]
) => {
    formData.append('name', name);
    formData.append('royalty', royalty.toString());
    formData.append('advertisingFee', advertisingFee.toString());
    formData.append('investments', investments.toString());
    formData.append('ownPoints', ownPoints.toString());
    formData.append('franchisePoints', franchisePoints.toString());
    formData.append('paybackTime', paybackTime.toString());
    formData.append('industryId', industryId.toString());
    formData.append('typeOfActivityId', typeOfActivityId.toString());
    formData.append('brand', brand);
    formData.append('description', description);
    
    if (faceImage) {
        formData.append('faceImage', faceImage);
    }
    
    galleryImages.forEach((file) => {
        formData.append('galleryImages', file);
    });
};