import { makeAutoObservable, runInAction, toJS } from "mobx";
import { IFranchise } from "../models/IFranchise";
import FranchiseApprovalService from "../service/FranchiseApprovalService";

export default class FranchiseApprovalStore {
     private _franchises: IFranchise[] = [];
     private _loading: boolean = false;

     constructor() {
          makeAutoObservable(this);
     }

     async fetchApprovalFranchise() {
          this.setLoading(true);
          try {
               const franchises = await FranchiseApprovalService.fetchApprovalFranchise();
               runInAction(() => {
                    this._franchises = franchises;
               });
          } catch (e: any) {
               console.log(e.message);
          } finally {
               this.setLoading(false);
          }
     }

     async approveFranchise(franchiseId: number) {
          try {
               await FranchiseApprovalService.approveFranchise(franchiseId);
               this._franchises = this._franchises.filter(franchise => franchise.id !== franchiseId);
          } catch (e: any) {
               console.log(e.message);
          }
     }

     async denyFranchise(franchiseId: number) {
          try {
               await FranchiseApprovalService.denyFranchise(franchiseId);
               this._franchises = this._franchises.filter(franchise => franchise.id !== franchiseId);
          } catch (e: any) {
               console.log(e.message);
          }
     }

     get franchises(): IFranchise[] {
          return toJS(this._franchises);
     }

     get isLoading(): boolean {
          return this._loading;
     }

     private setLoading(loading: boolean) {
          this._loading = loading;
     }
}