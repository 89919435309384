import React from "react";
import classes from './SubscribeInput.module.scss';
import CustomButton from "../buttons/CustomButton";

const SubscribeInput = (props) => {
    return (
        <div className={classes.subInput}>
            <input className={classes.inputField} {...props} />
            <div className={classes.SubscribeButton}>
                <CustomButton 
                    padding="0.95rem 1.9rem"
                >Подписаться</CustomButton>
            </div>
        </div>
    );
};

export default SubscribeInput;