import React, { useContext, useEffect, useState } from "react";
import classes from './CatalogFranchiseCard.module.scss';
import VectorIcon from '../../assets/icons/vector.svg';
import { FRANCHISE_PAGE_ROUTE,PUBLIC_PROFILE_ROUTE } from "../../utilits/consts";
import ScrollToTopNavLink from "../ScrollToTopNavLink";
import { IFranchise } from "../../models/IFranchise";
import { ReactComponent as Heart } from './../../assets/icons/heart.svg';
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import { STATIC_URL } from "../../http";
import { formatPrice } from "../../utilits/utilities";
import { truncateText } from "../../utilits/truncateText";
import DefaultImage from '../../assets/defaultUser.png'
import { useNotification } from "../../hooks/useNotification";

const CatalogFranchiseCard: React.FC<{ franchise: IFranchise }> = ({ franchise }) => {
    const {favoritesStore, userStore } = useContext(Context);
    const [isFavorite, setIsFavorite] = useState<boolean>(favoritesStore.favorites.some(fav => fav.id === franchise.id));

    const { notify } = useNotification();

    const ownerInfo = franchise?.ownerInfo;
    const ownerImage = ownerInfo?.img ? `${STATIC_URL}/users/${ownerInfo.img}` : DefaultImage;
    
    const toggleFavorite = async () => {
        if(!userStore.isAuth) {
            notify("Авторизуйтесь для добавления франшизы в избранное")
            return;
        }

        try {
            if (isFavorite) {
                setIsFavorite(false);
                await favoritesStore.removeFavorite(franchise.id);
            } else {
                setIsFavorite(true);
                await favoritesStore.addFavorite(franchise.id);
            }
        } catch (error) {
            console.error("Не удалось обновить статус избранного:", error);
        }
    };

    return (
        <div className={classes.card__container}>
            <div className={classes.card__imagesBox}>
                <ScrollToTopNavLink to={`${FRANCHISE_PAGE_ROUTE}/${franchise.id}`} className={classes.franchise__image}>
                    <img src={`${STATIC_URL}/franchises/${franchise?.faceImage}`} alt="Franchise Image" />
                </ScrollToTopNavLink>

                <div className={classes.userImage__box}>
                    <ScrollToTopNavLink to={`${PUBLIC_PROFILE_ROUTE}/${ownerInfo?.id}`}>
                        <img src={ownerImage} alt={`Author image for ${ownerInfo?.firstName}`} />
                    </ScrollToTopNavLink>
                </div>
            </div>

            <div className={classes.card__info}>
                <p>{truncateText(franchise.name, 1, 20)}</p>
                <div className={classes.price_and_time_box}>
                    <span className={classes.price}>от {formatPrice(franchise.investments)} BYN</span>
                    <span className={classes.time}>
                        <img src={VectorIcon} alt="Вектор" />
                        <span>от {franchise.paybackTime} месяцев</span>
                    </span>
                </div>
                <div className={classes.buttons_container}>
                    <ScrollToTopNavLink to={`${FRANCHISE_PAGE_ROUTE}/${franchise.id}`}>
                        <span className={classes.details}>
                            Подробнее
                        </span>
                    </ScrollToTopNavLink>
                    <div className={isFavorite ? classes.favorite : ''} onClick={toggleFavorite} style={{cursor: "pointer"}}>
                        <Heart />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(CatalogFranchiseCard);