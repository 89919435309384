import React, { FC } from "react";
import NothingFoundImage from '../assets/nothingFound2.png';
import classes from '../styles/NothingFound.module.scss';

interface NothingFoundProps {
    text?: string;
}

const NothingFound: FC<NothingFoundProps> = ({ text = 'Cтраница не найдена' }) => {
    return (
        <div className={classes.nothingFound}>
            <img src={NothingFoundImage} alt="Nothing Found" />
            <p>{text}</p>
        </div>
    );
};

export default NothingFound;
