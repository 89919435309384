import React, { useContext, useState, useEffect, useRef, ChangeEvent } from "react";
import classes from './SearchInput.module.scss';
import SearchIcon from '../../assets/icons/search.svg';
import { Context } from "../../index";
import SearchItem from "./SearchItem";

interface SearchInputProps {
    isVisible: boolean;
}

const SearchInput: React.FC<SearchInputProps> = ({ isVisible }) => {
    const { franchiseStore } = useContext(Context);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [filteredData, setFilteredData] = useState(franchiseStore.franchises);
    const [showResults, setShowResults] = useState<boolean>(false);
    const searchRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (searchTerm.trim() !== '') {
            const filtered = franchiseStore.franchises.filter(item =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            if (filtered.length > 0) {
                setFilteredData(filtered);
                setShowResults(true);
            }
        } else {
            setShowResults(false);
        }
    }, [searchTerm]);

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleInputClick = () => {
        if (searchTerm.trim() !== '') {
            setShowResults(true);
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
            setShowResults(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={classes.searchInput} ref={searchRef}>
            <input
                className={`${classes.inputField} ${showResults && classes.inputFieldActive}`}
                value={searchTerm}
                onChange={handleSearch}
                onClick={handleInputClick}
                placeholder="Поиск..."
            />
            <img src={SearchIcon} alt="Поиск" className={classes.searchIcon} />
            {showResults && (
                <ul className={`${classes.searchResults} ${isVisible ? classes.active : classes.hidden}`}>
                    {filteredData.map(item => (
                        <SearchItem key={item.id} franchise={item} />
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SearchInput;