export async function validateImage(
      file: File,
      minWidth: number = 400,
      minHeight: number = 400,
      maxWidth: number = 5000,
      maxHeight: number = 5000
  ): Promise<void> {
      const allowedTypes = ['image/png', 'image/jpeg', 'image/webp', 'image/avif'];
      if (!allowedTypes.includes(file.type)) {
            throw new Error('Неверный формат изображения. Допустимые форматы: png, jpg, jpeg, webp, avif.');
      }
  
      const img = new Image();
      const reader = new FileReader();
  
      return new Promise((resolve, reject) => {
            reader.onload = () => {
                  img.src = reader.result as string;
            };
      
            img.onload = () => {
                  if (img.width < minWidth || img.height < minHeight) {
                        reject(new Error(`Изображение должно быть не меньше ${minWidth}x${minHeight} пикселей.`));
                        return;
                  }
                  if (img.width > maxWidth || img.height > maxHeight) {
                        reject(new Error(`Изображение не должно превышать ${maxWidth}x${maxHeight} пикселей.`));
                        return;
                  }
                  resolve();
            };
      
            img.onerror = () => {
                  reject(new Error('Не удалось загрузить изображение.'));
            };
      
            reader.readAsDataURL(file);
      });
  }
  