export function formatPrice(price: number | undefined | null): string {
      if (price === undefined || price === null) {
          return '';
      }
      const formattedPrice = new Intl.NumberFormat('ru-RU', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
      }).format(price);
      return formattedPrice;
  }
  