import React, { useContext, useEffect } from 'react';
import { Context } from '../../index';
import { observer } from 'mobx-react-lite';
import { IFranchise } from '../../models/IFranchise';
import FranchiseApprovalCard from '../../UI/cards/FranchiseApprovalCard';
import classes from './../../styles/CreationForms.module.scss';
import LoadingDots from '../../UI/loadingDots';
import NothingFound from '../NothingFound';

const PendingFranchises: React.FC = observer(() => {
     const { franchiseApprovalStore  } = useContext(Context);

     useEffect(() => {
          const fetchData = async () => {
               await franchiseApprovalStore.fetchApprovalFranchise();
          };
          fetchData();
     }, [franchiseApprovalStore]);

     if (franchiseApprovalStore.isLoading) {
          return (
               <>
                    <LoadingDots />
               </>
          )
     }

     return (
          <div className={classes.form__container}>
               <div className={classes.form__content}>
                    <h1>Франшизы на рассмотрении</h1>
                    {franchiseApprovalStore.franchises.length > 0 ? (
                         franchiseApprovalStore.franchises.map((franchise: IFranchise) => (
                              <FranchiseApprovalCard 
                                   key={franchise.id}
                                   franchise={franchise}
                              />
                         ))
                    ) : (
                         <NothingFound text='На данный момент не франшиз на одобрение'/>
                    )}
               </div>
          </div>
     );
});

export default PendingFranchises;
