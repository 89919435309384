import React, { useState, useContext } from 'react';
import classes from './NewsObject.module.scss';
import CustomButton from "../buttons/CustomButton";
import { STATIC_URL } from "../../http";
import { formatDate } from "../../utilits/formatDate";
import ScrollToTopNavLink from "../ScrollToTopNavLink";
import { NEWS_PAGE_ROUTE } from "../../utilits/consts";
import { ReactComponent as Clock } from '../../assets/icons/clock.svg';
import { truncateText } from "../../utilits/truncateText";


interface NewsObjectProps {
    image: string;
    date: string;
    newsContent: string;
    newsName: string;
    index: number;
    id: number
}

const NewsObject: React.FC<NewsObjectProps> = ({ image, date, newsContent, newsName, index, id }) => {
    
    return (
        <div className={`${classes.newsItem__container} ${index % 2 === 0 ? classes.even : classes.odd}`}>
            <div className={classes.news__image}>
                <img src={`${STATIC_URL}/news/${image}`} alt={newsName} />
            </div>
            <div className={classes.content__box}>
                <h2>{newsName}</h2>
                <p className={classes.news__creationTime}>
                    <Clock/>
                    {formatDate(date!)}
                </p>
                <p dangerouslySetInnerHTML={{ __html: truncateText(newsContent!, 3, 205) }}></p>
                <div className={classes.read__button}>
                    <ScrollToTopNavLink to={`${NEWS_PAGE_ROUTE}/${id}`} className={classes.toFranchisePage}>
                        <CustomButton>
                            Читать
                        </CustomButton>
                    </ScrollToTopNavLink>
                </div>
            </div>
        </div>
    );
};

export default NewsObject;
