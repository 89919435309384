import React from "react";
import classes from './FindYourFranchise.module.scss'
import QuestionnaireButton from "../../../UI/buttons/QuestionnaireButton";

const FindYourFranchise = () => {
    return (
        <div className={classes.findYourFranchise__container}>
            <div className={classes.findYourFranchise__content}>
                <h1>
                    Ищете<br />
                    <span>подходящую</span><br />
                    франшизу?
                </h1>
                <div className={classes.questionaryBox}>
                    <p>Мы можем помочь! Ответьте на несколько вопросов, и мы подберем для вас лучшие варианты.</p>
                    <QuestionnaireButton/>
                </div>
            </div>
        </div>
    )
}

export default FindYourFranchise;