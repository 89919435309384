import React, { useContext, useEffect, useState } from 'react';
import classes from './../styles/FranchiseCreator.module.scss';
import InputField from '../UI/inputs/InputField';
import FileUploader from '../UI/fileUploaders/FileUploader';
import { Context } from '../index';
import MultipleFileUploader from '../UI/fileUploaders/MultiplyFileUploader';
import { IIndustry } from '../models/IIndustry';
import CustomButton from '../UI/buttons/CustomButton';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FRANCHISE_EDIT_ROUTE, FRANCHISE_PAGE_ROUTE, HOME_ROUTE, PROFILE_ROUTE } from '../utilits/consts';
import { fetchFile } from '../utilits/fetchFile';
import { STATIC_URL } from '../http';
import { useNotification } from '../hooks/useNotification';
import DraftEditor from '../UI/inputs/DraftEditor';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { parsePhoneNumberFromString } from 'libphonenumber-js';


interface FormData {
    industryId: string;
    name: string;
    investments: string;
    paybackTime: string;
    typeOfActivityId: string;
    brand: string;
    advertisingFee: string;
    description: string;
    franchisePoints: string;
    ownPoints: string;
    royalty: string;
}

const FranchiseCreator: React.FC = () => {
    const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
    
    const { franchiseStore, industryStore, userFranchiseStore } = useContext(Context);
    const { notify, showLoading, hideLoading } = useNotification();
    
    const [frontImage, setFrontImage] = useState<File | null>(null);
    const [galleryImages, setGalleryImages] = useState<File[]>([]);
    const [errors, setErrors] = useState<{ [key: string]: boolean }>({});

    const [formData, setFormData] = useState<FormData>({
        industryId: '',
        name: '',
        investments: '',
        paybackTime: '',
        typeOfActivityId: '',
        brand: '',
        advertisingFee: '',
        description: '',
        franchisePoints: '',
        ownPoints: '',
        royalty: '',
    });
    
    const navigate = useNavigate(); 
    const location = useLocation();
    const isEditMode = location.pathname.startsWith(FRANCHISE_EDIT_ROUTE) && location.pathname.length > FRANCHISE_EDIT_ROUTE.length;
    const { id } = useParams<{ id: string }>(); 
    
    useEffect(() => {
        if (id && isEditMode) {
            const fetchFranchise = async () => {
                try {
                    showLoading("Загружаем франшизу");
                    const franchise = await franchiseStore.fetchOneFranchise(parseInt(id));

                    setFormData({
                        industryId: franchise.industryId.toString(),
                        name: franchise.name,
                        investments: franchise.investments.toString(),
                        paybackTime: franchise.paybackTime.toString(),
                        typeOfActivityId: franchise.typeOfActivityId.toString(),
                        brand: franchise.brand,
                        advertisingFee: franchise.advertisingFee.toString(),
                        description: franchise.description,
                        franchisePoints: franchise.franchisePoints.toString(),
                        ownPoints: franchise.ownPoints.toString(),
                        royalty: franchise.royalty.toString(),
                    });

                    const frontImageUrl = `${STATIC_URL}/franchises/${franchise.faceImage}`;
                    const frontImageFile = await fetchFile(frontImageUrl, franchise.faceImage);
                    setFrontImage(frontImageFile);
                    
                    const galleryFilesPromises = franchise.imageGallery.map(async (_, index) => {
                        const galleryImageUrl = `${STATIC_URL}/franchises/${franchise.imageGallery[index]}`;
                        return await fetchFile(galleryImageUrl, franchise.imageGallery[index]);
                    });
                    const galleryFiles = await Promise.all(galleryFilesPromises);
                    setGalleryImages(galleryFiles);

                    if (franchise.description) {
                        const contentState = convertFromRaw(JSON.parse(franchise.description));
                        setEditorState(EditorState.createWithContent(contentState));
                    }

                    hideLoading();
                } catch (error) {
                    console.error("Не удалось получить франшизу", error);
                }
            };
            fetchFranchise();
        }
    }, [id, franchiseStore]);
    
    const handleFrontImageChange = (newImage: File) => {
        setFrontImage(newImage);
    };

    const handleGalleryImagesChange = (newImages: File[]) => {
        setGalleryImages(newImages);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, field: keyof FormData) => {
        const value = e.target.value;
        setFormData({
            ...formData,
            [field]: value
        });
    
        setErrors({ ...errors, [field]: false });
    }; 

    const handleEditorContentChange = (content: string) => {
        setFormData({
            ...formData,
            description: content
        });
        setErrors({ ...errors, description: false });
    };

    const validateForm = (): boolean => {
        const newErrors: { [key: string]: boolean } = {};

        Object.keys(formData).forEach((key) => {
            if (!formData[key as keyof FormData]) {
                newErrors[key] = true;
            }
        });
        
        const requiredFields = {
            faceImage: frontImage,
            galleryImages: galleryImages.length > 0,
            description: formData.description
        };
        
        Object.entries(requiredFields).forEach(([field, isValid]) => {
            if (!isValid) {
                newErrors[field] = true;
            }
        });
        
        
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;
        
        try {
            const contentState = editorState.getCurrentContent();
            const contentJSON = JSON.stringify(convertToRaw(contentState));

            const franchiseParams: [
                string, number, number, number, number,
                number, number, number, number,
                string, string, File, File[]
            ] = [
                formData.name,
                Number(formData.royalty),
                Number(formData.advertisingFee),
                Number(formData.investments),
                Number(formData.ownPoints),
                Number(formData.franchisePoints),
                Number(formData.paybackTime),
                Number(formData.industryId),
                Number(formData.typeOfActivityId),
                formData.brand,
                contentJSON,
                frontImage!,
                galleryImages!
            ];

            showLoading(isEditMode ? "Сохраняем изменения" : "Создаем франшизу");
            if (isEditMode) {
                await franchiseStore.updateFranchise(id!, ...franchiseParams);
            } else {
                await franchiseStore.createFranchise(...franchiseParams);
            }
            hideLoading();
            notify(isEditMode ? "Франшиза успешно обновлена!" : "Франшиза успешно создана!");
            navigate(PROFILE_ROUTE);
        } catch (error) {
            console.error("Не удалось создать/изменить франшизу:", error);
        }
    };

    const handleDelete = async () => {
        try {
            if (!id) {
                setErrors({ ...errors, id: true });
                return;
            }
            showLoading("Удаляем франшизу")
            await userFranchiseStore.removeFranchise(parseInt(id))
            await franchiseStore.deleteOneFranchiseById(parseInt(id));
            hideLoading();
            notify("Франшиза успешно удалена!");
            navigate(PROFILE_ROUTE);
        } catch (error) {
            console.error("Не удалось удалить франшизу:", error);
        }
    };

    const handleIndustryChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        const industryId = Number(e.target.value);
        setFormData({
            ...formData,
            industryId: e.target.value,
            typeOfActivityId: ''
        });
    
        if (industryId) {
            await industryStore.fetchSubIndustries(industryId);
        }
    };
    
    return (
        <div className={classes.franchiseCreator__container}>
            <div className={classes.franchiseCreator__content}>

                <div className={classes.franchiseCreator__header}>
                    <h1>{isEditMode ? "Режим редактирования" : "Создание франшизы"}</h1>
                    {isEditMode &&
                        <CustomButton 
                            onClick={handleDelete}
                            bodyType="empty"
                        >
                            Удалить франшизу
                        </CustomButton>
                    }
                </div>

                <div className={classes.franchiseCreator__body}>
                    <FileUploader
                        onImageChange={handleFrontImageChange}
                        error={errors['faceImage']}
                        errorMessage="Это обязательное поле. Заполните его!"
                        defaultImageURL={frontImage ? URL.createObjectURL(frontImage) : undefined}
                    />
                    <div className={classes.franchise__grid}>
                        <InputField
                            as='select'
                            value={formData.industryId || ''}
                            onChange={handleIndustryChange}
                            error={errors.industryId}
                            errorMessage="Это обязательное поле. Заполните его!"
                        >
                            <option value="">Выберите категорию</option>
                            {industryStore.industries.map((industry: IIndustry) => (
                                <option key={industry.id} value={industry.id}>{industry.name}</option>
                            ))}
                        </InputField>
                        <InputField
                            as='select'
                            value={formData.typeOfActivityId || ''}
                            onChange={(e) => handleInputChange(e, 'typeOfActivityId')}
                            error={errors.typeOfActivityId}
                            errorMessage="Это обязательное поле. Заполните его!"
                        >
                            <option value="">Выберите тип активности</option>
                            {industryStore.getSubIndustries(Number(formData.industryId)).map((type) => (
                                <option key={type.id} value={type.id}>{type.name}</option>
                            ))}
                        </InputField>

                        <InputField
                            placeholder='Название'
                            value={formData.name}
                            maxLength={50}
                            onChange={(e) => handleInputChange(e, 'name')}
                            error={errors.name}
                            errorMessage="Это обязательное поле. Заполните его!"
                        />
                        <InputField
                            placeholder='Роялти от'
                            value={formData.royalty}
                            type='number'
                            onChange={(e) => handleInputChange(e, 'royalty')}
                            maxLength={4}
                            error={errors.royalty}
                            errorMessage="Это обязательное поле. Заполните его!"
                            infoType={"%"}
                        />
                        <InputField
                            placeholder='Бренд'
                            value={formData.brand}
                            onChange={(e) => handleInputChange(e, 'brand')}
                            maxLength={50}
                            error={errors.brand}
                            errorMessage="Это обязательное поле. Заполните его!"
                        />
                        <InputField
                            placeholder='Рекламный сбор'
                            type='number'
                            maxLength={15}
                            value={formData.advertisingFee}
                            onChange={(e) => handleInputChange(e, 'advertisingFee')}
                            error={errors.advertisingFee}
                            errorMessage="Это обязательное поле. Заполните его!"
                            infoType={"BYN"}
                        />
                        <InputField
                            placeholder='Инвестиции'
                            type='number'
                            maxLength={15}
                            value={formData.investments}
                            onChange={(e) => handleInputChange(e, 'investments')}
                            error={errors.investments}
                            errorMessage="Это обязательное поле. Заполните его!"
                            infoType={"BYN"}
                        />
                        <InputField
                            placeholder='Кол-во собственных точек'
                            value={formData.ownPoints}
                            maxLength={30}
                            onChange={(e) => handleInputChange(e, 'ownPoints')}
                            error={errors.ownPoints}
                            errorMessage="Это обязательное поле. Заполните его!"
                        />
                        <InputField
                            placeholder='Окупаемость'
                            type='number'
                            maxLength={3}
                            value={formData.paybackTime}
                            onChange={(e) => handleInputChange(e, 'paybackTime')}
                            error={errors.paybackTime}
                            errorMessage="Это обязательное поле. Заполните его!"
                            infoType={"месяцев"}
                        />
                        <InputField
                            placeholder='Кол-во франчайзинговых точек'
                            type='number'
                            maxLength={10}
                            value={formData.franchisePoints}
                            onChange={(e) => handleInputChange(e, 'franchisePoints')}
                            error={errors.franchisePoints}
                            errorMessage="Это обязательное поле. Заполните его!"
                        />
                    </div>
                </div>
                
                <h2>Описание *</h2>
                <DraftEditor
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                    onContentChange={handleEditorContentChange}
                    errorMessage="Это обязательное поле. Заполните его!"
                    error={errors.description}
                    maxLength={4000}
                />

                <h1>Фотографии</h1>
                <MultipleFileUploader 
                    onImagesChange={handleGalleryImagesChange}
                    error={errors['galleryImages']}
                    errorMessage="Это обязательное поле. Заполните его!"
                    defaultFiles={galleryImages}
                />
                <CustomButton onClick={handleSubmit} isCheeseButton>
                    {isEditMode ? "Сохранить изменения" : "Подать объявление"}
                </CustomButton> 
            </div>
        </div>
    );
};

export default FranchiseCreator;