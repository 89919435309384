import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowImg from '../../assets/icons/arrow.svg';
import classes from './ComeBack.module.scss';

interface ComeBackProps {
    onBack?: () => void;
    label?: string;
    icon?: string;
    className?: string;
    style?: React.CSSProperties;
}

const ComeBack: React.FC<ComeBackProps> = ({
    onBack,
    label = 'Вернуться назад',
    icon = ArrowImg,
    className = '',
    style = {},
}) => {
    const navigate = useNavigate();

    const handleBack = () => {
        if (onBack) {
            onBack();
        } else {
            navigate(-1);
        }
    };

    return (
        <div
            className={`${classes.comeBack} ${className}`}
            onClick={handleBack}
            style={style}
        >
            <img src={icon} alt="Back Icon" />
            <b>{label}</b>
        </div>
    );
};

export default ComeBack;