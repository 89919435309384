import React, { CSSProperties } from 'react';
import classes from './Skeleton.module.scss';

interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
     height?: string | number;
     width?: string | number;
     borderRadius?: string | number;
     contrast?: 'light' | 'dark';
}

const Skeleton: React.FC<SkeletonProps> = ({
     height,
     width,
     borderRadius,
     contrast = 'light',
     className, // Accept the className prop
     ...props
}) => {
     const skeletonStyle: CSSProperties = {
          height: height || 'inherit',
          width: width || 'inherit',
          borderRadius: borderRadius || '0',
          ...props.style,
     };

     const contrastClass = contrast === 'dark' ? classes.skeletonDark : classes.skeletonLight;

     return (
          <div 
               className={`${classes.skeleton} ${contrastClass} ${className}`}
               style={skeletonStyle}
               {...props}
          ></div>
     );
}

export default Skeleton;
