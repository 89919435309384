import React from "react";
import classes from './ProfileCard.module.scss';
import { IUser } from "../../models/IUser";
import { STATIC_URL } from "../../http";
import DefaultUserImage from '../../assets/defaultUser.png'

interface ProfileCardProps {
     user: IUser;
}

const PublicProfileCard: React.FC<ProfileCardProps> = ({
     user,
}) => {
     const userImage = user.img ? `${STATIC_URL}/users/${user.img}` : DefaultUserImage; 
     console.log(userImage)
     return (
          <div className={classes.profile__card}>
               <h2>Личные данные</h2> 
               <div className={classes.profile__box}>
                    <div className={classes.profileImage}>
                         <img src={userImage} alt="" />
                    </div>
                    <div className={classes.user__info}>
                         <h3>{user.firstName} {user.lastName}</h3>
                         <span>{user.email}</span>
                    </div>
               </div>
        </div>
    );
};

export default PublicProfileCard;
