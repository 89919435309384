import React, { useContext } from "react";
import classes from "./WelcomeSection.module.scss";
import DotsImg from '../../../assets/dots.svg';
import InteractiveCards from "../../../UI/cards/InteractiveCards";
import { Context } from "../../../index";
import { observer } from "mobx-react-lite";
import CustomButton from "../../../UI/buttons/CustomButton";
import ScrollToTopNavLink from "../../../UI/ScrollToTopNavLink";
import { FRANCHISE_CATALOG_ROUTE, FRANCHISE_CREATION_ROUTE } from "../../../utilits/consts";

const WelcomeSection = () => {
    const {userStore} = useContext(Context);

    return (
        <div className={classes.welcome__container}>
            <div className={classes.welcome__content}>
                <div className={classes.welcome__box}>
                    <div className={classes.welcome__text}>
                        <h1>Открой мир возможностей с нашей франшизой</h1>
                        <p>Дайте новый импульс вашему бизнесу с francheese! Покупайте и продавайте франшизы на нашей платформе, где вас всегда поддержат и помогут в любом вопросе</p>
                    </div>
                    <ScrollToTopNavLink to={userStore.isAuth ? FRANCHISE_CREATION_ROUTE : FRANCHISE_CATALOG_ROUTE}>
                        <CustomButton className={classes.welcome__button} isCheeseButton fontSize="1.2rem">
                            {userStore.isAuth ? 'Предложить свою франшизу' : 'Погрузиться в мир francheese'}
                        </CustomButton>
                    </ScrollToTopNavLink>
                    <img className={classes.dots__image} src={DotsImg} alt="Точки" />
                </div>
                <div className={classes.interactiveCards}>
                    <InteractiveCards />
                </div>
            </div>
        </div>
    );
}

export default observer(WelcomeSection);
