import React, { useState, useContext } from 'react';
import classes from './../../styles/CreationForms.module.scss';
import InputField from '../../UI/inputs/InputField';
import { Context } from '../..';
import { ITypeOfActivity } from '../../models/ITypeOfActivity';
import CustomButton from '../../UI/buttons/CustomButton';
import { useNotification } from '../../hooks/useNotification';

const TypeCreator: React.FC = () => {
     const { typeOfActivityStore, industryStore } = useContext(Context);
     const { notify, showLoading, hideLoading } = useNotification();

     const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
     const [formData, setFormData] = useState<ITypeOfActivity>({
          id: 0,
          name: '',
          industryId: 0
     });

     const [selectedTypeId, setSelectedTypeId] = useState<number | null>(null);
     const [selectedIndustryId, setSelectedIndustryId] = useState<number | null>(null);

     const handleInputChange = (
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
          field: keyof ITypeOfActivity
     ) => {
          const value = e.target.value;
          setFormData({
               ...formData,
               [field]: value,
          });

          setErrors({ ...errors, [field]: false });
     };

     const handleSubmit = async () => {
          try {
               if (!formData.name || formData.industryId === 0) {
                    setErrors({
                         name: !formData.name,
                         industryId: formData.industryId === 0,
                    });
                    return;
               }
               showLoading("Создаем новый тип активности")
               await typeOfActivityStore.createType(formData);
               hideLoading();
               notify(`Тип активности ${formData.name} успешно создан!`);
               setFormData({ id: 0, name: '', industryId: 0 });
               setErrors({});
          } catch (error) {
               console.error("Не удалось создать тип:", error);
          }
     };

     const handleDelete = async () => {
          if (selectedTypeId !== null) {
               try {
                    showLoading("Удаляем тип активности")
                    await typeOfActivityStore.deleteType(selectedTypeId);
                    hideLoading();
                    notify(`Тип активности успешно удален!`);
                    setFormData({ id: 0, name: '', industryId: 0 });
                    setErrors({});
                    setSelectedTypeId(null);
               } catch (error) {
                    console.error("Не удалось удалить тип:", error);
               }
          } else {
               console.error("Не выбран тип для удаления");
          }
     };

     return (
          <div className={classes.form__container}>
               <div className={classes.form__content}>
                    <div className={classes.form__box}>
                         <h1>Создание подотраслей</h1>
                         <div className={classes.controllers__container}>
                              <InputField
                                   as="select"
                                   value={formData.industryId || ''}
                                   onChange={(e) => handleInputChange(e, 'industryId')}
                                   error={errors.industryId}
                                   errorMessage="Это обязательное поле. Заполните его!"
                              >
                                   <option value="">Выберите отрасль</option>
                                   {industryStore.industries.map(industry => (
                                        <option key={industry.id} value={industry.id}>
                                             {industry.name}
                                        </option>
                                   ))}
                              </InputField>

                              <InputField
                                   as="input"
                                   placeholder="Название новой подотрасли"
                                   value={formData.name}
                                   onChange={(e) => handleInputChange(e, 'name')}
                                   error={errors.name}
                                   errorMessage="Это обязательное поле. Заполните его!"
                              />
                         </div>
                         <CustomButton onClick={handleSubmit} isCheeseButton>Создать тип активности</CustomButton>
                    </div>

                    <div className={classes.form__box}>
                         <h1>Удаление подотраслей</h1>
                         <div className={classes.controllers__container}>
                              <InputField
                                   as="select"
                                   value={selectedIndustryId || ''}
                                   onChange={(e) => setSelectedIndustryId(Number(e.target.value))}
                                   error={errors.industryId}
                                   errorMessage="Выберите отрасль для отображения подотрослей"
                              >
                              <option value="">Выберите отрасль</option>
                                   {industryStore.industries.map(industry => (
                                        <option key={industry.id} value={industry.id}>
                                             {industry.name}
                                        </option>
                                   ))}
                              </InputField>

                              <div className={classes.items__list}>
                                   {selectedIndustryId ? (
                                        industryStore.subIndustries[selectedIndustryId]?.length > 0 ? (
                                             industryStore.subIndustries[selectedIndustryId].map((type: ITypeOfActivity) => (
                                                  <div
                                                       key={type.id}
                                                       className={classes.list__item}
                                                       onClick={() => setSelectedTypeId(type.id)}
                                                       style={{
                                                            cursor: 'pointer',
                                                            backgroundColor: selectedTypeId === type.id ? '#f0f0f0' : 'transparent'
                                                       }}
                                                  >
                                                       {type.name}
                                                  </div>
                                             ))
                                        ) : (
                                             <p>Подотрасли не найдены для выбранной отрасли.</p>
                                        )
                                   ) : (
                                        <p>Пожалуйста, выберите отрасль для просмотра подотрослей.</p>
                                   )}
                              </div>
                         </div>
                         <p>В качестве удаляемого выбран: {selectedTypeId !== null ? industryStore.subIndustries[selectedIndustryId!]?.find(type => type.id === selectedTypeId)?.name : 'Ничего не выбрано'}</p>
                         <CustomButton onClick={handleDelete} isCheeseButton>Удалить тип подотрасли</CustomButton>
                    </div>
               </div>
          </div>
     );
};

export default TypeCreator;
