import React, { useState, ChangeEvent, DragEvent, useEffect } from 'react';
import classes from './MultiplyFileUploader.module.scss';
import addImage from './../../assets/icons/addImage.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { validateImage } from '../../utilits/validateImage';

interface MultiplyFileUploaderProps {
    onImagesChange: (images: File[]) => void;
    error?: boolean;
    errorMessage?: string;
    defaultFiles?: File[];
}

const MAX_IMAGES = 10;

const MultiplyFileUploader: React.FC<MultiplyFileUploaderProps> = ({ onImagesChange, error, errorMessage, defaultFiles }) => {
    const [selectedImages, setSelectedImages] = useState<File[]>([]);
    const [dragging, setDragging] = useState(false);
    const [localError, setLocalError] = useState<string | null>(null);

    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
          if (event.target.files) {
               try {
                    const files = Array.from(event.target.files);
                    if (selectedImages.length + files.length > MAX_IMAGES) {
                        setLocalError(`Вы можете загрузить максиму ${MAX_IMAGES} фотографий.`);
                        return;
                    }
                    const validFiles: File[] = [];
                    for (const file of files) {
                        await validateImage(file);
                        validFiles.push(file);
                    }
                    setSelectedImages(prevImages => {
                        const updatedImages = [...prevImages, ...validFiles];
                        onImagesChange(updatedImages);
                        return updatedImages;
                    });
                    setLocalError(null);
               } catch (e: any) {
                    setLocalError(e.message);
               }
          }
    };

    useEffect(() => {
        if (defaultFiles) {
            setSelectedImages(defaultFiles);
        }
    }, [defaultFiles]);

    const removeImage = (index: number) => {
        const updateImages = selectedImages.filter((_, i) => i !== index);
        setSelectedImages(updateImages);
        onImagesChange(updateImages);
    };

    const handleFilesAdded = async (files: File[]) => {
          try {
               if (selectedImages.length + files.length > MAX_IMAGES) {
                    setLocalError(`Вы можете загрузить максиму ${MAX_IMAGES} фотографий.`);
                    return;
               }
               const validFiles: File[] = [];
               for (const file of files) {
                    await validateImage(file);
                    validFiles.push(file);
               }
               setSelectedImages(prevImages => {
                    const updatedImages = [...prevImages, ...validFiles];
                    onImagesChange(updatedImages);
                    return updatedImages;
               });
               setLocalError(null);
          } catch (e: any) {
               setLocalError(e.message);
          }
    };

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragging(false);
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragging(false);
        if (event.dataTransfer.files) {
            const files = Array.from(event.dataTransfer.files);
            handleFilesAdded(files);
        }
    };

    const slidesPerView = Math.min(selectedImages.length, 4);

    return (
        <div
            className={`${classes.multipleFileUploader} ${dragging ? classes.dragging : ''} ${error || localError ? classes.error : ''}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            <input
                type="file"
                id="multipleFileUploader"
                onChange={handleFileChange}
                multiple
                className={classes.fileInput}
            />
            {(localError || error) && <p className={classes.errorMessage}>{localError || errorMessage}</p>}
            {selectedImages.length > 0 ? (
                <Swiper
                    slidesPerView={slidesPerView}
                    spaceBetween={10}
                >
                  
                    {selectedImages.map((file, index) => (
                        <SwiperSlide key={index} className={classes.swiper_slide}>
                            <div className={classes.swiperImage_container} onClick={() => removeImage(index)}>
                                <img src={URL.createObjectURL(file)} alt={`Выбран ${file.name}`} />
                            </div>
                        </SwiperSlide>
                    ))}
                    {selectedImages.length < MAX_IMAGES && (
                        <SwiperSlide className={classes.swiper_slide}>
                            <label htmlFor="multipleFileUploader">
                                <img src={addImage} alt="Добавить изображение" />
                            </label>
                        </SwiperSlide>
                    )}
                </Swiper>
            ) : (
                <label className={classes.dropZone} htmlFor="multipleFileUploader">
                    <p>Добавьте или переместите <span>свои файлы сюда</span></p>
                </label>
            )}
        </div>
    );
};

export default MultiplyFileUploader;