import React, { FC, useEffect, useRef, InputHTMLAttributes, TextareaHTMLAttributes, SelectHTMLAttributes } from 'react';
import classes from './InputField.module.scss';
import ExclamationPoint from './../../assets/icons/exclamation_point.svg';
import classNames from 'classnames';

type InputFieldProps = 
    | (InputHTMLAttributes<HTMLInputElement> & { as?: 'input' })
    | (TextareaHTMLAttributes<HTMLTextAreaElement> & { as: 'textarea' })
    | (SelectHTMLAttributes<HTMLSelectElement> & { as: 'select' });

interface CommonProps {
    error?: boolean;
    errorMessage?: string;
    maxLength?: number;
    infoType?: string;
    className?: string;
}

const InputField: FC<InputFieldProps & CommonProps> = ({
    as = 'input',
    error = false,
    errorMessage,
    maxLength,
    infoType,
    className,
    ...props
}) => {
    const [charCount, setCharCount] = React.useState<number>(0);
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    useEffect(() => {
        if (as === 'textarea' && textareaRef.current) {
            autoResize(textareaRef.current);
            setCharCount(textareaRef.current.value.length);
        }
    }, [props.value]);

    const autoResize = (textarea: HTMLTextAreaElement) => {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (!maxLength || value.length <= maxLength) {
            if (props.onChange) (props.onChange as React.ChangeEventHandler<HTMLInputElement>)(e);
        }
    };

    const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCharCount(e.target.value.length);
        if (props.onChange) (props.onChange as React.ChangeEventHandler<HTMLTextAreaElement>)(e);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === '-' || e.key === 'e' || e.key === 'E') {
            e.preventDefault();
        }
    };

    const renderInput = () => {
        switch (as) {
            case 'textarea':
                return (
                    <>
                        <textarea 
                            maxLength={maxLength} 
                            className={classes.input} 
                            ref={textareaRef} 
                            onChange={handleTextareaChange}
                            {...(props as TextareaHTMLAttributes<HTMLTextAreaElement>)} 
                        />
                        {maxLength && (
                            <div className={classes.charCount}>
                                {charCount}/{maxLength}
                            </div>
                        )}
                    </>
                );
                
            case 'select':
                return (
                    <div className={classes.selectWrapper}>
                        <select {...(props as SelectHTMLAttributes<HTMLSelectElement>)} className={classes.input} />
                        <div className={classes.customArrow} />
                    </div>
                );

            default:
                const inputProps = props as InputHTMLAttributes<HTMLInputElement>;
                return (
                    <div className={classes.inputWrapper}>
                        <input 
                            {...inputProps} 
                            className={classes.input} 
                            maxLength={maxLength} 
                            onKeyDown={inputProps.type === 'number' ? handleKeyDown : undefined} 
                            onChange={
                                inputProps.type === 'number'
                                ? handleNumberChange
                                : (props.onChange as React.ChangeEventHandler<HTMLInputElement>)
                            }
                        />
                        {infoType && <span className={classes.percentageSymbol}>{infoType}</span>}
                    </div>
                );
        }
    };

    return (
        <div className={classNames(
            classes.inputField, {[classes.error]: error}
        )}>
            {renderInput()}
            {error && (
                <>
                    <img className={classes.errorIcon} src={ExclamationPoint} alt="Ошибка" />
                    {errorMessage && <span className={classes.errorMessage}>{errorMessage}</span>}
                </>
            )}
        </div>
    );
};

export default InputField;
