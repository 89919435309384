import React from "react";
import AuthorizationInput from "../../UI/inputs/AuthorizationInput";
import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg';

interface ForgotPasswordProps {
     email: string;
     emailError?: boolean;
     
     blurHandle: (event: React.FocusEvent<HTMLInputElement>) => void;
     handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
     isSubmitClicked: boolean;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({
     email,
     emailError,
     blurHandle,
     handleChange,
     isSubmitClicked,
}) => {
     return (
          <>
               <AuthorizationInput
                    type="text"
                    placeholder="Почта"
                    name="recovery_email"
                    value={email}
                    onBlur={blurHandle}
                    onChange={handleChange}
                    icon={<EmailIcon />}
                    error={isSubmitClicked && emailError}
               />
          </>
     );
}

export default ForgotPassword;
