import React, { useState, useEffect, useContext } from "react";
import classes from './Header.module.scss';
import Logo from '../../assets/logo.svg';
import SearchInput from "../../UI/searches/SearchInput";
import { ADMIN_ROUTE, CONTACTS_ROUTE, HOME_ROUTE, NEWS_ROUTE } from "../../utilits/consts";
import NavigationButtons from "../../UI/buttons/NavigationButtons";
import ScrollToTopNavLink from "../../UI/ScrollToTopNavLink";
import { useLocation } from "react-router-dom";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";

const Header = () => {
    const { userStore } = useContext(Context);
    const location = useLocation();
    const [isVisible, setIsVisible] = useState(true);
    
    let lastScrollTop = 0;
    const handleScroll = () => {
        const scrollTop = document.documentElement.scrollTop;
        if (scrollTop > lastScrollTop) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
        lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if(userStore.isAuth) {
            userStore.checkAdmin();
        }
    }, [userStore.isAuth]);

    return (
        <header className={isVisible ? classes.visible : classes.hidden}>
            <div className={classes.header__body}>
                <div className={classes.left_container}>
                    <ScrollToTopNavLink to={HOME_ROUTE}>
                        <img className={classes.logo__image} src={Logo} alt="francheese logo" />
                    </ScrollToTopNavLink>
                    <ul className={classes.navigation}>
                        <ScrollToTopNavLink
                            to={HOME_ROUTE} 
                            className={`${classes.nav__item} ${location.pathname === HOME_ROUTE ? classes.active : ''}`}
                        >
                            Главная
                        </ScrollToTopNavLink>

                        <ScrollToTopNavLink 
                            to={NEWS_ROUTE} 
                            className={`${classes.nav__item} ${location.pathname === NEWS_ROUTE ? classes.active : ''}`}
                        >
                            Новости
                        </ScrollToTopNavLink>
                        
                        <ScrollToTopNavLink 
                            to={CONTACTS_ROUTE} 
                            className={`${classes.nav__item} ${location.pathname === CONTACTS_ROUTE ? classes.active : ''}`}
                        >
                            Контакты
                        </ScrollToTopNavLink>
                    </ul>
                </div>
                <div className={classes.right_container}>
                    <SearchInput isVisible={isVisible} />
                    <NavigationButtons />
                </div>
                {userStore.isAdmin && (
                    <ScrollToTopNavLink
                        to={ADMIN_ROUTE} 
                        className={`${classes.adminPage__link} ${location.pathname === ADMIN_ROUTE ? classes.active : ''}`}
                    >
                        Админ
                    </ScrollToTopNavLink>
                )}
            </div>
        </header>
    );
};

export default observer(Header);