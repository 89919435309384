import React from "react";
import classes from './Footer.module.scss';
import TwitterIcon from '../../assets/icons/twitter.svg';
import LinkedinIcon from '../../assets/icons/linkedin.svg';
import FacebookIcon from '../../assets/icons/facebook.svg'
import InstagramIcon from '../../assets/icons/instagram.svg'

import SubscribeInput from "../../UI/inputs/SubscribeInput";
import { CONTACTS_ROUTE, FAVORITES_ROUTE, FRANCHISE_CATALOG_ROUTE, HOME_ROUTE, NEWS_ROUTE, PROFILE_ROUTE } from "../../utilits/consts";
import ScrollToTopNavLink from "../../UI/ScrollToTopNavLink";

const Footer: React.FC = () => {
    return (
        <footer>
            <div className={classes.footer__container}>
                <div className={classes.footer__content}>
                    <div className={classes.footer__body}>
                        <div className={classes.footer__column}>
                            <h1>Francheese</h1>
                            <p>
                                Ваш путеводитель в мире франшиз. Следите за нами в социальных сетях и оставайтесь на связи для актуальной информации, обновлений и поддержки вашего бизнеса.
                            </p>
                            <div className={classes.SocialMedia}>
                                {/* <a href="https://x.com">
                                    <img src={TwitterIcon} alt="Twiter" />
                                </a> */}
                                <a href="https://ru.linkedin.com">
                                    <img src={LinkedinIcon} alt="Linkedin" />
                                </a>
                                {/* <a href="https://www.facebook.com">
                                    <img src={FacebookIcon} alt="Facebook" />
                                </a> */}
                                <a href="https://www.instagram.com">
                                    <img src={InstagramIcon} alt="Facebook" />
                                </a>
                            </div>
                        </div>
                        <div className={classes.footer__column}>
                            <h1>Мой аккаунт</h1>
                            <ul className={classes.footer__navigation}>
                                <div className={classes.buttons__box}>
                                    <ScrollToTopNavLink to={HOME_ROUTE}>
                                        Главная
                                    </ScrollToTopNavLink>
                                </div>
                                <div className={classes.buttons__box}>
                                    <ScrollToTopNavLink to={PROFILE_ROUTE}>
                                        Профиль
                                    </ScrollToTopNavLink>
                                </div>
                                <div className={classes.buttons__box}>
                                    <ScrollToTopNavLink to={FAVORITES_ROUTE}>
                                        Избранное
                                    </ScrollToTopNavLink>
                                </div>
                                <div className={classes.buttons__box}>
                                    <ScrollToTopNavLink to={NEWS_ROUTE}>
                                        Новости
                                    </ScrollToTopNavLink>
                                </div>
                                <div className={classes.buttons__box}>
                                    <ScrollToTopNavLink to={CONTACTS_ROUTE}>
                                        Контакты
                                    </ScrollToTopNavLink>
                                </div>
                                <div className={classes.buttons__box}>
                                    <ScrollToTopNavLink to={FRANCHISE_CATALOG_ROUTE}>
                                        Каталог
                                    </ScrollToTopNavLink>
                                </div>
                            </ul>
                        </div>
                        <div className={classes.footer__column}>
                            <h1>Оставайтесь в курсе событий</h1>
                            <p>
                                Оставайтесь в курсе последних событий и новостей. Подпишитесь на нашу рассылку, чтобы получать самую свежую информацию на свою почту.
                            </p>
                            <SubscribeInput type="text" placeholder="Введите ваш email"/>
                        </div>
                    </div>
                    <p className={classes.Copyright}>Copyright © 2024 aucuo</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;