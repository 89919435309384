import React, { useState, useContext, useEffect } from "react";
import classes from '../styles/News.module.scss';
import NewsObject from '../UI/news/NewsObject';
import { Context } from "../index";
import Pagination from "../UI/news/Pagination";
import { observer } from "mobx-react-lite";
import SkeletonNewsObject from "../UI/skeletons/SkeletonNewsObject";

const News: React.FC = () => {
     const [currentPage, setCurrentPage] = useState<number>(1);
     const itemsPerPage: number = 3;
 
     const { newsStore } = useContext(Context);
 
     useEffect(() => {
          newsStore.loadNews();
     }, [newsStore]);
     
     const totalPages: number = Math.ceil(newsStore.News.length / itemsPerPage);
     const startIndex: number = (currentPage - 1) * itemsPerPage;
     const endIndex: number = startIndex + itemsPerPage;
     const currentItems = newsStore.News.slice(startIndex, endIndex);
 
     const handlePageChange = (page: number) => {
         setCurrentPage(page);
     };

     return (
          <div className={classes.news__container}>
               <div className={classes.news__content}>
                    <h1>Новости</h1>
                    <div className={classes.news__items}>
                         {currentItems.length === 0 ? (
                              Array.from({ length: 3 }).map((_, index) => (
                                   <SkeletonNewsObject index={index} key={index}/>
                              ))
                         ) : (
                              currentItems.map((news, index) => (
                                   <NewsObject
                                        key={news.id}
                                        image={news.img}
                                        newsName={news.title}
                                        date={news.createdAt!}
                                        newsContent={news.details}
                                        index={index}
                                        id={news.id}
                                   />
                              ))
                         )}
                    </div>
                    <Pagination
                         currentPage={currentPage}
                         totalPages={totalPages}
                         onPageChange={handlePageChange}
                    />
               </div>
          </div>
     );
};

export default observer(News);
