import React from 'react';
import classes from './Notification.module.scss';
import { INotification } from '../../models/INotification';

interface NotificationProps {
    notifications: INotification[];
    loadingNotification: string | null;
}

const Notification: React.FC<NotificationProps> = ({ notifications, loadingNotification }) => {
    return (
        <div className={classes.notification__container}>
            {notifications.map((notification, index) => (
                <div key={index} className={classes.notification}>
                    {notification.message}
                </div>
            ))}
            {loadingNotification && (
                <div className={`${classes.notification} ${classes.loading}`}>
                    <div className={classes.spinner}></div>
                    {loadingNotification}
                </div>
            )}
        </div>
    );
};

export default Notification;