import React from "react";
import AuthorizationInput from "../../UI/inputs/AuthorizationInput";
import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg';
import LabeledCheckbox from "../../UI/inputs/LabledCheckbox";

interface RegistrThirdStepProps {
    storedEmail: string;
    activationCode: string;
    activationCodeError?: boolean; 
    consent: boolean;
    blurHandle: (event: React.FocusEvent<HTMLInputElement>) => void;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleCheckboxChange: () => void;
    isSubmitClicked: boolean;
}
const RegistrThirdStep: React.FC<RegistrThirdStepProps> = ({
    storedEmail,
    activationCode,
    activationCodeError,
    consent,
    handleChange,
    handleCheckboxChange,
    blurHandle,
    isSubmitClicked,
}) => {
    const userEmailStyle: React.CSSProperties = {
        width: 'fit-content',
        borderRadius: '60px',
        background: '#565656',
        color: '#FFFFFF',
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: '100%',
        letterSpacing: '0.04em',
        padding: '0.75rem 1rem',
    };

    return (
        <>
            <span style={userEmailStyle}>{storedEmail}</span>
            <AuthorizationInput
                type="text"
                placeholder="Код из письма"
                name="activationCode"
                value={activationCode}
                onBlur={blurHandle}
                onChange={handleChange}
                error={isSubmitClicked && activationCodeError}
                icon={<EmailIcon/>}
            />
            <LabeledCheckbox 
                text="Я согласен на обработку моих персональных данных" 
                checked={consent}
                onChange={handleCheckboxChange}
            />
        </>
    );
}

export default RegistrThirdStep;
