import React, { useState, useContext } from 'react';
import classes from './../../styles/CreationForms.module.scss';
import InputField from '../../UI/inputs/InputField';
import FileUploader from '../../UI/fileUploaders/FileUploader';
import { Context } from '../..';
import CustomButton from '../../UI/buttons/CustomButton';
import { useNotification } from '../../hooks/useNotification';
import { INews } from '../../models/INews';
import DraftEditor from '../../UI/inputs/DraftEditor';
import { EditorState, convertToRaw } from 'draft-js';

const NewsCreator: React.FC = () => {
    const { newsStore } = useContext(Context);
    const [frontImage, setFrontImage] = useState<File | null>(null);
    const { notify, showLoading, hideLoading } = useNotification();

    const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
    const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
    const [formData, setFormData] = useState<{ [key: string]: string }>({
        id: '',
        title: '',
        img: '',
        details: '',
        content: '',
    });

    const handleFrontImageChange = (newImage: File) => {
        setFrontImage(newImage);
    };

    const handleEditorContentChange = (content: string) => {
        setFormData({
            ...formData,
            content: content
        });
        setErrors({ ...errors, content: false });
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, field: keyof INews) => {
        const value = e.target.value;
        setFormData({
            ...formData,
            [field]: value
        });

        setErrors({ ...errors, [field]: false });
    };

    const validateForm = (): boolean => {
        const newErrors: { [key: string]: boolean } = {};

        if (!formData.title) {
            newErrors.title = true;
        }
        if (!formData.details) {
            newErrors.details = true;
        }
        if (!frontImage) {
            newErrors.img = true;
        }
        if (!editorState.getCurrentContent().hasText()) {
            newErrors.content = true;
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validateForm()) {
            return;
        }

        try {
            showLoading("Создаем новость");

            const contentState = editorState.getCurrentContent();
            const contentJSON = JSON.stringify(convertToRaw(contentState));

            await newsStore.createNews(
                formData.title,
                frontImage!,
                formData.details.replace(/\n/g, '<br />'),
                contentJSON
            );

            hideLoading();
            notify(`Новость "${formData.title}" успешно создана!`);
        } catch (error) {
            console.error("Не удалось создать новость:", error);
            notify(`Не удалось создать новость: ${error}`);
        }
    };

    const handleDelete = async () => {
        try {
            showLoading("Удаляем новость");
            await newsStore.deleteOneNews(Number(formData.id));
            hideLoading();
            notify(`Новость успешно удалена!`);
        } catch (error) {
            console.error("Не удалось удалить новость ", error);
            notify(`Не удалось удалить новость: ${error}`);
        }
    };

    return (
        <div className={classes.form__container}>
            <div className={classes.form__content}>
                <div className={classes.form__box}>
                    <h1>Создание Новости</h1>
                    <div className={classes.controllers__container}>
                        <FileUploader
                            width='270px'
                            height='270px'
                            onImageChange={handleFrontImageChange}
                            error={errors.img}
                            errorMessage="Это обязательное поле. Заполните его!"
                        />
                        <div className={classes.controllers__box}>
                            <InputField
                                as="input"
                                type="text"
                                placeholder='Заголовок'
                                value={formData.title}
                                onChange={(e) => handleInputChange(e, 'title')}
                                error={errors.title}
                                errorMessage="Это обязательное поле. Заполните его!"
                            />
                            <InputField
                                as='textarea'
                                maxLength={512}
                                placeholder='Краткое описание'
                                value={formData.details}
                                onChange={(e) => handleInputChange(e, 'details')}
                                error={errors.details}
                                errorMessage="Это обязательное поле. Заполните его!"
                            />
                        </div>
                    </div>
                    <h2>Описание *</h2>
                    <DraftEditor
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        onContentChange={handleEditorContentChange} 
                        error={errors.content}
                        errorMessage="Это обязательное поле. Заполните его!"
                        maxLength={1024}
                    />
                    <CustomButton onClick={handleSubmit} isCheeseButton>Создать новость</CustomButton>
                </div>

                <div className={classes.form__box}>
                    <h1>Удаление новости</h1>
                    <div className={classes.controllers__container}>
                        <InputField
                            as='select'
                            value={formData.id || ''}
                            onChange={(e) => handleInputChange(e, 'id')}
                            error={errors.id}
                            errorMessage="Это обязательное поле. Заполните его!"
                        >
                            <option value="">Выберите новость, которую вы хотите удалить</option>
                            {newsStore.News.map((news: INews) => (
                                <option key={news.id} value={news.id}>{news.title}</option>
                            ))}
                        </InputField>
                    </div>
                    <CustomButton onClick={handleDelete} isCheeseButton>Удалить новость</CustomButton>
                </div>
            </div>
        </div>
    );
};

export default NewsCreator;