import React from "react";
import classes from './FranchiseCard.module.scss';
import EditorsChoiceImg from '../../assets/editors_choice.svg';
import { IFranchise } from "../../models/IFranchise";
import { STATIC_URL } from "../../http";
import { formatPrice } from "../../utilits/utilities";
import ScrollToTopNavLink from "../ScrollToTopNavLink";
import { FRANCHISE_PAGE_ROUTE } from "../../utilits/consts";
import defaultUserImage from '../../assets/defaultUser.png'

interface FranchiseCardProps {
    franchise?: IFranchise,
    isEditorsChoice?: boolean,
    displayName?: boolean,
    displayOwner?: boolean,
    isActive?: boolean
}

const FranchiseCard: React.FC<FranchiseCardProps> = ({
    franchise,
    isEditorsChoice = false,
    displayName = true,
    displayOwner = true,
    isActive = false
}) => {
    const ownerInfo = franchise?.ownerInfo;
    const ownerImage = ownerInfo ? `${STATIC_URL}/users/${ownerInfo.img}` : defaultUserImage;

    const cardContent = (
        <div className={classes.franchise__card}>
            <img className={classes.franchise__faceImage} src={`${STATIC_URL}/franchises/${franchise?.faceImage}`} alt={franchise?.name} />
            
            {isEditorsChoice && (
                <div className={classes.editorsChoice}>
                    <img src={EditorsChoiceImg} alt="Выбор редакции" />
                </div>
            )}
            
            {ownerInfo && (
                <div className={classes.franchise__userProfile}>
                    {displayName && (
                        <h1>{franchise?.name}</h1>
                    )}
                    {displayOwner && (
                        <div className={classes.franchise__author}>
                            <div className={classes.franchise__authorImage}>
                                <img src={ownerImage} alt={ownerInfo.firstName} />
                            </div>
                            <h2>{ownerInfo.firstName} {ownerInfo.lastName}</h2>
                        </div>
                    )}
                </div>
            )}

            <div className={classes.franchise__performance}>
                <div className={classes.franchise__investments}>
                    <p>инвестиции</p>
                    <span>от {formatPrice(franchise?.investments)} BYN</span>
                </div>

                <div className={classes.franchise__paybackPeriod}>
                    <p>срок окупаемости</p>
                    <span>от {franchise?.paybackTime} месяцев</span>
                </div>
            </div>
        </div>
    );

    return isActive ? (
        <ScrollToTopNavLink 
            to={`${FRANCHISE_PAGE_ROUTE}/${franchise?.id}`}
            className={classes.franchise__card}
        >
            {cardContent}
        </ScrollToTopNavLink>
    ) : (
        cardContent
    );
}

export default FranchiseCard;