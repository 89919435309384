import axios from 'axios';
import { AuthResponse } from '../models/response/AuthResponse';

//export const API_URL = "http://localhost:5000/api"
//export const STATIC_URL = "http://localhost:5000"

export const API_URL = "https://api.francheese.by"
export const STATIC_URL = "https://api.francheese.by"

const $api = axios.create({
      withCredentials: true,
      baseURL: API_URL
})
  
$api.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`
      return config;
})

$api.interceptors.response.use((config) => {
      return config;
}, async (error) => {
      const originalRequest = error.config;
      if (error.response.status == 401 && error.config && !error.config._isRetry) {
            originalRequest._isRetry = true;
            try {
                  const response = await axios.get<AuthResponse>(`${API_URL}/users/refresh`, {withCredentials: true})
                  localStorage.setItem('accessToken', response.data.accessToken);
                  console.log("Access токен восстановлен")
                  return $api.request(originalRequest);
            } catch (e) {
                  console.log('Пользователь не авторизован')
            }
      }
      throw error;
})

export default $api;