import React, { ChangeEvent } from "react";
import classes from "./QuestionnaireInput.module.scss";

interface QuestionnaireInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    headerName: string;
    headerInfo?: string;
    isRequired?: boolean;
    isYesNo?: boolean;
    error?: string;
    value: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const QuestionnaireInput: React.FC<QuestionnaireInputProps> = ({
    headerName,
    headerInfo,
    isRequired,
    isYesNo = false,
    error,
    value,
    onChange,
    ...props
}) => {
    return (
        <div className={classes.questionnaireInput__container}>
            <div className={classes.questionnaireInput__header}>
                {headerName}
                {isRequired && 
                (
                    <span className={` ${error ? classes.errorMarker : classes.requiredMarker}`}>*</span>
                )}
                {headerInfo && (
                    <p dangerouslySetInnerHTML={{ __html: headerInfo }} />
                )}
            </div>

            {isYesNo ? (
                <div className={classes.yesNoWrapper}>
                    <label>
                        <input
                            type="radio"
                            name={props.name}
                            value="Да"
                            checked={value === "Да"}
                            onChange={onChange}
                            {...props}
                        />
                        Да
                    </label>
                    <label>
                        <input
                            type="radio"
                            name={props.name}
                            value="Нет"
                            checked={value === "Нет"}
                            onChange={onChange}
                            {...props}
                        />
                        Нет
                    </label>
                </div>
            ) : (
                <div className={classes.inputWrapper}>
                    <input
                        {...props}
                        type={"text"}
                        value={value}
                        onChange={onChange}
                        placeholder="Мой ответ"
                    />
                </div>
            )}

            {error && <p className={classes.errorMessage}>{error}</p>}
        </div>
    );
}

export default QuestionnaireInput;