import React, { useState, useEffect, ChangeEvent } from 'react';
import classes from './AuthorizationImageUploader.module.scss';
import Hint from '../Hint';
import { validateImage } from '../../utilits/validateImage';
import DefaultImage from "../../assets/defaultUser.png";

interface AuthorizationImageUploaderProps {
    profileImage?: File | null;
    onImageChange: (image: File) => void;
    profileImageError?: boolean;
}

const AuthorizationImageUploader: React.FC<AuthorizationImageUploaderProps> = ({
    profileImage,
    onImageChange,
    profileImageError,
}) => {
    const [imagePreview, setImagePreview] = useState<string>(DefaultImage);
    const [errorMessage, setErrorMessage] = useState<string>("");

    useEffect(() => {
        if (profileImage) {
            setImagePreview(URL.createObjectURL(profileImage));
        } else {
            setImagePreview(DefaultImage);
        }
    }, [profileImage]);

    const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const selectedImage = files[0];
            try {
                await validateImage(selectedImage, 100, 100, 2000, 2000);
                onImageChange(selectedImage);
                setImagePreview(URL.createObjectURL(selectedImage));
                setErrorMessage('');
            } catch (error) {
                const errorMsg = (error as Error).message;
                setErrorMessage(errorMsg);
                setImagePreview(DefaultImage);
            }
        } else {
            setErrorMessage('Изображение не выбрано. Пожалуйста, выберите изображение.');
            setImagePreview(DefaultImage);
        }
    };

    useEffect(() => {
        return () => {
            if (imagePreview !== DefaultImage) {
                URL.revokeObjectURL(imagePreview);
            }
        };
    }, [imagePreview]);

    return (
        <div className={classes.imageUpload__container}>
            <div className={`${classes.imageUpload__wrapper} ${profileImageError ? classes.error : ''}`}>
                {errorMessage !== "" && (
                    <Hint message={errorMessage} hints={[]} isErrored />
                )}
                <label htmlFor="file_input">
                    <div className={classes.image_preview}>
                        <img src={imagePreview} alt="Image Preview" className={classes.image} />
                    </div>
                </label>
                <input
                    type="file"
                    className={classes.input}
                    id="file_input"
                    accept="image/*"
                    onChange={handleImageChange}
                />
            </div>
        </div>
    );
};

export default AuthorizationImageUploader;