import React, { useState, useContext } from 'react';
import classes from './../../styles/CreationForms.module.scss';
import InputField from '../../UI/inputs/InputField';
import { IIndustry } from '../../models/IIndustry';
import { Context } from '../..';
import CustomButton from '../../UI/buttons/CustomButton';
import { useNotification } from '../../hooks/useNotification';

const IndustryCreator: React.FC = () => {
    const { industryStore } = useContext(Context);

    const { notify, showLoading, hideLoading } = useNotification();
    const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
    const [formData, setFormData] = useState<IIndustry>({
        id: 0,
        name: '',
    });

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
        field: keyof IIndustry
    ) => {
        const value = e.target.value;
        setFormData({
            ...formData,
            [field]: value,
        });

        setErrors({ ...errors, [field]: false });
    };

    const handleSubmit = async () => {
        try {
            if (!formData.name) {
               setErrors({ ...errors, name: true });
               return;
            }
            showLoading("Создание новой индустрии")
            await industryStore.createIndustry(formData);
            hideLoading();
            notify(`Индустрия успешно создана!`);
            setFormData({ id: 0, name: '' });
            setErrors({});
        } catch (error) {
            console.error("Не удалось создать индустрию:", error);
        }
    };

    const handleDelete = async () => {
        try {
            if (formData.id === 0) {
                setErrors({ ...errors, id: true });
                return;
            }
            showLoading("Удаление индустрии")
            await industryStore.deleteIndustry(formData.id);
            hideLoading();
            notify(`Индустрия успешно удалена!`);
            setFormData({ id: 0, name: '' });
            setErrors({});
        } catch (error) {
            console.error("Не удалось удалить индустрию:", error);
        }
    };

    return (
        <div className={classes.form__container}>
            <div className={classes.form__content}>
                <div className={classes.form__box}>
                    <h1>Создание отрасли</h1>
                    <div className={classes.controllers__container}>
                        <InputField
                            as="input"
                            type="text"
                            placeholder="Название новой отрасли"
                            value={formData.name}
                            onChange={(e) => handleInputChange(e, 'name')}
                            error={errors.name}
                            errorMessage="Это обязательное поле. Заполните его!"
                        />
                    </div>
                    <CustomButton onClick={handleSubmit} isCheeseButton>Добавить отрасль</CustomButton>
                </div>
                <div className={classes.form__box}>
                    <h1>Удаление отрасли</h1>
                    <div className={classes.controllers__container}>
                        <InputField
                            as="select"
                            value={formData.id || ''}
                            onChange={(e) => handleInputChange(e, 'id')}
                            error={errors.id}
                            errorMessage="Это обязательное поле. Заполните его!"
                        >
                            <option value="">Выберите индустрию для удаления</option>
                            {industryStore.industries.map((industry: IIndustry) => (
                                <option key={industry.id} value={industry.id}>
                                    {industry.name}
                                </option>
                            ))}
                        </InputField>
                    </div>
                    <span className={classes.alert__text}>*Внимание при уадлении индустрии будут удалены и все сопутствующие ей подотрасли</span>
                    <CustomButton onClick={handleDelete} isCheeseButton>Удалить отрасль</CustomButton>
                </div>
            </div>
        </div>
    );
};

export default IndustryCreator;