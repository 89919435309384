export async function fetchFile(url: string, fileName: string): Promise<File> {
      try {
          const response = await fetch(url);
          if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
          }
          const blob = await response.blob();
          return new File([blob], fileName, { type: blob.type });
      } catch (error) {
          console.error(`Failed to fetch file from ${url}:`, error);
          throw error;
      }
  }