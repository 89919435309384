import React from "react";
import WelcomeSection from "../components/HomePageModules/welcomeSection/WelcomeSection";
import FindYourFranchise from "../components/HomePageModules/findYourFranchise/FindYourFranchise";
import BuyAndSell from "../components/HomePageModules/buyAndSell/BuyAndSell";
import FranchisesCatalog from "./FranchisesCatalog";
import RecommendedFranchises from "../components/HomePageModules/recommendedFranchises/RecommendedFranchises";

const Home: React.FC = () => {
    return (
        <>
            <WelcomeSection />
            <FindYourFranchise />
            <RecommendedFranchises />
            <FranchisesCatalog />
            <BuyAndSell />
        </>
    );
};

export default Home;
