import $api from "../http/index";
import { IFranchise } from "../models/IFranchise";
import { IRelease } from "../models/IRelease";

export default class ReleaseService {
     static async fetchReleases(): Promise<IRelease[]> {
               try {
                    const { data } = await $api.get('releases/get-all');
                    return data;
               } catch (error) {
                    throw error;
               }
     }

     static async fetchSpecialFranchise(): Promise<IFranchise> {
               try {
                    const { data } = await $api.get('releases/get-special-franchise');
                    return data;
               } catch (error) {
                    throw error;
               }
     }

     static async fetchThreeInteractiveCards(): Promise<IFranchise[]> {
               try {
                    const { data } = await $api.get('releases/get-interactive-cards');
                    return data;
               } catch (error) {
                    throw error;
               }
     }

     static async fetchAllInteractiveCards(): Promise<IFranchise[]> {
          try {
               const { data } = await $api.get('releases/get-all-interactive-cards');
               return data;
          } catch (error) {
               throw error;
          }
}

     static async addInteractiveCard(releaseId: number): Promise<IFranchise> {
          try {
               const { data } = await $api.post('/releases/add-interactive-card', { releaseId });
               return data;
          } catch (error) {
               throw error;
          }
     }

     static async removeInteractiveCard(id: number) {
          try {
               await $api.delete(`/releases/remove-interactive-card/${id}`);
          } catch (error) {
               throw error;
          }
     }

     static async addNewRelease(franchiseId: number): Promise<IRelease> {
          try {
               const { data } = await $api.post('/releases/create', { franchiseId })
               return data; 
          } catch (error) {
               throw error;
          }
     }

     static async removeRelease(id: number) {
          try {
               await $api.delete(`/releases/delete/${id}`);
          } catch (error) {
               throw error;
          }
     }

     static async setSpecialFranchise(releaseId: number): Promise<IFranchise> {
          try {
               const { data } = await $api.post('/releases/set-special-franchise', { releaseId });
               return data;
          } catch (error) {
               throw error;
          }
     }
}
