import React, { useContext, useState } from "react";
import classes from '../styles/Questionnaire.module.scss';
import QuestionnaireInput from "../UI/inputs/QuestionnaireInput";
import CustomButton from "../UI/buttons/CustomButton";
import { Context } from "../index";
import { useNotification } from "../hooks/useNotification";
import LabeledCheckbox from "../UI/inputs/LabledCheckbox";
import MailService from "../service/MailService";

interface FormData {
    fullName: string;
    phone: string;
    email: string;
    city: string;
    franchiseInterest: string;
    investmentAmount: string;
    businessExperience: string;
    leadershipExperience: string;
    franchiseExperience: string;
    hasPremises: string;
    notes: string;
    consent: boolean;
}

interface FormErrors {
    [key: string]: string;
}

const initialFormState: FormData = {
    fullName: "",
    phone: "",
    email: "",
    city: "",
    franchiseInterest: "",
    investmentAmount: "",
    businessExperience: "",
    leadershipExperience: "",
    franchiseExperience: "",
    hasPremises: "",
    notes: "",
    consent: false
};

const Questionnaire: React.FC = () => {
     const [formData, setFormData] = useState<FormData>(initialFormState);
     const [errors, setErrors] = useState<FormErrors>({});
     const { notify, showLoading, hideLoading } = useNotification();

     const validate = (): FormErrors => {
          const errors: FormErrors = {};
          const requiredMessage = "Это поле обязательно для заполнения";
          const selectMessage = "Выберите один из вариантов";
      
          const fieldsToValidate = [
              { field: 'fullName', message: requiredMessage },
              { field: 'phone', message: requiredMessage },
              { field: 'email', message: requiredMessage },
              { field: 'city', message: requiredMessage },
              { field: 'franchiseInterest', message: requiredMessage },
              { field: 'investmentAmount', message: requiredMessage },
          ];

          fieldsToValidate.forEach(({ field, message }) => {
               const value = formData[field as keyof typeof formData];
               
               if (typeof value === 'string' && !value.trim()) {
                    errors[field as keyof FormErrors] = message;
               }
          });
      
          if (!formData.businessExperience) errors.businessExperience = selectMessage;
          if (!formData.leadershipExperience) errors.leadershipExperience = selectMessage;
          if (!formData.franchiseExperience) errors.franchiseExperience = selectMessage;
          if (!formData.hasPremises) errors.hasPremises = selectMessage;
      
          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (formData.email && !emailPattern.test(formData.email)) {
              errors.email = "Введите корректный адрес электронной почты";
          }
      
          const phonePattern = /^\+?[0-9]{10,15}$/;
          if (formData.phone && !phonePattern.test(formData.phone)) {
              errors.phone = "Введите корректный номер телефона";
          }

          if (!formData.consent) {
               notify("Необходимо согласится на обработку данных");
               errors.consent = "Вы должны согласиться на обработку данных.";
          }
      
          return errors;
     };  
 
     const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const { name, value } = e.target;
          setFormData({
               ...formData,
               [name]: value,
          });
     };

     const handleCheckboxChange = () => {
          setFormData({
               ...formData,
               consent: !formData.consent, 
          });
     };

     const handleSubmit = async (e: React.FormEvent) => {
          e.preventDefault();
          const formErrors = validate();
          if (Object.keys(formErrors).length > 0) {
               setErrors(formErrors);
               return;
          } 
          
          setErrors({});
          try {
               showLoading("Отправляем результаты анкеты")
               await MailService.sendQuestionnaireResult(formData)
               hideLoading();
               notify("Анкета отправлена. Спасибо за ваше участие!")
               setFormData(initialFormState);
          } catch (error) {
               console.error("Ошибка при отправке анкеты:", error);
          }
     };

     const handleReset = () => {
          setFormData(initialFormState);
          setErrors({});
     };

     return (
          <div className={classes.questionnaire__container}>
               <div className={classes.questionnaire__content}>
                    <h1>Анкета-заявка потенциального франчайзера</h1>
                    <div className={classes.questionnaire__items}>
                         <QuestionnaireInput 
                              headerName="ФИО"
                              isRequired
                              name="fullName"
                              value={formData.fullName}
                              onChange={handleInputChange}
                              error={errors.fullName}
                         />
                         
                         <QuestionnaireInput 
                              headerName="Телефон"
                              isRequired
                              name="phone"
                              value={formData.phone}
                              onChange={handleInputChange}
                              error={errors.phone}
                         />
                         
                         <QuestionnaireInput 
                              headerName="E-mail"
                              isRequired
                              name="email"
                              value={formData.email}
                              onChange={handleInputChange}
                              error={errors.email}
                         />
                         
                         <QuestionnaireInput 
                              headerName="Город, регион для открытия точки"
                              isRequired
                              name="city"
                              value={formData.city}
                              onChange={handleInputChange}
                              error={errors.city}
                         />
                         
                         <QuestionnaireInput 
                              headerName="Франшиза в какой сфере Вас интересует?"
                              isRequired
                              name="franchiseInterest"
                              value={formData.franchiseInterest}
                              onChange={handleInputChange}
                              error={errors.franchiseInterest}
                         />
                         
                         <QuestionnaireInput 
                              headerName="Располагаемая сумма инвестиций для организации и ведения бизнеса"
                              isRequired
                              name="investmentAmount"
                              value={formData.investmentAmount}
                              onChange={handleInputChange}
                              error={errors.investmentAmount}
                         />
                         
                         <QuestionnaireInput 
                              headerName="Есть ли у Вас опыт самостоятельного ведения бизнеса?"
                              headerInfo="(для физического лица)"
                              isRequired
                              isYesNo
                              name="businessExperience"
                              value={formData.businessExperience}
                              onChange={handleInputChange}
                              error={errors.businessExperience}
                         />
                         
                         <QuestionnaireInput 
                              headerName="Есть ли опыт в руководстве персоналом?"
                              headerInfo="(для физического лица)"
                              isYesNo
                              name="leadershipExperience"
                              value={formData.leadershipExperience}
                              onChange={handleInputChange}
                              error={errors.leadershipExperience}
                         />
                         
                         <QuestionnaireInput 
                              headerName="Есть ли у Вас опыт работы в сфере интересуемой Вас франшизы?"
                              headerInfo="Например, если интересует франшиза в сфере общественного питания, есть ли опыт работы в этой сфере."
                              isRequired
                              isYesNo
                              name="franchiseExperience"
                              value={formData.franchiseExperience}
                              onChange={handleInputChange}
                              error={errors.franchiseExperience}
                         />
                         
                         <QuestionnaireInput 
                              headerName="Имеется ли у Вас помещение для открытия торговой точки?"
                              headerInfo="Если да, то в примечании ниже укажите его характеристики и месторасположение.<br/>
                              Если нет, то в примечании укажите, нужна ли Вам помощь в подборе помещения."
                              isRequired
                              isYesNo
                              name="hasPremises"
                              value={formData.hasPremises}
                              onChange={handleInputChange}
                              error={errors.hasPremises}
                         />
                         
                         <QuestionnaireInput 
                              headerName="Ваши примечания"
                              name="notes"
                              value={formData.notes}
                              onChange={handleInputChange}
                         />
                         
                         <LabeledCheckbox text="Я согласен на обработку моих данных" checked={formData.consent} onChange={handleCheckboxChange} />
                    </div>
                    <div className={classes.questionnaire__buttons}>
                         <CustomButton onClick={handleReset} bodyType="empty">Очистить форму</CustomButton>
                         <CustomButton onClick={handleSubmit}>Отправить</CustomButton>
                    </div>
               </div>
          </div>
     )
}

export default Questionnaire;