import React, { useEffect, useState } from 'react';
import classes from './QuestionnaireNotification.module.scss';
import QuestionnaireButton from '../buttons/QuestionnaireButton';
import classNames from 'classnames';

const QuestionnaireNotification: React.FC = () => {
    const [showNotification, setShowNotification] = useState(false);
    const [isVisible, setIsVisible] = useState(false); 

      useEffect(() => {
            const firstTimeout = setTimeout(() => {
                  setShowNotification(true);
                  setIsVisible(true);
            }, 30000);

            const secondTimeout = setTimeout(() => {
                  setShowNotification(true);
                  setIsVisible(true); 
            }, 300000);

            return () => {
                  clearTimeout(firstTimeout);
                  clearTimeout(secondTimeout);
            };
      }, []);

      const handleClose = () => {
            setIsVisible(false);
            setTimeout(() => {
                  setShowNotification(false);
            }, 600);
      }

      if (!showNotification) {
            return null;
      }

      return (
            <div className={classNames(classes.questionnaireNotification, 
                  { 
                        [classes.visible]: isVisible,
                        [classes.invisible]: !isVisible
                  })} 
                  onClick={handleClose}
            >
                  <button className={classes.closeNotification} onClick={handleClose}>×</button>
                  
                  <h1>
                        Ищете <span>подходящую</span> <br />
                        франшизу?
                  </h1>

                  <p>Позвольте нам помочь! Пройдите анкету, и мы предложим вам франшизы, которые максимально соответствуют вашим запросам и возможностям.</p>
                  <QuestionnaireButton onClick={handleClose}/>
            </div>
      );
};

export default QuestionnaireNotification;
