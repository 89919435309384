import React, { useState, useContext, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Context } from "../index";
import { observer } from "mobx-react-lite";
import classes from '../styles/Auth.module.scss';
import { FORGOT_PASSWORD_ROUTE, HOME_ROUTE, LOGIN_ROUTE, REGISTRATION_ROUTE } from "../utilits/consts";
import ErrorDetector from "../UI/ErrorDetector";
import Agreement from "../UI/Agreement";
import LoginForm from "../components/AuthorizationModulse/LoginForm";
import CustomButton from "../UI/buttons/CustomButton";
import RegistrFirstStep from "../components/AuthorizationModulse/RegistrFirstStep";
import ScrollToTopNavLink from "../UI/ScrollToTopNavLink";
import RegistrSecondStep from "../components/AuthorizationModulse/RegistrSecondStep";
import RegistrThirdStep from "../components/AuthorizationModulse/RegistrThirdStep";
import ForgotPassword from "../components/AuthorizationModulse/ForgotPassword";
import ComeBack from "../UI/buttons/ComeBack";
import AuthCheeseImg from '../assets/authCheese.svg';
import { useNotification } from "../hooks/useNotification";

interface FormState {
    identifier: string;
    logging_password: string;
    recovery_email: string;
    login: string;
    password: string;
    confirmPassword: string;
    profileImage: File | null;
    firstName: string;
    lastName: string;
    activationCode: string;
    email: string;
    consent: boolean;
}

interface FieldErrors {
    identifier: boolean;
    logging_password: boolean;
    recovery_email: boolean;
    login: boolean;
    password: boolean;
    confirmPassword: boolean;
    profileImage: boolean;
    firstName: boolean;
    lastName: boolean;
    activationCode: boolean;
    email: boolean;
    consent: boolean;
    dataError: boolean;
}

interface DirtyFields {
    identifier: boolean;
    logging_password: boolean;
    recovery_email: boolean;
    login: boolean;
    password: boolean;
    confirmPassword: boolean;
    firstName: boolean;
    lastName: boolean;
    activationCode: boolean;
    email: boolean;
}

const initialFormState: FormState = {
    login: "",
    password: "",
    recovery_email: "",
    confirmPassword: "",
    profileImage: null,
    firstName: "",
    lastName: "",
    identifier: "",
    email: "",
    activationCode: "",
    logging_password: "",
    consent: false
};

const initialFieldErrors: FieldErrors = {
    login: false,
    password: false,
    recovery_email: false,
    confirmPassword: false,
    profileImage: false,
    firstName: false,
    lastName: false,
    email: false,
    activationCode: false,
    identifier: false,
    logging_password: false,
    consent: false,
    dataError: false,
};

const initialDirtyFields: DirtyFields = {
    login: false,
    password: false,
    recovery_email: false,
    confirmPassword: false,
    firstName: false,
    lastName: false,
    activationCode: false,
    email: false,
    identifier: false,
    logging_password: false,
};

const Auth: React.FC = () => {
    const navigate = useNavigate();
    const { notify, showLoading, hideLoading } = useNotification();
    const { userStore } = useContext(Context);

    const location = useLocation();
    const isLogin = location.pathname === LOGIN_ROUTE;
    const isForgotPassword = location.pathname === FORGOT_PASSWORD_ROUTE;
    const isRegistration = location.pathname === REGISTRATION_ROUTE;

    const [showError, setShowError] = useState<boolean>(false);
    const [serverError, setServerError] = useState<string | null>(null);
    const [isSubmitClicked, setIsSubmitClicked] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState<number>(1);

    const [formState, setFormState] = useState<FormState>(initialFormState);
    const [fieldErrors, setFieldErrors] = useState<FieldErrors>(initialFieldErrors);
    const [dirtyFields, setDirtyFields] = useState<DirtyFields>(initialDirtyFields);

    const handleBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setDirtyFields(prev => ({ ...prev, [name]: true }));
        validateField(name, value);
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormState(prevState => {
            const newState = { ...prevState, [name]: value };
            return newState;
        });
    };

    const handleCheckboxChange = () => {
        setFormState((prevState) => ({
            ...prevState,
            consent: !prevState.consent
        }));
   };
   
    const handleImageChange = useCallback((image: File) => {
        setFormState(prev => ({ ...prev, profileImage: image }));
        if (formState.profileImage !== null) {
            setFieldErrors(prev => ({ ...prev, profileImage: false }));
        }
    }, [formState.profileImage]);

    const validateField = useCallback((name: string, value: string) => {
        setFieldErrors(prevErrors => {
            const error = (() => {
                switch (name) {
                    case 'login':
                        return !/^[a-zA-Z]{4,}$/.test(value);
                    case 'recovery_email':
                    case 'email':
                        return !/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i.test(value);
                    case 'password':
                        return !/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(value);
                    default:
                        return false;
                }
            })();
            return { ...prevErrors, [name]: error };
        });
    }, []);

    const validateForm = useCallback((): boolean => {
        const errors: FieldErrors = { ...initialFieldErrors, dataError: false};

        if (isLogin) {
            errors.identifier = !formState.identifier;
            errors.logging_password = !formState.logging_password;
            errors.dataError = errors.identifier || errors.logging_password;
        }

        if (isRegistration) {
            if (currentStep === 1) {
                errors.login = !formState.login || fieldErrors.login;
                errors.password = !formState.password || fieldErrors.password;
                errors.confirmPassword = !formState.confirmPassword || formState.confirmPassword !== formState.password;
                errors.dataError = !formState.login || !formState.password || !formState.confirmPassword;
            } else if (currentStep === 2) {
                errors.firstName = !formState.firstName;
                errors.lastName = !formState.lastName;
                errors.email = !formState.email || fieldErrors.email;
                errors.dataError = !formState.firstName || !formState.lastName || !formState.email;
            } else if (currentStep === 3) {
                errors.activationCode = !formState.activationCode || fieldErrors.activationCode;
                errors.consent = formState.consent === false;
                if(errors.consent) {
                    notify('Пожалуйста, согласитесь на обработку данных');
                }
            }
        }

        if (isForgotPassword) {
            errors.recovery_email = !formState.recovery_email || fieldErrors.recovery_email;
            errors.dataError = !formState.recovery_email;
        }

        setFieldErrors(errors);
        return Object.values(errors).some(error => error);

    }, [formState, fieldErrors, currentStep, isLogin]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setShowError(false);
        setIsSubmitClicked(true);
        
        const hasErrors = validateForm();
        if (hasErrors) {
            setShowError(true);
            return;
        }

        setIsLoading(true);
        try {
            if (isLogin) {
                await userStore.login(formState.identifier, formState.logging_password);
                navigate(HOME_ROUTE);
            }
            
            if (isForgotPassword){
                await userStore.sendRecoveryLink(formState.recovery_email);
                notify(`Письмо было отправлено на почту ${formState.recovery_email}`)
            }
               
            if (isRegistration) {
                if (currentStep === 1) {
                    await userStore.checkLogin(formState.login);
                    setServerError(null);
                    setCurrentStep(2);
                } else if (currentStep === 2) {
                    await userStore.checkEmail(formState.email);
                    await userStore.sendActivationCode(formState.email);
                    setServerError(null);
                    setCurrentStep(3);
                } else if (currentStep === 3) {
                    await userStore.registration(formState.email, formState.password, formState.login, formState.firstName, formState.lastName, formState.activationCode, formState.profileImage as File);
                    setServerError(null);
                    navigate(HOME_ROUTE);
                }
            }
        } catch (error: any) {
            setServerError(error.message);
            setShowError(true);
        } finally {
            setIsSubmitClicked(false);
            setIsLoading(false);
        }
    };

    const handleBack = () => {
        if (currentStep > 1) {
            resetErrors();
            setCurrentStep(prevStep => prevStep - 1);
        } else {
            navigate(-1);
        }
    }
    
    const resetErrors = () => {
        setShowError(false);
        setServerError(null);
        setFieldErrors({
            login: false,
            email: false,
            recovery_email: false,
            password: false,
            activationCode: false,
            logging_password: false,
            identifier: false,
            profileImage: false,
            confirmPassword: false,
            firstName: false,
            lastName: false,
            consent: false,
            dataError: false,
        });
        setDirtyFields({
            login: false,
            email: false,
            recovery_email: false,
            password: false,
            activationCode: false,
            logging_password: false,
            identifier: false,
            confirmPassword: false,
            firstName: false,
            lastName: false,
        });
    };

    useEffect(() => {
        resetErrors();
    }, [isLogin])

    const renderRegistrationSteps = () => {
        switch (currentStep) {
            case 1:
                return (
                    <>
                        <RegistrFirstStep
                            login={formState.login}
                            loginError={fieldErrors.login}
                            password={formState.password}
                            passwordError={fieldErrors.password}
                            confirmPassword={formState.confirmPassword}
                            confirmPasswordError={fieldErrors.confirmPassword}
                            blurHandle={handleBlur}
                            handleChange={handleChange}
                            isSubmitClicked={isSubmitClicked}
                        />
                        <ScrollToTopNavLink to={LOGIN_ROUTE} className={classes.AuthLinks}>
                            Уже есть аккаунт
                        </ScrollToTopNavLink>
                    </>
                );

            case 2:
                return (
                    <RegistrSecondStep
                        profileImage={formState.profileImage}
                        profileImageError={fieldErrors.profileImage}
                        firstName={formState.firstName}
                        firstNameError={fieldErrors.firstName}
                        lastName={formState.lastName}
                        lastNameError={fieldErrors.lastName}
                        email={formState.email}
                        emailError={fieldErrors.email}
                        handleImageChange={handleImageChange}
                        blurHandle={handleBlur}
                        handleChange={handleChange}
                        isSubmitClicked={isSubmitClicked}
                    />
                );

            case 3:
                return (
                    <RegistrThirdStep
                        activationCode={formState.activationCode}
                        consent={formState.consent}
                        handleCheckboxChange={handleCheckboxChange}
                        activationCodeError={fieldErrors.activationCode}
                        storedEmail={formState.email}
                        blurHandle={handleBlur}
                        handleChange={handleChange}
                        isSubmitClicked={isSubmitClicked}
                    />
                );

            default:
                return null;
        }
    };

    return (
        <div className={classes.auth__container}>
            <div className={classes.auth__wrapper}>
                <ComeBack onBack={handleBack} />
                <form className={classes.auth__form} onSubmit={handleSubmit}>
                    {!isLogin && !isForgotPassword && (
                        <div className={classes.stepNumeration}>
                            <img src={AuthCheeseImg} alt="Сырная авторизация" />
                            <span>{currentStep}</span>
                        </div>
                    )}

                    <h2>
                        {isLogin ? "Войти" :
                        isForgotPassword ? "Забыли пароль" :
                        "Регистрация"}
                    </h2>

                    <p>
                        {isLogin ? "И снова здравствуй" :
                        isForgotPassword ? "Мы все иногда забываем пароли. Введите свой email, чтобы получить код для восстановления доступа к аккаунту." :
                        currentStep === 1 ? "Заполните информацию об аккаунте" :
                        currentStep === 2 ? "Заполните основную информацию о себе" :
                        "Вы почти у цели. Мы отправили письмо с кодом на почту. Введите код и этот этап будет пройден"}
                    </p>

                    {isLogin && (
                        <>
                            <LoginForm
                                identifier={formState.identifier}
                                identifierError={fieldErrors.identifier}
                                password={formState.logging_password}
                                passwordError={fieldErrors.logging_password}
                                handleChange={handleChange}
                                blurHandle={handleBlur}
                                isSubmitClicked={isSubmitClicked}
                            />
                            <ScrollToTopNavLink to={FORGOT_PASSWORD_ROUTE} className={classes.AuthLinks}>
                                Забыл пароль
                            </ScrollToTopNavLink>
                        </>
                    )}

                    {isForgotPassword && (
                        <ForgotPassword 
                            email={formState.recovery_email}
                            emailError={fieldErrors.recovery_email}
                            blurHandle={handleBlur}
                            handleChange={handleChange}
                            isSubmitClicked={isSubmitClicked}
                        />
                    )}

                    {isRegistration && renderRegistrationSteps()}

                    <div className={classes.submit__container}>
                        <ErrorDetector
                            showError={showError}
                            dataError={fieldErrors.dataError}
                            loginError={fieldErrors.login}
                            loginDirty={dirtyFields.login}
                            confirmPasswordError={fieldErrors.confirmPassword}
                            confirmPasswordDirty={dirtyFields.confirmPassword}
                            activationCode={fieldErrors.activationCode}
                            passwordError={fieldErrors.password}
                            passwordDirty={dirtyFields.password}
                            emailError={fieldErrors.email}
                            emailDirty={dirtyFields.email}
                            recovery_emailError={fieldErrors.recovery_email}
                            recovery_emailDirty={dirtyFields.recovery_email}
                            serverError={serverError}
                        />

                        <div className={classes.submitButton}>
                            <CustomButton
                                type="submit"
                                padding="1.125rem 1.5rem"
                                loading={isLoading}
                            >
                            {isLogin ? "Войти" :
                                isForgotPassword ? "Отправить письмо" :
                                currentStep === 3 ? "Зарегистрироваться" :
                                "Продолжить"
                            }
                            </CustomButton>
                        </div>
                    </div>
                </form>
                <Agreement isLogin={isLogin} isThirdStep={currentStep === 3} />
            </div>
        </div>
    );
};

export default observer(Auth);