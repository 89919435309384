import React, { useMemo, useRef, useLayoutEffect } from 'react';
import styles from './Pagination.module.scss';

const MAX_VISIBLE_PAGES = 3;

interface PaginationProps {
     currentPage: number;
     totalPages: number;
     onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
     const underlineRef = useRef<HTMLDivElement>(null);
     
     useLayoutEffect(() => {
          const activeLink = document.querySelector(`.${styles.ActiveLink}`) as HTMLElement | null;
          if (activeLink && underlineRef.current) {
               const offsetLeft = activeLink.offsetLeft;
               underlineRef.current.style.left = `${offsetLeft}px`;
          }
     }, [currentPage, totalPages]);

     const handlePrevPage = () => {
          if (currentPage > 1) {
               onPageChange(currentPage - 1);
          }
     };

     const handleNextPage = () => {
          if (currentPage < totalPages) {
               onPageChange(currentPage + 1);
          }
     };

     const handlePageClick = (page: number, e: React.MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();
          onPageChange(page);
     };

     const pageNumbers = useMemo(() => {
          const pages = [];
          let startPage = Math.max(currentPage - Math.floor(MAX_VISIBLE_PAGES / 2), 1);
          let endPage = Math.min(startPage + MAX_VISIBLE_PAGES - 1, totalPages);

          if (endPage - startPage + 1 < MAX_VISIBLE_PAGES) {
               startPage = Math.max(totalPages - MAX_VISIBLE_PAGES + 1, 1);
               endPage = Math.min(startPage + MAX_VISIBLE_PAGES - 1, totalPages);
          }

          for (let i = startPage; i <= endPage; i++) {
               pages.push(i);
          }
          return pages;
     }, [currentPage, totalPages]);

     return (
     <div className={styles.pagination}>
          {/* < */}
          <a href="#" className={`${currentPage === 1 ? styles.DisabledLink : ''}`}
               onClick={(e) => {
                    e.preventDefault();
                    handlePrevPage();
               }}
          >
          {"<"}
          </a>
          {/* 1 */}
          {pageNumbers[0] > 1 && totalPages > MAX_VISIBLE_PAGES && (
          <>
               <a
                    href="#"
                    onClick={(e) => handlePageClick(1, e)}
                    className={`${currentPage === 1 ? styles.ActiveLink : ''}`}
               >
               1
               </a>
               {pageNumbers[0] > 2 && <span className={styles.pagination__dots}>...</span>}
          </>
          )}
          {/* PAGES */}
          {pageNumbers.map((page) => (
          <a
               key={page}
               href="#"
               onClick={(e) => handlePageClick(page, e)}
               className={`${currentPage === page ? styles.ActiveLink : ''}`}
          >
               {page}
          </a>
          ))}
          {/* LastPAGE */}
          {pageNumbers[pageNumbers.length - 1] < totalPages && totalPages > MAX_VISIBLE_PAGES && (
          <>
               {pageNumbers[pageNumbers.length - 1] < totalPages - 1 && (
                    <span className={styles.pagination__dots}>...</span>
               )}
               <a
                    href="#"
                    onClick={(e) => handlePageClick(totalPages, e)}
                    className={`${currentPage === totalPages ? styles.ActiveLink : ''}`}
               >
               {totalPages}
               </a>
          </>
          )}
          {/* > */}
          <a
               href="#"
               onClick={(e) => {
                    e.preventDefault();
                    handleNextPage();
               }}
               className={`${currentPage === totalPages ? styles.DisabledLink : ''}`}
          >
          {">"}
          </a>
          {/* Элемент для анимации подчеркивания */}
          <div ref={underlineRef} className={styles.underlineAnimation} />
     </div>
     );
};

export default Pagination;
