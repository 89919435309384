import React from "react";
import classes from '../../styles/FranchisePage.module.scss';
import Skeleton from "./Skeleton";

const SkeleronFranchisePage: React.FC = () => {
    return (
          <div className={classes.franchise__container}>
               <div className={classes.franchise__wrapper}>
                    <div className={classes.franchise__topBox}>
                         <Skeleton className={classes.franchise__image}/>
                         <div className={classes.franchise__infoBody}>
                              <Skeleton width={"30%"} height={"2.25rem"} borderRadius={4}/>
                              <Skeleton width={"70%"} height={"40vh"} borderRadius={4}/>
                              <Skeleton width={"25%"} height={"3rem"} borderRadius={60}/>
                         </div>
                    </div>
                    <div className={classes.franchise__bottomBox}>
                         <div className={classes.franchisor_description}>
                              <Skeleton width={"30%"} height={36} borderRadius={4}/>
                              <Skeleton width={"100%"} height={120} borderRadius={12}/>
                         </div>
                    </div>
               </div>
          </div>
     )
}
export default SkeleronFranchisePage;
