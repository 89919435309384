import React from 'react';
import classNames from 'classnames';
import classes from './Hint.module.scss';

interface HintProps {
    message: string;
    hints?: string[];
    isErrored?: boolean;
    profileHint?: boolean;
}

const Hint: React.FC<HintProps> = ({ 
    message,
    hints = [],
    isErrored,
    profileHint,
}) => {
    const containerClasses = classNames(
        classes.hintContainer,
        { [classes.errored]: isErrored },
        { [classes.profileHint]: profileHint },
    );

    const messageClasses = classNames({
        [classes.errored]: isErrored
    });

    return (
        <div className={containerClasses}>
            <b className={messageClasses}>{message}</b>
            
            {hints.length > 0 && (
                <ul>
                    {hints.map((hint, index) => (
                        <li key={index}>{hint}</li>
                    ))}
                </ul>
            )}

            <div className={classes.triangle}>
                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 8L0.5 15.7942L0.5 0.205771L14 8Z" fill="white" />
                </svg>
            </div>
        </div>
    );
};

export default Hint;
