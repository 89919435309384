import React, { useCallback, useContext } from "react";
import FranchiseCard from "./FranchiseCard";
import classes from './InteractiveCards.module.scss';
import { Context } from "../..";
import { observer } from "mobx-react-lite";
import { IFranchise } from "../../models/IFranchise";
import SkeletonsFranchiseCard from "../skeletons/SkeletonsFranchiseCard";

interface InteractiveCardsProps {
    previewFranchises?: IFranchise[];
}

const InteractiveCards: React.FC<InteractiveCardsProps> = ({ previewFranchises }) => {
    const { releaseStore } = useContext(Context);

    const handleCardClick = useCallback((index: number) => {
        if (index !== 0) {
            const updatedCards = [releaseStore.threeInteractiveCards[index], ...releaseStore.threeInteractiveCards.filter((_, i) => i !== index)];
            releaseStore.updateThreeInteractiveCards(updatedCards);
        }
    }, [releaseStore]);

    const threeInteractiveCards = previewFranchises ?? releaseStore.threeInteractiveCards;

    return (
        <div className={classes.InteractiveCards}>
            {threeInteractiveCards.length !== 0 ? (
                <>
                    {threeInteractiveCards.map((card, index) => (
                        <div
                            key={card.id}
                            className={`${classes.franchise_card} ${index === 0 ? classes.active : ''}`}
                            onClick={() => handleCardClick(index)}
                        >
                            <FranchiseCard
                                franchise={card}
                                isActive={index === 0}
                                isEditorsChoice
                            />
                        </div>
                    ))}
                </>
            ) : (
                <>
                    {Array.from({ length: 3 }).map((_, index) => (
                        <div
                            key={index}
                            className={`${classes.franchise_card} ${index === 0 ? classes.active : ''}`}
                        >
                            <SkeletonsFranchiseCard isEditorsChoice />
                        </div>
                    ))}
                </>
            )}
        </div>
    );
};

export default observer(InteractiveCards);
