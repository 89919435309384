import React from "react";
import classes from './../franchiseBoxes/FranchiseBox.module.scss';
import Skeleton from "./Skeleton";
import CustomButton from "../buttons/CustomButton";
import { ReactComponent as Heart } from '../../assets/icons/heart.svg';

const SkeletonFranchiseBox: React.FC = () => {
     return (
          <div className={classes.franchiseBox__container}>
               <Skeleton className={classes.franchiseImage} borderRadius={20} contrast="dark"/>
               <div className={classes.franchiseBox__content}>
                    <Skeleton width={300} height={40} borderRadius={4} contrast="dark"/>
                    <div className={classes.creationTime__box}>
                         <Skeleton width={80} height={30} borderRadius={4} contrast="dark"/>
                    </div>
                    <Skeleton width={800} height={100} borderRadius={4} contrast="dark"/>
                    <Skeleton width={140} height={30} borderRadius={4} contrast="dark"/>
               </div>
               <div className={classes.box__button}>
                    <Heart className={classes.favorite__icon} />
               </div>
               <CustomButton
                    padding="1.125rem 1.5rem"
                    loading
                    style={{
                         position: "absolute",
                         right: "-10px",
                         bottom: "-10px",
                         border: "10px solid white",
                    }}
               >
                    Загрузка
               </CustomButton>
          </div>
     );
};

export default SkeletonFranchiseBox;