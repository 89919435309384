import React, { useEffect, useContext } from "react";
import classes from './FranchisesYouMayLike.module.scss';
import { Context } from "../../index";
import CatalogFranchiseCard from "../../UI/cards/CatalogFranchiseCard";

interface FranchisesYouMayLikeProps {
     franchiseId: number;
     industryId: number;
}

const FranchisesYouMayLike: React.FC<FranchisesYouMayLikeProps> = ({
     franchiseId,
     industryId
}) => {
     const { franchiseStore} = useContext(Context);

     useEffect(() => {
          const fetchFranchises = async () => {
               await franchiseStore.fetchFranchisesByIndustry(franchiseId, industryId, 3)
          }
          fetchFranchises()
     }, [ franchiseStore]);

     return(

     <div className={classes.franchisesYouMayLike}>
          <div className={classes.franchisesYouMayLike__content}>
               <h1>Рекомендуем вам...</h1>
               <div className={classes.franchisesYouMayLike__cards}>
                    {franchiseStore.recommendedFranchises.map((franchise) => (
                         <CatalogFranchiseCard key={franchise.id} franchise={franchise} />
                    ))}
               </div>
          </div>
     </div>


     )
}

export default FranchisesYouMayLike;