import React from 'react'
import classes from '../styles/PrivacyPolicy.module.scss'

const PrivacyPolicy = () => {
     return (
          <div className={classes.privacyPolicy__container}>
               <div className={classes.privacyPolicy__content}>
                    <h1>Политика конфиденциальности персональных данных</h1>
                    <p>Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) действует в отношении всей информации, которую сайт о франчайзинге francheese.by, (далее – francheese.by) расположенный на доменном имени francheese.by (а также его субдоменах), может получить о Пользователе во время использования сайта francheese.by (а также его субдоменов), его программ и его продуктов.</p>
                    <h2>1. Определение терминов</h2>
                    <h3>1.1 В настоящей Политике конфиденциальности используются следующие термины:</h3>
                    <ul>
                         <li>
                              <strong>1.1.1.</strong> Администрация сайта (далее – Администрация) – уполномоченные сотрудники на управление сайтом о франчайзинге francheese.by, действующие от имени ИП Трубченко Н.С., которые организуют и (или) осуществляют обработку персональных данных, а также определяют цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
                         </li>
                         <li>
                              <strong>1.1.2.</strong> Персональные данные - любая информация, относящаяся к прямо или косвенно определенному, или определяемому физическому лицу (субъекту персональных данных).
                         </li>
                         <li>
                              <strong>1.1.3.</strong> Обработка персональных данных - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
                         </li>
                         <li>
                              <strong>1.1.4.</strong> Конфиденциальность персональных данных - обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.
                         </li>
                         <li>
                              <strong>1.1.5.</strong> Сайт-портал о франчайзинге francheese.by - это совокупность связанных между собой веб-страниц, размещенных в сети Интернет по уникальному адресу (URL): francheese.by, а также его субдоменах.
                         </li>
                         <li>
                              <strong>1.1.6.</strong>  Субдомены- это страницы или совокупность страниц, расположенные на доменах третьего уровня, принадлежащие сайту Портал о франчайзинге francheese.by, а также другие временные страницы, внизу которых указана контактная информация Администрации.
                         </li>
                         <li>
                              <strong>1.1.7.</strong> Пользователь сайта Портал о франчайзинге francheese.by (далее Пользователь) – лицо, имеющее доступ к сайту Портал о франчайзинге francheese.by посредством сети Интернет и использующее информацию, материалы и продукты сайта Портал о франчайзинге francheese.by.
                         </li>
                         <li>
                              <strong>1.1.8.</strong> Cookies — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.
                         </li>
                         <li>
                              <strong>1.1.9.</strong>  IP-адрес— уникальный сетевой адрес узла в компьютерной сети, через который Пользователь получает доступ на francheese.by.
                         </li>
                         <li>
                              <strong>1.1.10.</strong> Товар - продукт, который Пользователь заказывает на сайте и оплачивает через платёжные системы.
                         </li>
                    </ul>
                    
                    <h2>2. Общие положения</h2>
                    <ul>
                         <li>
                              <strong>2.1.</strong> Использование сайта-портала о франчайзинге francheese.by Пользователем означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя.
                         </li>
                         <li>
                              <strong>2.2.</strong> В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование сайта Портал о франчайзинге francheese.by.
                         </li>
                         <li>
                              <strong>2.3.</strong> Настоящая Политика конфиденциальности применяется к сайт-порталу о франчайзинге francheese.by. francheese.by не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на сайте Портал о франчайзинге francheese.by.
                         </li>
                         <li>
                              <strong>2.4.</strong> Администрация не проверяет достоверность персональных данных, предоставляемых Пользователем.
                         </li>
                    </ul>

                    <h2>3. Предмет политики конфиденциальности</h2>
                    <ul>
                         <li>
                              <strong>3.1.</strong> Настоящая Политика конфиденциальности устанавливает обязательства Администрации по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации при регистрации на сайте Портал о франчайзинге francheese.by, при подписке на информационную e-mail рассылку или при оформлении заказа.
                         </li>
                         <li>
                              <strong>3.2.</strong> Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путём заполнения форм на сайте-портале о франчайзинге francheese.by и включают в себя следующую информацию:
                              <ul>
                                   <li><strong>3.2.1.</strong> фамилию, имя Пользователя;</li>
                                   <li><strong>3.2.2.</strong> адрес электронной почты (e-mail);</li>
                              </ul>
                         </li>
                         <li>
                              <strong>3.3.</strong> francheese.by защищает данные, которые автоматически передаются при посещении страниц:
                              <ul>
                                   <li><strong>3.3.1.</strong> Отключение cookies может повлечь невозможность доступа к частям сайта, требующим авторизации.</li>
                                   <li><strong>3.3.2.</strong> francheese.by осуществляет сбор статистики об IP-адресах своих посетителей. Данная информация используется с целью предотвращения, выявления и решения технических проблем.</li>
                              </ul>
                         </li>
                         <li>
                              <strong>3.4.</strong> Любая иная персональная информация, неоговоренная выше (история посещения, используемые браузеры, операционные системы и т.д.), подлежит надежному хранению и нераспространению, за исключением случаев, предусмотренных в п.п. 5.2. и 5.3. настоящей Политики конфиденциальности.
                         </li>
                    </ul>

                    <h2>4. Цели сбора персональной информации пользователя</h2>
                    <ul>
                         <li><strong>4.1.</strong> Персональные данные Пользователя Администрация может использовать в целях:</li>
                         <ul>
                              <li><strong>4.1.1.</strong> Идентификации Пользователя, зарегистрированного на сайт-портале о франчайзинге francheese.by для его дальнейшей авторизации, оформления заказа и других действий.</li>
                              <li><strong>4.1.2.</strong> Предоставления Пользователю доступа к персонализированным данным сайта Портал о франчайзинге francheese.by.</li>
                              <li><strong>4.1.3.</strong> Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования сайта Портал о франчайзинге francheese.by, оказания услуг и обработки запросов и заявок от Пользователя.</li>
                              <li><strong>4.1.4.</strong> Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.</li>
                              <li><strong>4.1.5.</strong> Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.</li>
                              <li><strong>4.1.6.</strong> Создания учетной записи для использования частей сайта Портал о франчайзинге francheese.by, если Пользователь дал согласие на создание учетной записи.</li>
                              <li><strong>4.1.7.</strong> Уведомления Пользователя по электронной почте.</li>
                              <li><strong>4.1.8.</strong> Предоставления Пользователю эффективной технической поддержки при возникновении проблем, связанных с использованием сайта Портал о франчайзинге francheese.by.</li>
                              <li><strong>4.1.9.</strong> Предоставления Пользователю с его согласия специальных предложений, информации о ценах, новостной рассылки и иных сведений от имени сайта Портал о франчайзинге francheese.by.</li>
                              <li><strong>4.1.10.</strong> Осуществления рекламной деятельности с согласия Пользователя.</li>
                         </ul>
                    </ul>

                    <h2>5. Способы и сроки обработки персональной информации</h2>
                    <ul>
                         <li><strong>5.1.</strong> Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.</li>
                         <li><strong>5.2.</strong> Пользователь соглашается с тем, что Администрация вправе передавать персональные данные третьим лицам, в частности, курьерским службам, организациям почтовой связи (в том числе электронной), операторам электросвязи, исключительно в целях выполнения заказа Пользователя, оформленного на сайт-портале о франчайзинге francheese.by, включая доставку Товара, документации или e-mail сообщений.</li>
                         <li><strong>5.3.</strong> Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Республики Беларусь только по основаниям и в порядке, установленным законодательством Республики Беларусь.</li>
                         <li><strong>5.4.</strong> При утрате или разглашении персональных данных Администрация вправе не информировать Пользователя об утрате или разглашении персональных данных.</li>
                         <li><strong>5.5.</strong> Администрация принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.</li>
                         <li><strong>5.6.</strong> Администрация совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.</li>
                    </ul>


                    <h2>6. Права и обязанности сторон</h2>
                    <ul>
                         <li>
                              <strong>6.1. Пользователь вправе:</strong>
                              <ul>
                                   <li><strong>6.1.1.</strong> Принимать свободное решение о предоставлении своих персональных данных, необходимых для использования сайт-портала о франчайзинге francheese.by, и давать согласие на их обработку.</li>
                                   <li><strong>6.1.2.</strong> Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.</li>
                                   <li><strong>6.1.3.</strong> Пользователь имеет право на получение у Администрации информации, касающейся обработки его персональных данных, если такое право не ограничено в соответствии с федеральными законами. Пользователь вправе требовать от Администрации уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав.</li>
                              </ul>
                         </li>
                         <li>
                              <strong>6.2. Администрация обязана:</strong>
                              <ul>
                                   <li><strong>6.2.1.</strong> Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.</li>
                                   <li><strong>6.2.2.</strong> Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением п.п. 5.2 и 5.3. настоящей Политики Конфиденциальности.</li>
                                   <li><strong>6.2.3.</strong> Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.</li>
                                   <li><strong>6.2.4.</strong> Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя, или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.</li>
                              </ul>
                         </li>
                    </ul>

                    <h3>7. Ответственность сторон</h3>
                    <ul>
                         <li>
                              <strong>7.1.</strong> Администрация, не исполнившая свои обязательства, несёт ответственность за убытки, понесённые Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Республики Беларусь, за исключением случаев, предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей Политики Конфиденциальности.
                         </li>
                         <li>
                              <strong>7.2.</strong> В случае утраты или разглашения Конфиденциальной информации Администрация не несёт ответственность, если данная конфиденциальная информация:
                              <ul>
                                   <li>
                                        <strong>7.2.1.</strong> Стала публичным достоянием до её утраты или разглашения.
                                   </li>
                                   <li>
                                        <strong>7.2.2.</strong> Была получена от третьей стороны до момента её получения Администрацией Ресурса.
                                   </li>
                                   <li>
                                        <strong>7.2.3.</strong> Была разглашена с согласия Пользователя.
                                   </li>
                              </ul>
                         </li>
                         <li>
                              <strong>7.3.</strong> Пользователь несет полную ответственность за соблюдение требований законодательства Республики Беларусь, в том числе законов о рекламе, о защите авторских и смежных прав, об охране товарных знаков и знаков обслуживания, но не ограничиваясь перечисленным, включая полную ответственность за содержание и форму материалов.
                         </li>
                         <li>
                              <strong>7.4.</strong> Пользователь признает, что ответственность за любую информацию (в том числе, но не ограничиваясь: файлы с данными, тексты и т. д.), к которой он может иметь доступ как к части сайта Портал о франчайзинге francheese.by, несет лицо, предоставившее такую информацию.
                         </li>
                         <li>
                              <strong>7.5.</strong> Пользователь соглашается, что информация, предоставленная ему как часть сайт-портала о франчайзинге francheese.by, может являться объектом интеллектуальной собственности, права на который защищены и принадлежат другим Пользователям, партнерам или рекламодателям, которые размещают такую информацию на сайт-портале о франчайзинге francheese.by. Пользователь не вправе вносить изменения, передавать в аренду, передавать на условиях займа, продавать, распространять или создавать производные работы на основе такого Содержания (полностью или в части), за исключением случаев, когда такие действия были письменно прямо разрешены собственниками такого Содержания в соответствии с условиями отдельного соглашения.
                         </li>
                         <li>
                              <strong>7.6.</strong> В отношении текстовых материалов (статей, публикаций, находящихся в свободном публичном доступе на сайт-портала о франчайзинге francheese.by) допускается их распространение при условии, что будет дана ссылка на francheese.by.
                         </li>
                         <li>
                              <strong>7.7.</strong> Администрация не несет ответственности перед Пользователем за любой убыток или ущерб, понесенный Пользователем в результате удаления, сбоя или невозможности сохранения какого-либо Содержания и иных коммуникационных данных, содержащихся на сайт-портале о франчайзинге francheese.by или передаваемых через него.
                         </li>
                         <li>
                              <strong>7.8.</strong> Администрация не несет ответственности за любые прямые или косвенные убытки, произошедшие из-за: использования либо невозможности использования сайта, либо отдельных сервисов; несанкционированного доступа к коммуникациям Пользователя; заявления или поведение любого третьего лица на сайте.
                         </li>
                         <li>
                              <strong>7.9.</strong> Администрация не несет ответственности за какую-либо информацию, размещенную пользователем на сайт-портале о франчайзинге francheese.by, включая, но не ограничиваясь: информацию, защищенную авторским правом, без прямого согласия владельца авторского права.
                         </li>
                    </ul>


                    <h2>8. Разрешение споров</h2>
                    <ul>
                         <li>
                              <strong>8.1.</strong> До обращения в суд с иском по спорам, возникающим из отношений между Пользователем и Администрацией, обязательным является предъявление претензии (письменного предложения или предложения в электронном виде о добровольном урегулировании спора).
                         </li>
                         <li>
                              <strong>8.2.</strong> Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно или в электронном виде уведомляет заявителя претензии о результатах рассмотрения претензии.
                         </li>
                         <li>
                              <strong>8.3.</strong> При не достижении соглашения спор будет передан на рассмотрение Арбитражного суда г. Минск.
                         </li>
                         <li>
                              <strong>8.4.</strong> К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией применяется действующее законодательство Республики Беларусь.
                         </li>
                    </ul>


                    <h2>9. Дополнительные условия</h2>
                    <ul>
                         <li>
                              <strong>9.1.</strong> Администрация вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.
                         </li>
                         <li>
                              <strong>9.2.</strong> Новая Политика конфиденциальности вступает в силу с момента ее размещения на сайте Портал о франчайзинге francheese.by, если иное не предусмотрено новой редакцией Политики конфиденциальности.
                         </li>
                         <li>
                              <strong>9.3.</strong> Все предложения или вопросы касательно настоящей Политики конфиденциальности следует сообщать по адресу: <a href="mailto:francheesegroup@gmail.com">francheesegroup@gmail.com</a>
                         </li>
                         <li>
                              <strong>9.4.</strong> Действующая Политика конфиденциальности размещена на странице по адресу: <a href="https://topfranchise.ru/politika-konfidentsialnosti/" target="_blank">https://topfranchise.ru/politika-konfidentsialnosti/</a>
                         </li>
                    </ul>
               </div>
          </div>
     )
}

export default PrivacyPolicy;