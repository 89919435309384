import React from "react";
import AuthorizationInput from "../../UI/inputs/AuthorizationInput";

import { ReactComponent as ProfileIcon } from '../../assets/icons/profile.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg';
import AuthorizationImageUploader from "../../UI/fileUploaders/AuthorizationImageUploader";

interface RegistrSecondStepProps {
    profileImage: File | null;
    handleImageChange: (image: File) => void;
    profileImageError: boolean;

    firstName: string;
    firstNameError: boolean;
    
    lastName: string;
    lastNameError: boolean;
    
    email: string;
    emailError: boolean;

    blurHandle: (e: React.FocusEvent<HTMLInputElement>) => void;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isSubmitClicked: boolean;
}

const RegistrSecondStep: React.FC<RegistrSecondStepProps> = ({
    profileImage,
    handleImageChange,
    profileImageError,
    firstName,
    firstNameError,

    lastName,
    lastNameError,

    email,
    emailError,

    handleChange,
    blurHandle,
    isSubmitClicked
}) => {
    return (
      <>
            <AuthorizationImageUploader
                profileImage={profileImage}
                onImageChange={handleImageChange}
                profileImageError={profileImageError}
            /> 

            <AuthorizationInput
                type="text"
                placeholder="Имя"
                name="firstName"
                value={firstName}
                onBlur={blurHandle}
                onChange={handleChange}
                icon={<ProfileIcon />}
                error={isSubmitClicked && firstNameError}
            />

            <AuthorizationInput
                type="text"
                placeholder="Фамилия"
                name="lastName"
                value={lastName}
                onBlur={blurHandle}
                onChange={handleChange}
                icon={<ProfileIcon />}
                error={isSubmitClicked && lastNameError}
            />

            <AuthorizationInput
                type="text"
                placeholder="Почта"
                name="email"
                value={email}
                onBlur={blurHandle}
                onChange={handleChange}
                icon={<EmailIcon />}
                error={isSubmitClicked && emailError}
            />
        </>
    );
};

export default RegistrSecondStep;