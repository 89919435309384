import React from "react";
import classes from '../styles/Contacts.module.scss';

const Contacts: React.FC = () => {
    return (
        <div className={classes.contacts__container}>
            <div className={classes.contacts__content}>
                <div className={classes.contacts__card}>
                    <h1>Контактная информация</h1>
                    <div className={classes.cardInfo__box}>
                        <div className={classes.cardInfo__item}>
                            <span>ИП</span>
                            <p>Трубченко Никита Сергеевич</p>
                        </div>
                        <div className={classes.cardInfo__item}>
                            <span>Звонить по номеру</span>
                            <p>+375336949638</p>
                        </div>
                        <div className={classes.cardInfo__item}>
                            <span>Мы находимся по адресу</span>
                            <p>Г. Полоцка, ул. Петра Машерова 9а</p>
                        </div>
                        <div className={classes.cardInfo__item}>
                            <span>Электронная почта</span>
                            <p>NikitaTrubchenko@mail.ru</p>
                        </div>
                        <div className={classes.cardInfo__item}>
                            <span>УНП</span>
                            <p>391965816</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contacts;